import {
  ProjectTelemetry,
  SensorTriggering,
  Gateway as SensorGateway,
  Sensor,
  Triggers as SensorTriggers,
  WirelessSensorsLayout,
  WirelessSensorsPlaceholder,
  ErrorsAndIncidents,
} from "web-analysis-lib";
import { axiosInstance } from "../..";

export const SensorConfiguratorPage = () => <ProjectTelemetry />;
export const SensorTriggeringPage = () => <SensorTriggering />;
export const SensorGatewayPage = () => <SensorGateway />;
export const SensorPage = () => <Sensor />;
export const SensorTriggersPage = () => <SensorTriggers />;
export const WirelessSensorsLayoutPage = () => (
  <WirelessSensorsLayout axiosInstance={axiosInstance} marginTop={50} />
);
export const ErrorsAndIncidentsPage = () => <ErrorsAndIncidents />;
export const WirelessSensorsPlaceholderComp = () => (
  <WirelessSensorsPlaceholder />
);
