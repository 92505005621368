import { axiosInstance } from "../..";
import {
  ProductContractExpanded,
  ServiceAccount,
  ServiceAccountAdd,
  ServiceAccountDetails,
  ServiceAccountUpdate,
  SubscriptionKeysContract,
  UserExtendedProperties,
} from "./models";

export async function sendsConfirmationPassServiceAccount(
  id: string
): Promise<any> {
  const urlRequest =
    "/rbac/v1/serviceAccounts/" + id + "/confirmations/password/send";

  const response = await axiosInstance.post<any>(urlRequest);
  return response;
}

export async function listServiceAccounts(): Promise<ServiceAccount[] | any> {
  const response = await axiosInstance.get<ServiceAccount[] | any>(
    "/rbac/v1/serviceAccounts/"
  );
  return response.data;
}

export async function listServiceAccountsProducts(): Promise<
  ProductContractExpanded[] | any
> {
  const response = await axiosInstance.get<ProductContractExpanded[] | any>(
    "rbac/v1/serviceAccounts/products"
  );
  return response.data;
}

export async function addServiceAccount(
  serviceAccountAdd: ServiceAccountAdd
): Promise<ServiceAccountDetails | any> {
  const config: ServiceAccountAdd = serviceAccountAdd;
  const urlRequest = "/rbac/v1/serviceAccounts";

  const response = await axiosInstance.post<ServiceAccountDetails | any>(
    urlRequest,
    config
  );
  if (response.data) return response.data;
  else return response;
}

export async function deleteServiceAccount(
  id: string
): Promise<UserExtendedProperties | any> {
  const urlRequest = "/rbac/v1/serviceAccounts/" + id;
  const response = await axiosInstance.delete<UserExtendedProperties | any>(
    urlRequest
  );
  if (response.data) return response.data;
  else return response;
}

export async function detailsServiceAccount(
  id: string
): Promise<ServiceAccountDetails | any> {
  const response = await axiosInstance.get<ServiceAccountDetails | any>(
    "/rbac/v1/serviceAccounts/" + id
  );
  if (response.data) return response.data;
  else return response;
}

export async function RegeneratePrimaryKeyServiceAccount(
  id: string,
  subscriptionId: string
): Promise<SubscriptionKeysContract | any> {
  const urlRequest =
    "/rbac/v1/serviceAccounts/" +
    id +
    "/subscriptions/" +
    subscriptionId +
    "/keys/regeneratePrimaryKey";
  const response = await axiosInstance.post<SubscriptionKeysContract | any>(
    urlRequest
  );
  if (response.data) return response.data;
  else return response;
}

export async function RegenerateSecondaryKeyServiceAccount(
  id: string,
  subscriptionId: string
): Promise<SubscriptionKeysContract | any> {
  const urlRequest =
    "/rbac/v1/serviceAccounts/" +
    id +
    "/subscriptions/" +
    subscriptionId +
    "/keys/regenerateSecondaryKey";
  const response = await axiosInstance.post<SubscriptionKeysContract | any>(
    urlRequest
  );
  if (response.data) return response.data;
  else return response;
}

export async function subscriptionKeysServiceAccount(
  id: string,
  subscriptionId: string
): Promise<SubscriptionKeysContract | any> {
  const response = await axiosInstance.get<SubscriptionKeysContract | any>(
    "/rbac/v1/serviceAccounts/" +
      id +
      "/subscriptions/" +
      subscriptionId +
      "/keys/"
  );
  if (response.data) return response.data;
  else return response;
}

export async function updateServiceAccount(
  id: string,
  serviceAccountUpdate: ServiceAccountUpdate
): Promise<ServiceAccountDetails | any> {
  const config: ServiceAccountUpdate = serviceAccountUpdate;
  const urlRequest = "/rbac/v1/serviceAccounts/" + id;
  const response = await axiosInstance.put<ServiceAccountDetails | any>(
    urlRequest,
    config
  );

  if (response.data) return response.data;
  else return response;
}
