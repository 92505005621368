import { Label, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { Text } from "@fluentui/react/lib/Text";
import React from "react";

/**
 * HOC to add a loading panel to a wrapped component when the data parameter is not available
 * @param data The data
 * @param wrappedComponent The wrapped component.
 * @returns The loading panel, when the data is null. Otherwise the wrapped component.
 */
export const WithLoadingPanelHOC = (
  data: any,
  wrappedComponent: JSX.Element
): JSX.Element => {
  let result: JSX.Element = (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      tokens={{ childrenGap: 5, padding: 5 }}
      role="loading panel"
      style={{ height: "100%", marginTop: "auto", marginBottom: "auto" }}
    >
      <Stack.Item>
        <Spinner size={SpinnerSize.large} />
      </Stack.Item>
      <Stack.Item>
        <Label>Loading data...</Label>
      </Stack.Item>
    </Stack>
  );
  if (data) {
    result = wrappedComponent;
  }

  return result;
};

/**
 * HOC to add a text to a wrapped component when the count parameter is zero
 * @param data The data
 * @param wrappedComponent The wrapped component.
 * @returns The text, when the count is zero. Otherwise the wrapped component.
 */
export const WithNoItemsPanelHOC = (
  count: number,
  wrappedComponent: JSX.Element
): JSX.Element => {
  let result: JSX.Element = (
    <Text variant="medium" block={true}>
      {"No items."}
    </Text>
  );

  if (count > 0) {
    result = wrappedComponent;
  }

  return result;
};
