import React from "react";

export interface IPageProps {
  title: string;
  children?: JSX.Element;
}

const pageStyle: React.CSSProperties = {
  padding: 20,
  width: "100%",
  height: "100%",
};

const Page = (props: IPageProps) => {
  return (
    <div style={pageStyle}>
      <h2>{props.title}</h2>
      {props.children}
    </div>
  );
};

export default Page;
