import { axiosInstance } from "../..";
import {
  WirelessGateway,
  RequestWirelessGatewayUpdate,
  ResponseWirelessGatewayDetailsWithChildren,
  RequestWirelessGatewayAdd,
} from "./models";

export type gatewaysBulkNotCommon = Omit<
  RequestWirelessGatewayUpdate,
  "projectId" | "ltePlanSize" | "firmware"
>;
export type gatewaysToEditBulk = Pick<
  RequestWirelessGatewayUpdate,
  "projectId" | "ltePlanSize" | "firmware"
>;

export interface gatewaysBulkProps {
  notCommon: gatewaysBulkNotCommon[];
  editGateWay: gatewaysToEditBulk;
}

export async function editBulkGateways(
  props: gatewaysBulkProps
): Promise<ResponseWirelessGatewayDetailsWithChildren | any> {
  let results;
  const propsToEditBulk: RequestWirelessGatewayUpdate[] = props.notCommon.map(
    (gat) => {
      return { ...gat, ...props.editGateWay };
    }
  );

  results = await Promise.all(
    propsToEditBulk.map(async (config) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await editWirelessGateway(config);
    })
  );

  return results;
}

export async function list(): Promise<WirelessGateway[]> {
  const response = await axiosInstance.get<WirelessGateway[]>(
    "/meta/read/internal/v1/wirelessGateways "
  );
  return response.data;
}

export async function deleteWirelessGateway(
  wireless: WirelessGateway
): Promise<WirelessGateway | any> {
  const urlRequest = "/meta/write/v1/wirelessGateways/" + wireless.id;
  const response = await axiosInstance.delete<WirelessGateway | any>(
    urlRequest
  );
  if (response.data) return response.data;
  else return response;
}

export async function editWirelessGateway(
  wireless: RequestWirelessGatewayUpdate
): Promise<any> {
  const urlRequest = "/meta/write/v1/wirelessGateways/" + wireless.id;
  const response = await axiosInstance.put<
    ResponseWirelessGatewayDetailsWithChildren | any
  >(urlRequest, wireless);
  if (response.data) return response.data;
  else return response;
}

export async function addWirelessGateway(
  wireless: RequestWirelessGatewayAdd
): Promise<any> {
  const urlRequest = "/meta/write/v1/wirelessGateways";

  const response = await axiosInstance.post<WirelessGateway | any>(
    urlRequest,
    wireless
  );

  if (response.data) return response.data;
  else return response;
}
