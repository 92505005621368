import { CSSProperties, useEffect, useState } from "react";
import { downloadFile } from "../../../FIles/api";
import {
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from "@fluentui/react";
import { v4 as uuidv4 } from "uuid";
import Icon from "../../../Icons/Icons";
import { MachineDrawing, UpdateMachine } from "../../models";
import { editMachine } from "../../api";
import { notification } from "web-analysis-lib";
import SelectFileIconOfMachine from "../../../Generic/SelectFileIconOfMachine";
import SelectFilesDrawingsOfMachine from "../../../Generic/SelectFilesDrawingsOfMachine";
import ImageGallery from "react-image-gallery";
import "./image-gallery.scss";
import { downloadSortedFiles } from "./api";

// Styles //
const titleStylePivot: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "auto",
};

type IconAndDrawingsMachineProps = {
  machine: UpdateMachine;
  iconId?: string;
  machineDrawings?: MachineDrawing[];
  onReload: () => void;
};

export type UrlDrawingsWithIndex = {
  url: string;
  index: number;
};

export const IconAndDrawingsMachine = ({
  machine,
  iconId,
  machineDrawings,
  onReload,
}: IconAndDrawingsMachineProps) => {
  const [iconId0, setIconId0] = useState<string>(iconId);
  const [iconUrl, setIconUrl] = useState<string>();
  const [drawingsUrl, setDrawingsUrl] = useState<UrlDrawingsWithIndex[]>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showDialogDrawings, setShowDialogDrawings] = useState<boolean>(false);
  const [isLoadingIcon, setIsLoadingIcon] = useState<boolean>(false);
  const [isLoadingDraws, setIsLoadingDraws] = useState<boolean>(false);

  useEffect(() => {
    setIsLoadingIcon(true);
    iconId0
      ? downloadFile(iconId0).then((res) => {
          res && setIconUrl(URL.createObjectURL(new Blob([res])));
          setIsLoadingIcon(false);
        })
      : setIsLoadingIcon(false);

    return () => {
      URL.revokeObjectURL(iconUrl);
      setIconUrl(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconId0]);

  useEffect(() => {
    setIsLoadingDraws(true);

    downloadSortedFiles(machineDrawings).then((res) => {
      setDrawingsUrl(res);
    });
    setIsLoadingDraws(false);
    return () => {
      drawingsUrl?.forEach((ele) => URL.revokeObjectURL(ele.url));
      setDrawingsUrl(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineDrawings]);

  return (
    <>
      <Stack horizontal horizontalAlign="space-evenly">
        <Stack
          verticalAlign="center"
          style={{ maxHeight: "20vw", margin: "25px" }}
        >
          <Text style={titleStylePivot}> Icon:</Text>
          {isLoadingIcon ? (
            <Spinner size={SpinnerSize.large} />
          ) : iconUrl ? (
            <>
              <img
                src={iconUrl}
                alt="MachineIcon"
                style={{ maxHeight: "20vw", margin: "25px" }}
              />
            </>
          ) : (
            <>
              <Icon
                name="NoImageIcon"
                viewBox="0 0 30 30"
                width="100"
                height="100"
              />
            </>
          )}
          <PrimaryButton
            style={{ height: "32px" }}
            text={iconUrl ? "Update" : "Add"}
            onClick={() => {
              setShowDialog(true);
            }}
          />
        </Stack>

        <Stack
          verticalAlign="center"
          style={{ maxHeight: "20vw", margin: "25px" }}
        >
          <Text style={titleStylePivot}> Drawings:</Text>

          {isLoadingDraws ? (
            <Spinner size={SpinnerSize.large} />
          ) : drawingsUrl?.length > 0 &&
            drawingsUrl.length === machineDrawings.length ? (
            <ImageGallery
              key={uuidv4()}
              items={drawingsUrl
                .sort((a, b) => (a.index > b.index ? 1 : -1))
                .map((ele) => {
                  return {
                    original: ele.url,
                    thumbnail: ele.url,
                    originalHeight: "300",
                    originalWidth: "300",
                  };
                })}
              thumbnailPosition="left"
              showIndex={true}
              showFullscreenButton={false}
              showPlayButton={false}
            />
          ) : (
            <>
              <Icon
                name="NoImageIcon"
                viewBox="0 0 30 30"
                width="100"
                height="100"
              />
            </>
          )}
          <PrimaryButton
            style={{ height: "32px" }}
            text={drawingsUrl && drawingsUrl.length > 0 ? "Update" : "Add"}
            onClick={() => {
              setShowDialogDrawings(true);
            }}
          />
        </Stack>
      </Stack>
      {showDialog && (
        <SelectFileIconOfMachine
          key={uuidv4()}
          machineId={machine.id}
          iconId={iconId0}
          hidden={!showDialog}
          onClose={() => setShowDialog(false)}
          onSubmit={(idSelected) => {
            editMachine({ ...machine, iconId: idSelected }).then((res) => {
              if ("status" in res)
                notification.error("Issue when updating machine.");
              else {
                notification.success("Updated Icon.");
                onReload();
              }
            });
            setIconId0(idSelected);
          }}
        />
      )}

      {showDialogDrawings && (
        <SelectFilesDrawingsOfMachine
          machineId={machine.id}
          currentDrawings={machine.drawingIds}
          hidden={!showDialogDrawings}
          onClose={() => setShowDialogDrawings(false)}
          onSubmit={(drawingsSelected) => {
            editMachine({ ...machine, drawingIds: drawingsSelected }).then(
              (res) => {
                if ("status" in res)
                  notification.error("Issue when updating machine.");
                else {
                  notification.success("Updated drawings.");
                  onReload();
                }
              }
            );
          }}
        />
      )}
    </>
  );
};
