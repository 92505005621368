import { axiosInstance } from "../..";
import { ResponseSimplifiedSignal } from "../Machines/models";
import { RequestSensorAdd, Sensor } from "./models";

export async function addSensor(
  machineId: string,
  sensor: RequestSensorAdd
): Promise<RequestSensorAdd | any> {
  const urlRequest = "/meta/write/v1/machines/" + machineId + "/sensors";

  const response = await axiosInstance.post<RequestSensorAdd | any>(
    urlRequest,
    sensor
  );
  if (response.data) return response.data;
  else return response;
}

export async function editSensor(
  machineId: string,
  id: string,
  sensor: Sensor
): Promise<Sensor | any> {
  const urlRequest = "/meta/write/v1/machines/" + machineId + "/sensors/" + id;

  const response = await axiosInstance.put<Sensor | any>(urlRequest, sensor);
  if (response.data) return response.data;
  else return response;
}

export async function deleteSensor(
  machineId: string,
  id: string
): Promise<Sensor | any> {
  const urlRequest = "/meta/write/v1/machines/" + machineId + "/sensors/" + id;

  const response = await axiosInstance.delete<Sensor | any>(urlRequest);
  if (response.data) return response.data;
  else return response;
}

export async function sensorsList(machineId: string): Promise<any | Sensor[]> {
  const response = await axiosInstance.get<any>(
    "/meta/read/v1/machines/" + machineId + "/sensors/"
  );
  if (response.data) return response.data;
  else return response;
}

export async function signalListOfSensor(
  machineId: string,
  sensorNo: string
): Promise<any | ResponseSimplifiedSignal[]> {
  const response = await axiosInstance.get<any>(
    "/meta/read/v1/machines/" + machineId + "/sensors/" + sensorNo + "/signals"
  );
  if (response.data) return response.data;
  else return response;
}
