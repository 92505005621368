import { Column, Table } from "web-analysis-lib";
import {
  PrimaryButton,
  Stack,
  Text,
  IFacepilePersona,
  IconButton,
  Facepile,
  IIconProps,
  TooltipHost,
  Icon,
} from "@fluentui/react";

import { useState } from "react";
import { AddRole } from "./AddRole";
import { AddRolePersona } from "./AddRolePersona";
import { ServiceAccountAddRole } from "../ServiceAccount/models";
import { iconStyle, titleStyle } from "../../schema/Constants";
import {
  ServiceAccountAddRoleToShow,
  personaProps,
} from "../ServiceAccount/AddDialog";
import { Personas } from "../../schema/personas";

const deleteAllRolesIcon: IIconProps = { iconName: "DeleteTable" };

type GetColumnsOpts = {
  hasActions: boolean;
  onDelete: (serviceAccount: ServiceAccountAddRole) => void;
};

const getColumns = ({ hasActions, onDelete }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "role",
      name: "Role",
      fieldName: "role",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "scopeLevel",
      name: "Scope Level",
      fieldName: "scopeLevel",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "resource",
      name: "Resource",
      fieldName: "resource",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "expiresAtUtc",
      name: "Expires at UTC",
      fieldName: "expiresAtUtc",
      minWidth: 100,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: "Actions",
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (role: ServiceAccountAddRole) => (
        <div style={{ display: "flex" }}>
          <TooltipHost
            key={1}
            content={"Delete"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(role)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    });
  }

  return columns;
};

type RolesComponentProps = {
  rolesToAdd: ServiceAccountAddRoleToShow[];
  setRolesToAdd: React.Dispatch<
    React.SetStateAction<ServiceAccountAddRoleToShow[]>
  >;
  onDelete: (roles: ServiceAccountAddRoleToShow) => void;
};

export const RolesComponentToAdd = ({
  rolesToAdd,
  setRolesToAdd,
  onDelete,
}: RolesComponentProps) => {
  const [showAddRole, setShowAddRole] = useState(false);
  const [showAddRolePersona, setShowAddRolePersona] = useState(false);
  const [persona, setPersona] = useState<personaProps>();

  const onCLickPersona = (ev, persona) => {
    setShowAddRolePersona((prv) => !prv);
    setPersona(persona.data);
  };

  const personas: IFacepilePersona[] = Personas.allPersonas
    //.filter((ele) => ele.type === memberType)
    .map((eleF, ind) => {
      return {
        personaName: eleF.name,
        imageInitials: eleF.imageInitials,
        data: { persona: eleF.personaRoles, root: eleF.root },
        onClick: onCLickPersona,
        initialsColor: ind,
      };
    });

  return (
    <div style={{ minWidth: "300px", padding: "40px" }}>
      <AddRole
        show={showAddRole}
        onClose={() => setShowAddRole((prv) => !prv)}
        rolesToAdd={rolesToAdd}
        setRolesToAdd={setRolesToAdd}
      ></AddRole>
      {persona && (
        <AddRolePersona
          key={Math.random()}
          show={showAddRolePersona}
          onClose={() => setShowAddRolePersona((prv) => !prv)}
          rolesToAdd={rolesToAdd}
          setRolesToAdd={setRolesToAdd}
          persona={persona}
        ></AddRolePersona>
      )}

      <Stack verticalFill>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Text style={titleStyle}>Incorporate</Text>
          <TooltipHost content={"Remove all"}>
            <IconButton
              iconProps={deleteAllRolesIcon}
              onClick={() => setRolesToAdd([])}
              disabled={!(rolesToAdd.length > 0)}
            />
          </TooltipHost>
          <PrimaryButton
            text="Add Single"
            onClick={() => setShowAddRole((prv) => !prv)}
          />
          <Facepile personas={personas} overflowPersonas={personas} />
        </Stack>
        <Table
          persistOpts={{
            key: "table-rolesAndScopes",
            version: 2,
          }}
          header={{
            title: "",
          }}
          items={rolesToAdd}
          columns={getColumns({
            hasActions: true,
            onDelete,
          })}
          hasSelection={false}
          isLoading={showAddRole}
          isError={rolesToAdd.length === 0}
        />
      </Stack>
    </div>
  );
};
