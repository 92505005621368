import { axiosInstance } from "../../..";
import {
  ResponseMachineDetailsWithChilds,
  StandstillSettings,
} from "../models";

export async function getMachineDetails(
  id: string
): Promise<ResponseMachineDetailsWithChilds> {
  const response = await axiosInstance.get<ResponseMachineDetailsWithChilds>(
    `/meta/read/v1/machines/${id}`
  );
  return response.data;
}

export async function MachineStandstillSettingsAddOrUpdate(
  id: string,
  config: StandstillSettings
): Promise<any> {
  const urlRequest = "/meta/write/v1/machines/" + id + "/standstillSettings";
  const response = await axiosInstance.post<any>(urlRequest, config);
  return response;
}

export async function signalsExport(machineId: string): Promise<any> {
  const response = await axiosInstance.get<any>(
    "/meta/read/v1/machines/" + machineId + "/signals/export"
  );
  return response;
}
