import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Status } from "../../../schema/status";
import { RootState } from "../../../store";
import { fetchData } from "./api";
import { CorporationDetails } from "../models";
import { Company } from "../../Companies/models";

export const listAsyncCorpoDetails = createAsyncThunk(
  "corporationDetail",
  async (id: string) => {
    const response = await fetchData(id);
    return response;
  }
);

export interface toSortbyKey {
  sort: boolean;
  key: string;
}

export interface CorporationDetailsState {
  corporationDet: CorporationDetails;
  status: Status;
  error: string;
}

const initialState: CorporationDetailsState = {
  corporationDet: { companies: [], id: "", name: "", number: "" },
  status: Status.loading,
  error: "",
};

export const corporationDetailsSlice = createSlice({
  name: "corporationDetails",
  initialState,
  reducers: {
    sortValues: (
      state: CorporationDetailsState,
      action: PayloadAction<toSortbyKey>
    ) => {
      const key = action.payload.key as keyof Company;
      state.corporationDet.companies = state.corporationDet.companies
        .slice(0)
        .sort((a: Company, b: Company) =>
          (
            action.payload.sort
              ? a[key].toLowerCase() < b[key].toLowerCase()
              : a[key].toLowerCase() > b[key].toLowerCase()
          )
            ? 1
            : -1
        );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncCorpoDetails.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncCorpoDetails.fulfilled, (state, action) => {
        state.status = Status.idle;
        state.error = "";
        state.corporationDet = action.payload;
      })
      .addCase(listAsyncCorpoDetails.rejected, (state) => {
        state.status = Status.error;
        state.error = "Error loading corporations. Please try again later.";
      });
  },
});

export const selectCorpCompas = (state: RootState) =>
  state.corporationDetails.corporationDet.companies;

export const selectCorpDetails = (state: RootState) =>
  state.corporationDetails.corporationDet;

export const selectCorporationDetailsStatus = (state: RootState) =>
  state.corporationDetails.status;

export const selectCorporationDetailsError = (state: RootState) =>
  state.corporationDetails.error;

export const { sortValues } = corporationDetailsSlice.actions;
export default corporationDetailsSlice.reducer;
