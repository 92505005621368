import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { axiosInstance } from "..";
import { useWebAnalysisPermissions } from "web-analysis-lib";

export interface IAuthorizationResult {
  userAdministrator: boolean;
  metaDataReader: boolean;
  metaDataContributor: boolean;
  auditReader: boolean;
  measuredDataReader: boolean;
  measuredDataContributor: boolean;
  machineCvReader: boolean;
  machineCvContributor: boolean;
  machineCvAdministrator: boolean;
  powerBiReader: boolean;
  powerBiContributor: boolean;
  imageContributor: boolean;
  imageAdministrator: boolean;
  wirelessSensorReader: boolean;
  wirelessSensorContributor: boolean;
  vpnReader: boolean;
  vpnContributor: boolean;
  submissionContributor: boolean;
  submissionAdministrator: boolean;
  mobileAppUser: boolean;
  wirelessSensorTriggerContributor: boolean;
  accessToken: string;
  loading: boolean;
}

export function useAuthorization() {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const { setPermissions } = useWebAnalysisPermissions();

  const defaultAppPermissions = {
    userAdministrator: false,
    metaDataReader: false,
    metaDataContributor: false,
    auditReader: false,
    measuredDataReader: false,
    measuredDataContributor: false,
    machineCvReader: false,
    machineCvContributor: false,
    machineCvAdministrator: false,
    powerBiReader: false,
    powerBiContributor: false,
    imageContributor: false,
    imageAdministrator: false,
    wirelessSensorReader: false,
    wirelessSensorContributor: false,
    vpnReader: false,
    vpnContributor: false,
    submissionContributor: false,
    submissionAdministrator: false,
    mobileAppUser: false,
    wirelessSensorTriggerContributor: false,
    loading: true,
    accessToken: "",
  };

  const [status, setStatus] = useState<IAuthorizationResult>({
    ...defaultAppPermissions,
  });

  const auth = async () => {
    setStatus({ ...status, loading: true });
    setPermissions({ data: undefined, loading: true });

    if (!isAuthenticated) {
      return;
    }

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        },
      });

      const urlRequest = `/rbac/v1/users/${user?.sub}`;
      const response = await axiosInstance.get<UserDetails>(urlRequest);
      const permissions = response?.data?.permissions ?? [];
      setPermissions({ data: permissions, loading: false });

      const hasRole = (role: string) =>
        permissions.some((e) => e.role === role);

      const userAdministrator = hasRole("UserAdministrator");
      let metaDataReader = hasRole("MetaDataReader");
      const metaDataContributor = hasRole("MetaDataContributor");
      if (metaDataContributor) metaDataReader = true;

      const auditReader = hasRole("AuditReader");
      let measuredDataReader = hasRole("MeasuredDataReader");
      const measuredDataContributor = hasRole("MeasuredDataContributor");
      if (measuredDataContributor) measuredDataReader = true;

      let machineCvReader = hasRole("MachineCvReader");
      let machineCvContributor = hasRole("MachineCvContributor");
      const machineCvAdministrator = hasRole("MachineCvAdministrator");
      if (machineCvAdministrator) machineCvContributor = true;
      if (machineCvContributor) machineCvReader = true;

      let powerBiReader = hasRole("PowerBiReader");
      const powerBiContributor = hasRole("PowerBiContributor");
      if (powerBiContributor) powerBiReader = true;

      const imageContributor = hasRole("ImageContributor");
      let imageAdministrator = hasRole("ImageAdministrator");

      let wirelessSensorReader = hasRole("WirelessSensorReader");
      const wirelessSensorContributor = hasRole("WirelessSensorContributor");
      if (wirelessSensorContributor) wirelessSensorReader = true;

      let vpnReader = hasRole("VpnReader");
      const vpnContributor = hasRole("VpnContributor");
      if (vpnContributor) vpnReader = true;

      let submissionContributor = hasRole("SubmissionContributor");
      const submissionAdministrator = hasRole("SubmissionAdministrator");
      if (submissionAdministrator) submissionContributor = true;

      const mobileAppUser = hasRole("MobileAppUser");
      const wirelessSensorTriggerContributor = hasRole(
        "WirelessSensorTriggerContributor"
      );

      setStatus({
        userAdministrator,
        metaDataReader,
        metaDataContributor,
        auditReader,
        measuredDataReader,
        measuredDataContributor,
        machineCvReader,
        machineCvContributor,
        machineCvAdministrator,
        powerBiReader,
        powerBiContributor,
        imageContributor,
        imageAdministrator,
        wirelessSensorReader,
        wirelessSensorContributor,
        vpnReader,
        vpnContributor,
        submissionContributor,
        submissionAdministrator,
        mobileAppUser,
        wirelessSensorTriggerContributor,
        loading: false,
        accessToken,
      });
    } catch (e) {
      console.error("Get User Details", e);
    }
  };

  useEffect(() => {
    auth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return { ...status, auth };
}

interface UserDetails {
  permissions: PermissionExpanded[];
}

interface PermissionExpanded {
  id: string;
  role: string;
  scope: string;
  scopeResource: string;
  expiresAtUtc: Date;
}
