import { axiosInstance } from "../../..";
import { ResponseWirelessGatewayDetailsWithChildren } from "../models";

export async function fetchData(
  id: string
): Promise<ResponseWirelessGatewayDetailsWithChildren> {
  const response =
    await axiosInstance.get<ResponseWirelessGatewayDetailsWithChildren>(
      `/meta/read/internal/v1/wirelessGateways/${id}`
    );
  return response.data;
}
