import { Text } from "@fluentui/react/lib/Text";
import "./SectionContainer.css";
import { Spinner, SpinnerSize } from "@fluentui/react";

type SectionContainerProps = {
  headerTitle?: string;
  isLoading?: boolean;
  children: React.ReactNode;
};

const SectionContainer = ({
  headerTitle,
  isLoading,
  children,
}: SectionContainerProps) => {
  if (isLoading) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <Spinner
            size={SpinnerSize.large}
            style={{
              marginBottom: 5,
            }}
          />
          <Text
            style={{
              fontSize: 12,
            }}
          >
            Loading
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div className="section-container">
      <div className="section-container-header">
        <Text block className="section-container-header-title">
          {headerTitle}
        </Text>
      </div>

      {children}
    </div>
  );
};

export default SectionContainer;
