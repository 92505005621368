/* eslint-disable react-hooks/exhaustive-deps */
import ConnectionsList, {
  ConnectionsTableItem,
} from "./vpnConnections/ConnectionsList";
import { useContext, useEffect, useState } from "react";
import {
  AutomationStatusType,
  ConnectionStatusType,
  ConnectionEntryBasic,
  MetadataProject,
} from "../Schema/models";
import {
  ConnectionItemDialogProps,
  ConnectionsActionsList,
} from "../Schema/viewModels";
import { AddConnectionDialog } from "./vpnConnections/AddEditConnectionDialog";
import VpnPCsListDialog from "./vpnPCs/VpnPCsListDialog";
import {
  BaseCommandBar,
  CommandBarItemProps,
  CommandBarItemType,
  computeCommandBarItems,
  notification,
  useTableFilters,
} from "web-analysis-lib";
import { WithLoadingPanelHOC } from "./generic/HOCs";
import { Stack } from "@fluentui/react";
import { AxiosContext } from "../VpnConnectionsManager/VpnConnectionsManager";
import { VpnConnectionsAPI } from "../Schema/api";
import React from "react";

type BarItemsProps = {
  hasAdminPermissions: boolean;
  onAdd: () => void;
  onVpnPcDetails: () => void;
};

type AppProps = {
  hasAdminPermissions: boolean;
  projects: MetadataProject[];
  onClose: () => void;
};

/**
 * Gets the command bar items props.
 * @param hasAdminPermissions value determining whether there is administrator permissions.
 * @param onVpnPcDetails Method called when the VPN PCs Details button is clicked.
 * @returns The Command bar item props list.
 */
const getBarItems = ({
  hasAdminPermissions,
  onAdd,
  onVpnPcDetails,
}: BarItemsProps): CommandBarItemProps[] => {
  let userActionsProps: CommandBarItemProps[] = [
    {
      key: "addVpnConnection",
      text: "Add",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Add" },
      onClick: onAdd,
    },
  ];

  let adminActionProps: CommandBarItemProps[] = [
    ...userActionsProps,
    {
      key: "vpnPcDetails",
      text: "VPN PC Details",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "ThisPC" },
      onClick: onVpnPcDetails,
    },
  ];

  return hasAdminPermissions ? adminActionProps : userActionsProps;
};

/**
 * Builds and gets the table items
 * @param companies The companies list.
 * @param projects The projects list.
 * @param vpnPcs The VPN PCs list.
 * @param items The VPN Connections entries.
 * @returns
 */
const buildTableItems = (
  projects: MetadataProject[],
  items: ConnectionEntryBasic[]
): ConnectionsTableItem[] => {
  let result: ConnectionsTableItem[] = items.map((item) => {
    let project = projects.find((p) => p.id === item.projectId);
    let entry: ConnectionsTableItem = {
      id: item?.id || "",
      projectName: project?.name || "",
      companyName: project?.company?.name || "",
      automationStatus: AutomationStatusType[item.automationStatus.status],
      connectionStatus: ConnectionStatusType[item.connectionStatus.status],
      comments: item.connectionStatus.comment || "",
    };

    return entry;
  });

  return result;
};

/**
 * Gets the action dialog component, according to an action selection
 * @param action The selected action.
 * @param companies The companies list.
 * @param projects The projects list.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The current dialog component. Or null if there is no selected item.
 */
const ActionDialog = ({
  action,
  projects,
  onClose,
}: ConnectionItemDialogProps) => {
  let result: JSX.Element | null = null;

  switch (action) {
    case ConnectionsActionsList.Add:
      result = <AddConnectionDialog projects={projects} onClose={onClose} />;
      break;

    case ConnectionsActionsList.VpnPCsDetails:
      result = <VpnPCsListDialog onClose={onClose} />;
      break;
  }

  return result;
};

/**
 * Gets the VPN Connections Manager component.
 * @param hasAdminPermissions value determining whether there is administrator permissions.
 * @param axiosInstance The axios instance.
 * @param projects The projects list.
 * @param onClose Method called to close this component.
 * @returns The VPN Connections manager component
 */
const App = ({ hasAdminPermissions, projects, onClose }: AppProps) => {
  const axiosInstance = useContext(AxiosContext);
  const [tableItems, setTableItems] = useState<ConnectionsTableItem[]>([]);
  const [selectedAction, setSelectedAction] = useState<ConnectionsActionsList>(
    ConnectionsActionsList.None
  );
  const { filters, handleSearch } = useTableFilters<ConnectionsTableItem>({
    keys: ["projectName", "companyName"],
  });
  const [listChanged, setListChanged] = useState<boolean>(true);

  // Gets the VPN connection list.
  useEffect(() => {
    if (projects.length === 0 || !axiosInstance || !listChanged) {
      return;
    }

    VpnConnectionsAPI.list(axiosInstance).then((response) => {
      if (response.status !== 200) {
        notification.error(
          `Failure getting the VPN connections list: ${response.statusText}.`
        );

        onClose?.();
        return;
      }

      let tableItems: ConnectionsTableItem[] = buildTableItems(
        projects,
        response.data
      );

      setTableItems(tableItems);
      setListChanged(false);
    });
  }, [projects, listChanged]);

  // Handlers
  const onAdd = () => {
    setSelectedAction(ConnectionsActionsList.Add);
  };

  const onVpnPcDetails = () => {
    setSelectedAction(ConnectionsActionsList.VpnPCsDetails);
  };

  const onCloseDialog = (listChanged?: boolean) => {
    listChanged && setListChanged(true);
    setSelectedAction(ConnectionsActionsList.None);
  };

  return (
    <div className="vpn-connections-container">
      <header>
        <Stack horizontal verticalAlign="center" tokens={{ padding: 8 }}>
          <h1
            id="vpn-connections-section"
            className="area-title"
            style={{ minWidth: "14em" }}
          >
            VPN Connections Manager
          </h1>
          <Stack.Item style={{ width: "100%" }}>
            <BaseCommandBar
              aria-labelledby="vpn-connections-section"
              items={computeCommandBarItems(
                getBarItems({
                  hasAdminPermissions,
                  onAdd,
                  onVpnPcDetails,
                })
              )}
              onSearch={handleSearch}
              styles={{
                root: { marginBottom: "0em", background: "transparent" },
              }}
            />
          </Stack.Item>
        </Stack>
      </header>
      <main className="vpn-connections-container">
        {WithLoadingPanelHOC(
          tableItems?.length > 0,
          <ConnectionsList
            hasAdminPermissions={hasAdminPermissions}
            filters={filters}
            projects={projects}
            tableItems={tableItems}
            onConnectionDataChanged={() => setListChanged(true)}
          />
        )}

        {selectedAction !== ConnectionsActionsList.None && (
          <ActionDialog
            action={selectedAction}
            projects={projects}
            onClose={onCloseDialog}
          />
        )}
      </main>
    </div>
  );
};

export default App;
