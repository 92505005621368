/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks";
import ControlledComboBox from "../../Generic/ControlledComboBox";
import { selectMachinesToList } from "../../Machines/reducer";
import { MachineToList } from "../../Machines/models";
import React from "react";
import { useProjects } from "../../../Hooks/useProjects";

type MachineFormItemProps = {
  initialMachineId: string;
  onMachineSelected: (machine: MachineToList) => void;
};

/**
 * Gets the machine form item component
 * @param initialMachineId The initial machine ID.
 * @param onMachineSelected Method called when a machine has been selected.
 * @returns The machine form item component
 */
const MachineFormItems = ({
  initialMachineId,
  onMachineSelected,
}: MachineFormItemProps) => {
  const { projects } = useProjects();
  const [projectId, setProjectId] = useState<string>("");
  const [machineId, setMachineId] = useState<string>(initialMachineId);
  const machines = useAppSelector(selectMachinesToList);

  // Selects the initial value.
  useEffect(() => {
    if (projects.size === 0) {
      return;
    }

    let machine = machines.find((m) => m.id === initialMachineId);
    if (!machine) {
      return;
    }

    setProjectId(projects.get(machine.projectId)?.id ?? "");
    setMachineId(machine.id);
    onMachineSelected?.(machine);
  }, [initialMachineId, projects.size]);

  // Handlers
  const onMachineIdSelected = (key: string) => {
    setMachineId(key);
    let machine = machines.find((m) => m.id === key);
    machine && onMachineSelected?.(machine);
  };

  return (
    <React.Fragment>
      <ControlledComboBox
        label={`Project`}
        required
        options={Array.from(projects.values())
          .filter((p) => p.name !== null)
          .map((p) => {
            return {
              key: p.id,
              text: `${p.name} (${p.company?.name})`,
            };
          })}
        selectedKey={projectId}
        disabled={false}
        onKeySelected={(key) => {
          key !== machineId && setMachineId("");
          setProjectId(key);
        }}
      />
      <ControlledComboBox
        label="Machine"
        required
        options={machines
          .filter((m) => m.name !== null && m.projectId === projectId)
          .map((m) => {
            return { key: m.id, text: m.name };
          })}
        selectedKey={machineId}
        disabled={false}
        onKeySelected={onMachineIdSelected}
      />
    </React.Fragment>
  );
};

export default MachineFormItems;
