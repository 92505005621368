import { axiosInstance } from "../../..";
import { ResponseSimplifiedSignalWithAlertLevel } from "./models";

export async function signalGet(
  machineId: string,
  signalId: string
): Promise<any | ResponseSimplifiedSignalWithAlertLevel> {
  const response = await axiosInstance.get<any>(
    "/meta/read/v1/machines/" + machineId + "/signals/" + signalId
  );
  if (response.data) return response.data;
  else return response;
}
