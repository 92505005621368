import { axiosInstance } from "../../../..";
import {
  CreateFrequencyCatalogueGroup,
  CreateFrequencyCatalogueItem,
  FrequencyCatalogueDbEntry,
  FrequencyCatalogueGroupDbEntry,
  FrequencyCatalogueGroupsAndItemsResponse,
  FrequencyCatalogueItem,
  UpdateFrequencyCatalogueGroup,
} from "./models";

export async function addFrequencyCatalogueItem(
  machineId: string,
  frequency: CreateFrequencyCatalogueItem
): Promise<FrequencyCatalogueItem | any> {
  const urlRequest =
    "/meta/write/v1/machines/" + machineId + "/frequencyCatalogue/";
  const response = await axiosInstance.post<FrequencyCatalogueItem | any>(
    urlRequest,
    frequency
  );
  if (response.data) return response.data;
  else return response;
}
export async function editFrequencyCatalogueItem(
  id: string,
  machineId: string,
  frequency: FrequencyCatalogueItem
): Promise<FrequencyCatalogueItem | any> {
  const urlRequest =
    "/meta/write/v1/machines/" + machineId + "/frequencyCatalogue/" + id;
  const response = await axiosInstance.put<FrequencyCatalogueItem | any>(
    urlRequest,
    frequency
  );
  if (response.data) return response.data;
  else return response;
}

export async function editMultipleFrequencyCatalogueItems(
  items: FrequencyCatalogueItem[],
  machineId: string
): Promise<(FrequencyCatalogueItem | any)[]> {
  const editedPromises = items.map((item) =>
    editFrequencyCatalogueItem(item.id, machineId, item)
  );
  return await Promise.all(editedPromises);
}

export async function deleteFrequencyCatalogueItem(
  id: string,
  machineId: string
): Promise<FrequencyCatalogueItem | any> {
  const urlRequest =
    "/meta/write/v1/machines/" + machineId + "/frequencyCatalogue/" + id;
  const response = await axiosInstance.delete<FrequencyCatalogueItem | any>(
    urlRequest
  );
  if (response.data) return response.data;
  else return response;
}

export async function deleteMultipleFrequencyCatalogueItems(
  ids: string[],
  machineId: string
): Promise<(FrequencyCatalogueItem | any)[]> {
  const deletePromises = ids.map((id) =>
    deleteFrequencyCatalogueItem(id, machineId)
  );
  return await Promise.all(deletePromises);
}

export async function frequencyCatalogueList(
  machineId: string
): Promise<any | FrequencyCatalogueItem[]> {
  const response = await axiosInstance.get<any>(
    "/meta/read/internal/v1/machines/" + machineId + "/frequencyCatalogue/"
  );
  if (response.data)
    return response.data.sort((a, b) => {
      if (a.groupName < b.groupName) {
        return -1;
      }
      if (a.groupName > b.groupName) {
        return 1;
      }
      return 0;
    });
  else return response;
}

export async function frequencyCatalogueImport(
  machineId: string,
  file_in: any
): Promise<any> {
  const formData = new FormData();
  formData.append("file", file_in!);
  const urlRequest =
    "/meta/write/v1/machines/" + machineId + "/frequencyCatalogue/import/";
  const response = await axiosInstance.post<any>(urlRequest, formData);
  return response;
}
export async function frequencyCatalogueExport(id: string): Promise<any> {
  const response = await axiosInstance.get<any>(
    "/meta/read/internal/v1/machines/" +
      id +
      "/frequencyCatalogue/export?format=csv"
  );
  if (response.data) return response.data;
  else return response;
}

/// All about groups

export async function frequencyCatalogueGroupDetails(
  machineId: string
): Promise<any | FrequencyCatalogueGroupsAndItemsResponse> {
  const response = await axiosInstance.get<any>(
    "/meta/read/internal/v1/machines/" + machineId + "/frequencyCatalogueGroup"
  );
  if (response.data) {
    response.data.items = response.data.items.map(
      (item: FrequencyCatalogueDbEntry) => ({
        ...item,
        frequencyInHz: parseFloat(item.frequencyInHz.toFixed(3)),
      })
    );
    return response.data;
  } else {
    return response;
  }
}

export async function deleteFrequencyCatalogueGroup(
  id: string,
  machineId: string
): Promise<FrequencyCatalogueGroupDbEntry | any> {
  const urlRequest =
    "/meta/write/v1/machines/" + machineId + "/frequencyCatalogueGroup/" + id;
  const response = await axiosInstance.delete<
    FrequencyCatalogueGroupDbEntry | any
  >(urlRequest);
  if (response.data) return response.data;
  else return response;
}

export async function editFrequencyCatalogueGroup(
  id: string,
  machineId: string,
  frequency: UpdateFrequencyCatalogueGroup
): Promise<FrequencyCatalogueGroupDbEntry | any> {
  const urlRequest =
    "/meta/write/v1/machines/" + machineId + "/frequencyCatalogueGroup/" + id;
  const response = await axiosInstance.put<
    FrequencyCatalogueGroupDbEntry | any
  >(urlRequest, frequency);
  if (response.data) return response.data;
  else return response;
}

export async function addFrequencyCatalogueGroup(
  machineId: string,
  frequency: CreateFrequencyCatalogueGroup
): Promise<FrequencyCatalogueGroupDbEntry | any> {
  const urlRequest =
    "/meta/write/v1/machines/" + machineId + "/frequencyCatalogueGroup/";
  const response = await axiosInstance.post<
    FrequencyCatalogueGroupDbEntry | any
  >(urlRequest, frequency);
  if (response.data) return response.data;
  else return response;
}

export async function deleteAllFrequencyCatalogueGroups(
  machineId: string
): Promise<any> {
  const urlRequest =
    "/meta/write/v1/machines/" + machineId + "/frequencyCatalogueGroup/";
  const response = await axiosInstance.delete<any>(urlRequest);
  return response;
}
