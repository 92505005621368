import { AskRequest, AskResponse, ChatRequest } from "./models";
import { axiosInstance } from "../../..";

export async function askApi(options: AskRequest): Promise<AskResponse> {
  const response = await axiosInstance.post<AskResponse>(
    "meta/openAi/v1/ask/",
    {
      question: options.question,
      //approach: options.approach,
      approach: "rtr", // hard coded currently
      // overrides: {
      //     semantic_ranker: options.overrides?.semanticRanker,
      //     semantic_captions: options.overrides?.semanticCaptions,
      //     top: options.overrides?.top,
      //     temperature: options.overrides?.temperature,
      //     prompt_template: options.overrides?.promptTemplate,
      //     prompt_template_prefix: options.overrides?.promptTemplatePrefix,
      //     prompt_template_suffix: options.overrides?.promptTemplateSuffix,
      //     exclude_category: options.overrides?.excludeCategory
      // }
    }
  );

  return response.data;
}

export async function chatApi(options: ChatRequest): Promise<AskResponse> {
  const response = await fetch("/chat", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      history: options.history,
      approach: options.approach,
      overrides: {
        semantic_ranker: options.overrides?.semanticRanker,
        semantic_captions: options.overrides?.semanticCaptions,
        top: options.overrides?.top,
        temperature: options.overrides?.temperature,
        prompt_template: options.overrides?.promptTemplate,
        prompt_template_prefix: options.overrides?.promptTemplatePrefix,
        prompt_template_suffix: options.overrides?.promptTemplateSuffix,
        exclude_category: options.overrides?.excludeCategory,
        suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
      },
    }),
  });

  const parsedResponse: AskResponse = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || "Unknown error");
  }

  return parsedResponse;
}

export function getCitationFilePath(citation: string): string {
  return `/content/${citation}`;
}
