import { Pivot, PivotItem, Text } from "@fluentui/react";
import { CSSProperties } from "react";

const titleStylePivot: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "auto",
};

export interface PropsJsxForPivotsMachineDet {
  headerText: string;
  itemKey: string;
  textOnRender: string;
  content: JSX.Element;
  show: boolean;
}

export const PivotRenderPermissionsBased = (
  childrenArr: PropsJsxForPivotsMachineDet[]
) => {
  return (
    <Pivot aria-label="Pivots to add permission">
      {childrenArr
        .filter((ele) => ele.show === true)
        .map((item) => (
          <PivotItem
            key={item.itemKey}
            headerText={item.headerText}
            itemKey={item.itemKey}
            onRenderItemLink={() => (
              <Text style={titleStylePivot}>{item.textOnRender}</Text>
            )}
          >
            {item.content}
          </PivotItem>
        ))}
    </Pivot>
  );
};
