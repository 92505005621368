export enum DModels {
  D850 = "850",
  D850Eco = "850 Eco",
  D650 = "650",
  D555 = "555",
  D325 = "325",
}

export enum D850OS {
  WindowsCE = "Windows CE",
  Linux = "Linux",
}

export enum AdjustmentCardTypes {
  MKM = "MKM",
  SixteenBits = "16 bits",
  TwentyFourBits = "24 bits",
}

export type D850EcoBasisCard = {
  id?: string;
  d850EcoId?: string;
  macAddress: string;
};

export type D850BasisCard = {
  id?: string;
  d850Id?: string;
  macAddress: string;
};

export type D850EcoAdjustmentCard = {
  id?: string;
  d850EcoId?: string;
  adjustmentCardType: string;
};

export type D850AdjustmentCard = {
  id?: string;
  d850Id?: string;
  adjustmentCard: string;
};

/**
 * Type for a base datalogger.
 */
export type DBase = {
  id?: string;
  projectId: string;
  machineIds: string[];
};

/**
 * Type for the 325 dataloggers.
 */
export type D325 = DBase & {
  macAddress: string;
  firmwareVersion: string;
};

/**
 * Type for the 555 and 650 dataloggers.
 */
export type DBasic = D325 & {
  ipAddress: string;
  subnetMask?: string;
  gateway?: string;
};

/**
 * Type for the 850 Eco datalogger.
 */
export type D850Eco = DBase & {
  basisCard: D850EcoBasisCard;
  adjustmentCard: D850EcoAdjustmentCard;
};

/**
 * Type for the 850 datalogger.
 */
export type D850 = DBase & {
  operativeSystem: string;
  ipAddress: string;
  subnetMask?: string;
  gateway?: string;
  basisCard: D850BasisCard;
  adjustmentCard: D850AdjustmentCard;
};

export type ServiceAccountDdpConfigItem = {
  name: string;
  apiKey: string;
};
