/* eslint-disable react-hooks/exhaustive-deps */
import { IPersonaProps, Persona, Stack } from "@fluentui/react";
import { AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { Text } from "@fluentui/react/lib/Text";

type UserPersonaProps = IPersonaProps & {
  userId: string;
  axiosInstance: AxiosInstance;
};

type UserDetails = {
  properties: { displayName: string };
  pictureBase64: string;
  default?: any;
};

/**
 * Extracts a name's initials.
 * @param name The name.
 * @returns The name initials. Otherwise, an empty string.
 */
export const extractNameInitials = (name: string | undefined): string => {
  if (!name) return "";
  const words: string[] = name.split(" ");
  const initials: string[] = words.map((word) => word.charAt(0).toUpperCase());
  if (initials.length > 4) {
    initials.length = 4;
  }

  return initials.join("");
};

/**
 * Gets the user persona component
 * @param userId the User Id
 * @param axiosInstance The axios instance
 * @returns The user persona component
 */
const UserPersona = ({ userId, axiosInstance, ...rest }: UserPersonaProps) => {
  const [userDetails, setUserDetails] = useState<UserDetails>({
    properties: { displayName: "" },
    pictureBase64: "",
  });

  // Gets the user details.
  useEffect(() => {
    axiosInstance
      .get<UserDetails>(`/rbac/v1/users/${userId}`)
      .then((response) => {
        if (response.status !== 200) {
          return;
        }

        setUserDetails(response.data);
      });
  }, [userId]);

  return (
    <Stack horizontalAlign="center" tokens={{ childrenGap: 5 }}>
      <Persona
        {...rest}
        imageUrl={`data:image/jpeg;base64,${userDetails?.pictureBase64}`}
        imageAlt="Persona photo"
        imageInitials={extractNameInitials(userDetails?.properties.displayName)}
        styles={{ root: { display: "block" } }}
      />
      <Text variant="mediumPlus" block>
        {userDetails?.properties.displayName}
      </Text>
    </Stack>
  );
};

export default UserPersona;
