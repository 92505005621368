/* eslint-disable react-hooks/exhaustive-deps */
import { TooltipHost, Icon, Text, Stack } from "@fluentui/react";
import { createContext, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Status } from "../../schema/status";
import { MachineToList, ResponseMachineDetails } from "./models";
import {
  listAsyncMachines,
  selectMachinesStatus,
  selectMachinesToList,
} from "./reducer";
import {
  BaseCommandBar,
  Column,
  CommandBarItemProps,
  CommandBarItemType,
  Table,
  computeCommandBarItems,
  useTableFilters,
} from "web-analysis-lib";
import { DeleteConfirm } from "./DeleteConfirm";
import { format } from "../../schema/Utils";
import {
  commandBarStyles,
  iconStyle,
  linkStyle,
  pageStyle,
  titleStyle,
} from "../../schema/Constants";
import { MachineAddDialog, MachineEditDialog } from "./MachineAddEditDialogs";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";

enum UserActions {
  None,
  Add,
  Edit,
  Delete,
}

type GetColumnsOpts = {
  hasActions: boolean;
  onEdit: (machine: MachineToList) => void;
  onDelete: (machine: MachineToList) => void;
};

type ActionDialogRequest = {
  selectedItem?: MachineToList;
  action: UserActions;
};

type ActionDialogProps = ActionDialogRequest & {
  machines: MachineToList[];
  onClose: () => void;
};

export const machinesContext = createContext<MachineToList[] | undefined>(
  undefined
);

const getCommandBarItems = (
  hasWritePermission: boolean,
  onAdd: () => void,
  onClick: () => void
) => {
  let titleProp: CommandBarItemProps = {
    key: "title",
    type: CommandBarItemType.Custom,
    onRender: () => <Text style={titleStyle}>Machines</Text>,
  };

  let refreshButtonProp: CommandBarItemProps = {
    key: "refresh",
    text: "Refresh",
    type: CommandBarItemType.Button,
    iconProps: { iconName: "Refresh" },
    onClick: onClick,
  };

  let actionButtonsProps: CommandBarItemProps[] = [
    {
      key: "add",
      text: "Add",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Add" },
      onClick: onAdd,
    },
  ];

  let result: CommandBarItemProps[] = hasWritePermission
    ? [titleProp, ...actionButtonsProps, refreshButtonProp]
    : [titleProp, refreshButtonProp];
  return result;
};

const getColumns = ({
  hasActions,
  onEdit,
  onDelete,
}: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "dalogId",
      name: "Dalog Id",
      fieldName: "dalogId",
      minWidth: 100,
      isSortable: true,
      onRender: ({ id, dalogId }: ResponseMachineDetails) => (
        <Link to={id} style={linkStyle}>
          {dalogId}
        </Link>
      ),
    },
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "projectName",
      name: "Project",
      fieldName: "projectName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "companyName",
      name: "Company",
      fieldName: "companyName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "corporationName",
      name: "Corporation",
      fieldName: "corporationName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "manufacturer",
      name: "Manufacturer",
      fieldName: "manufacturer",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "customerCode",
      name: "Customer Code",
      fieldName: "customerCode",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "dataFrom",
      name: "Data From",
      fieldName: "dataFrom",
      minWidth: 150,
      isSortable: true,
      onRender: ({ trendDataFrom }: ResponseMachineDetails) =>
        trendDataFrom && format(new Date(trendDataFrom)),
    },
    {
      key: "dataUntil",
      name: "Data Until",
      fieldName: "dataUntil",
      minWidth: 150,
      isSortable: true,
      onRender: ({ dataUntil }: ResponseMachineDetails) =>
        dataUntil && format(new Date(dataUntil)),
    },
  ];
  if (hasActions) {
    columns.push({
      key: "actions",
      name: "Actions",
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (machine: MachineToList) => (
        <div style={{ display: "flex" }} key={machine.dalogId + "actions"}>
          <TooltipHost
            key={machine.dalogId + "actions0"}
            content={"Edit"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(machine)}
              style={iconStyle}
            />
          </TooltipHost>
          <TooltipHost
            key={machine.dalogId + "actions1"}
            content={"Delete"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(machine)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    });
  }

  return columns;
};

const ActionDialog = ({
  machines,
  selectedItem,
  action,
  onClose,
}: ActionDialogProps): JSX.Element | null => {
  let result: JSX.Element | null = null;
  if (!machines) {
    return result;
  }

  switch (action) {
    case UserActions.Add:
      result = (
        <MachineAddDialog
          dalogIds={machines.map((gate) => gate.dalogId)}
          onClose={onClose}
        />
      );
      break;

    case UserActions.Edit:
      if (selectedItem) {
        result = (
          <MachineEditDialog
            tableItem={selectedItem}
            dalogIds={machines.map((gate) => gate.dalogId)}
            onClose={onClose}
          />
        );
      }

      break;

    case UserActions.Delete:
      if (selectedItem) {
        result = <DeleteConfirm data={selectedItem} onClose={onClose} />;
      }

      break;
  }

  return result;
};

const MachinesList = () => {
  const auth = useContext(authContext);
  const machineStatus = useAppSelector(selectMachinesStatus);
  const machines = useAppSelector(selectMachinesToList);
  const dispatch = useAppDispatch();
  const [action, setAction] = useState<ActionDialogRequest>({
    selectedItem: null,
    action: UserActions.None,
  });
  const { filters, handleSearch } = useTableFilters<MachineToList>({
    keys: [
      "dalogId",
      "name",
      "corporationName",
      "companyName",
      "projectName",
      "manufacturer",
      "customerCode",
    ],
  });

  const hasWritePermission = auth.metaDataContributor;

  // Gets the machines list.
  useEffect(() => {
    if (machineStatus !== Status.void) {
      return;
    }

    machineStatus === Status.void && dispatch(listAsyncMachines());
  }, [machineStatus]);

  // Handlers.
  const onAdd = () => {
    setAction({ selectedItem: null, action: UserActions.Add });
  };

  const onEdit = (machine: MachineToList) => {
    setAction({ selectedItem: machine, action: UserActions.Edit });
  };

  const onDelete = (machine: MachineToList) => {
    setAction({ selectedItem: machine, action: UserActions.Delete });
  };

  const onClose = () => {
    setAction({ selectedItem: null, action: UserActions.None });
  };

  return (
    <Stack style={pageStyle}>
      <Stack.Item>
        <BaseCommandBar
          items={computeCommandBarItems(
            getCommandBarItems(hasWritePermission, onAdd, () =>
              dispatch(listAsyncMachines())
            )
          )}
          onSearch={handleSearch}
          styles={commandBarStyles}
        />
      </Stack.Item>
      <Stack.Item style={{ height: "100%" }}>
        <Table
          persistOpts={{
            key: "table-machines",
            version: 2,
          }}
          header={{
            title: "Machines",
          }}
          items={machines}
          columns={getColumns({
            hasActions: hasWritePermission,
            onEdit,
            onDelete,
          })}
          filters={filters}
          hasSelection={false}
          isLoading={machineStatus === Status.loading}
          isError={machineStatus === Status.error}
        />
      </Stack.Item>
      <Stack.Item>
        <ActionDialog
          action={action.action}
          selectedItem={action.selectedItem}
          machines={machines}
          onClose={onClose}
        />
      </Stack.Item>
    </Stack>
  );
};

export default MachinesList;
