/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  selectProjectsStatus,
  selectProjects,
  listAsyncProjs,
} from "../Components/Projects/reducer";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Status } from "../schema/status";
import { ResponseProjectDetails } from "../Components/Projects/models";

export const useProjects = () => {
  const status = useAppSelector(selectProjectsStatus);
  const projectsList = useAppSelector(selectProjects);
  const dispatch = useAppDispatch();
  const [projects, setProjects] = useState<Map<string, ResponseProjectDetails>>(
    new Map<string, ResponseProjectDetails>([])
  );

  // Gets the projects list.
  useEffect(() => {
    status === Status.void && dispatch(listAsyncProjs());
    if (status !== Status.idle || projectsList.length === 0) {
      return;
    }

    // Builds the projects map (id, project)
    let map = new Map<string, ResponseProjectDetails>([]);
    projectsList.forEach((project) => map.set(project.id, project));
    setProjects(map);
  }, [status, projectsList.length]);

  return { projects, status };
};
