import { Stack } from "@fluentui/react";
import { Text } from "@fluentui/react/lib/Text";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { mergeStyles } from "@fluentui/react/lib/Styling";

const iconClass = mergeStyles({
  fontSize: 16,
  marginRight: 10,
});

const Tab = ({ tab, isActive, onClick }) => {
  return (
    <div className={`settings-tab ${isActive ? "active" : ""}`} onClick={onClick}>
      <Stack
        horizontal
        style={{
          alignItems: "center",
        }}
      >
        {tab.hasIcon && (
          <FontIcon
            aria-label={tab.icon}
            iconName={tab.icon}
            className={iconClass}
          />
        )}
        <Text>{tab.label}</Text>
      </Stack>
    </div>
  );
};

export default Tab;