import { axiosInstance } from "../../..";
import { ResponsePowerBIGet } from "../../DashboardsMetadata/models";
import { ProjectDetailsWithChilds } from "../models";

export async function fetchData(id: string): Promise<ProjectDetailsWithChilds> {
  const response = await axiosInstance.get<ProjectDetailsWithChilds>(
    `/meta/read/v1/projects/${id}`
  );
  return response.data;
}

export async function fetchPowerBiTokenPerProject(
  id: string
): Promise<ResponsePowerBIGet> {
  const response = await axiosInstance.get<ResponsePowerBIGet>(
    `meta/powerbi/v1/project/${id}`
  );
  return response.data;
}
