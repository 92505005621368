import { axiosInstance } from "../..";

export async function settingsUsersRBAC(id: string): Promise<any> {
  const response = await axiosInstance.get<any>(`/rbac/v1/users/${id}/settings`);
  return response?.data;
}
  
export async function settingsUpdateUsersRBAC(id: string, payload: object | undefined): Promise<any> {
  const response = await axiosInstance.put<any>(
    `/rbac/v1/users/${id}/settings`, payload
  );
  if (response?.data) return response.data;

  else return response;
}