import { useEffect, useState } from "react";
import { notification } from "web-analysis-lib";
import { GenerateConfigsAPI } from "./api";
import { ResponseSimplifiedSignal } from "../../Machines/models";

/**
 * Gets the useRawSignals hook
 * @param machineId the machine Id
 * @returns The signals list, if fetched successfully.
 */
export const useRawSignals = (machineId: string) => {
  const [signalsAreLoading, setSignalsAreLoading] = useState<boolean>(false);
  const [signals, setSignals] = useState<
    ResponseSimplifiedSignal[] | undefined
  >([]);

  // Fetches the machine signals.
  useEffect(() => {
    if (!machineId) {
      notification.error("There are no machines assigned to this datalogger.");
      setSignals(undefined);
      return;
    }

    setSignalsAreLoading(true);
    GenerateConfigsAPI.getRawSignals(machineId).then((response) => {
      setSignalsAreLoading(false);
      if (response.status !== 200) {
        notification.error(
          `Failure getting raw signals for this datalogger: ${response.statusText}.`
        );
      }

      setSignals(response.data);
    });
  }, [machineId]);

  return { signals, signalsAreLoading };
};
