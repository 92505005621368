import {
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { PropsWithChildren } from "react";
import { BaseDialog, DialogSize } from "web-analysis-lib";

type FormDialogProps = {
  title: string;
  isLoading: boolean;
  isValid: boolean;
  type: DialogType;
  size: DialogSize;
  onSubmit: () => void;
  onClose: () => void;
  submitButtonText?: string;
};

/**
 * Gets a form dialog component with the submit and cancel buttons.
 * @param title The form title.
 * @param type The form type.
 * @param size The form size.
 * @param isLoading Value indicating when the form state is loading.
 * @param isValid Value indicating whether the form data is valid or not.
 * @param onSubmit Function called when the submit button is clicked.
 * @param onClose Function called when the form dialog needs to be closed. (Insert here the function to close which will close this dialog).
 * @returns The form dialog component.
 */
const FormDialog = ({
  title,
  type,
  size,
  isLoading,
  isValid,
  onSubmit,
  onClose,
  submitButtonText,
  children,
  ...rest
}: PropsWithChildren<FormDialogProps>) => {
  return (
    <BaseDialog
      {...rest}
      hidden={false}
      dialogContentProps={{
        title,
        type,
        onDismiss: onClose,
      }}
      size={size}
    >
      {children}
      <DialogFooter>
        <PrimaryButton
          text={submitButtonText ?? "Save Changes"}
          disabled={isLoading || !isValid}
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
          onClick={onSubmit}
        />
        <DefaultButton
          disabled={isLoading}
          styles={{
            root: { border: "unset", background: "transparent" },
          }}
          text="Cancel"
          onClick={onClose}
        />
      </DialogFooter>
    </BaseDialog>
  );
};

export default FormDialog;
