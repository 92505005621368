import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  MetadataProject,
  ConnectionEntryBasic,
  ConnectionEntryDetailed,
  HistoricalChangesItemBasic,
  HistoricalChangesItemDetailed,
  MetadataVpnPC,
} from "./models";

/**
 * * Axios instance.
 */
export const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV_API_BASEURL!,
  headers: { "Ocp-Apim-Subscription-Key": process.env.NODE_ENV_API_KEY! },
  timeout: 1000 * 60 * 10,
});

/**
 * * The VPN Connections API
 */
export class VpnConnectionsAPI {
  /**
   * Gets the projects list.
   * @param axiosInstance The axios instance for the HTTP requests.
   * @returns The axios response, with the projects list.
   */
  static listProjects = async (
    axiosInstance: AxiosInstance
  ): Promise<AxiosResponse<MetadataProject[]>> => {
    let response: AxiosResponse<MetadataProject[]> = {
      status: 0,
      statusText: "",
      data: [],
      headers: {},
      config: {},
    };

    try {
      response = await axiosInstance?.get<MetadataProject[]>(
        "/meta/read/v1/projects"
      );
    } catch (e: any) {
      response.statusText = e?.message || "Unknown error";
    }

    // Sorts the result.
    response.data = response.data.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      }

      return 0;
    });

    return response;
  };

  /**
   * Gets the VPN connections list.
   * @param axiosInstance The axios instance for the HTTP requests.
   * @returns The axios response, with the VPN connections list.
   */
  static list = async (
    axiosInstance: AxiosInstance
  ): Promise<AxiosResponse<ConnectionEntryBasic[]>> => {
    let response: AxiosResponse<ConnectionEntryBasic[]> = {
      status: 0,
      statusText: "",
      data: [],
      headers: {},
      config: {},
    };

    try {
      response = await axiosInstance?.get<ConnectionEntryBasic[]>(
        "/meta/vpn/v1/connections"
      );
    } catch (e: any) {
      response.statusText = e?.message || "Unknown error";
    }

    return response;
  };

  /**
   * Gets the VPN connection item.
   * @param axiosInstance The axios instance for the HTTP requests.
   * @param id The Vpn connection Id.
   * @returns The axios response, with the VPN connection item.
   */
  static get = async (
    axiosInstance: AxiosInstance,
    id: string
  ): Promise<AxiosResponse<ConnectionEntryDetailed | undefined>> => {
    let response: AxiosResponse<ConnectionEntryDetailed | undefined> = {
      status: 0,
      statusText: "",
      data: undefined,
      headers: {},
      config: {},
    };

    try {
      response = await axiosInstance?.get<ConnectionEntryDetailed>(
        `/meta/vpn/v1/connections/${id}`
      );
    } catch (e: any) {
      response.statusText = e?.message || "Unknown error";
    }

    return response;
  };

  /**
   * Creates a VPN connection item.
   * @param axiosInstance The axios instance for the HTTP requests.
   * @param entry The Vpn connection entry.
   * @returns The axios response, with the created VPN connection item.
   */
  static create = async (
    axiosInstance: AxiosInstance,
    entry: ConnectionEntryDetailed
  ): Promise<AxiosResponse<ConnectionEntryDetailed | undefined>> => {
    let response: AxiosResponse<ConnectionEntryDetailed | undefined> = {
      status: 0,
      statusText: "",
      data: undefined,
      headers: {},
      config: {},
    };

    try {
      response = await axiosInstance?.post<ConnectionEntryDetailed>(
        `/meta/vpn/v1/connections`,
        entry
      );
    } catch (e: any) {
      response.statusText = e?.message || "Unknown error";
    }

    return response;
  };

  /**
   * Update a VPN connection item.
   * @param axiosInstance The axios instance for the HTTP requests.
   * @param entry The Vpn connection entry.
   * @returns The axios response, with the created VPN connection item.
   */
  static update = async (
    axiosInstance: AxiosInstance,
    entry: ConnectionEntryDetailed
  ): Promise<AxiosResponse<ConnectionEntryDetailed | undefined>> => {
    let response: AxiosResponse<ConnectionEntryDetailed | undefined> = {
      status: 0,
      statusText: "",
      data: undefined,
      headers: {},
      config: {},
    };

    try {
      if (!entry.id) {
        response.statusText = "No ID found to update the VPN connection";
        return response;
      }

      response = await axiosInstance?.put<ConnectionEntryDetailed>(
        `/meta/vpn/v1/connections/${entry.id}`,
        entry
      );
    } catch (e: any) {
      response.statusText = e?.message || "Unknown error";
    }

    return response;
  };

  /**
   * Deletes a VPN connection item.
   * @param axiosInstance The axios instance for the HTTP requests.
   * @param id The VPN connection id.
   * @returns The axios response, with the deleted VPN connection item.
   */
  static delete = async (
    axiosInstance: AxiosInstance,
    id: string
  ): Promise<AxiosResponse<ConnectionEntryDetailed | undefined>> => {
    let response: AxiosResponse<ConnectionEntryDetailed | undefined> = {
      status: 0,
      statusText: "",
      data: undefined,
      headers: {},
      config: {},
    };

    try {
      response = await axiosInstance?.delete<ConnectionEntryDetailed>(
        `/meta/vpn/v1/connections/${id}`
      );
    } catch (e: any) {
      response.statusText = e?.message || "Unknown error";
    }

    return response;
  };

  /**
   * Gets the changes history of a determined VPN connection
   * @param axiosInstance The axios instance for the HTTP requests.
   * @param id The VPN connection Id.
   * @returns The axios response, with the changes history list.
   */
  static listHistory = async (
    axiosInstance: AxiosInstance,
    id: string
  ): Promise<AxiosResponse<HistoricalChangesItemBasic[]>> => {
    let response: AxiosResponse<HistoricalChangesItemBasic[]> = {
      status: 0,
      statusText: "",
      data: [],
      headers: {},
      config: {},
    };

    try {
      response = await axiosInstance?.get<HistoricalChangesItemBasic[]>(
        `/meta/vpn/v1/connections/${id}/history`
      );
    } catch (e: any) {
      response.statusText = e?.message || "Unknown error";
    }

    return response;
  };

  /**
   * Gets a history item of a determined VPN connection
   * @param axiosInstance The axios instance for the HTTP requests.
   * @param id The VPN connection Id.
   * @param historyId The history changes entry Id.
   * @returns The axios response, with the history item.
   */
  static getHistory = async (
    axiosInstance: AxiosInstance,
    id: string,
    historyId: string
  ): Promise<AxiosResponse<HistoricalChangesItemDetailed | undefined>> => {
    let response: AxiosResponse<HistoricalChangesItemDetailed | undefined> = {
      status: 0,
      statusText: "",
      data: undefined,
      headers: {},
      config: {},
    };

    try {
      response = await axiosInstance?.get<HistoricalChangesItemDetailed>(
        `/meta/vpn/v1/connections/${id}/history/${historyId}`
      );
    } catch (e: any) {
      response.statusText = e?.message || "Unknown error";
    }

    return response;
  };
}

/**
 * * The VPN PCs API
 */
export class VpnPCsAPI {
  /**
   * Gets the VPN PCs list.
   * @param axiosInstance The axios instance for the HTTP requests.
   * @returns The axios response, with the VPN PCs list.
   */
  static list = async (
    axiosInstance: AxiosInstance
  ): Promise<AxiosResponse<MetadataVpnPC[]>> => {
    let response: AxiosResponse<MetadataVpnPC[]> = {
      status: 0,
      statusText: "",
      data: [],
      headers: {},
      config: {},
    };

    try {
      response = await axiosInstance?.get<MetadataVpnPC[]>("/meta/vpn/v1/pcs");
    } catch (e: any) {
      response.statusText = e?.message || "Unknown error";
    }

    // Sorts the result.
    response.data = response.data.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      }

      return 0;
    });

    return response;
  };

  /**
   * Creates a VPN PC.
   * @param axiosInstance The axios instance for the HTTP requests.
   * @param entry The VPN PC object.
   * @returns The axios response, with the created VPN PC item.
   */
  static create = async (
    axiosInstance: AxiosInstance,
    entry: MetadataVpnPC
  ): Promise<AxiosResponse<MetadataVpnPC | undefined>> => {
    let response: AxiosResponse<MetadataVpnPC | undefined> = {
      status: 0,
      statusText: "",
      data: undefined,
      headers: {},
      config: {},
    };

    try {
      response = await axiosInstance?.post<MetadataVpnPC>(
        `/meta/vpn/v1/pcs`,
        entry
      );
    } catch (e: any) {
      response.statusText = e?.message || "Unknown error";
    }

    return response;
  };

  /**
   * Updates a VPN PC.
   * @param axiosInstance The axios instance for the HTTP requests.
   * @param entry The VPN PC object.
   * @returns The axios response, with the updated VPN PC item.
   */
  static update = async (
    axiosInstance: AxiosInstance,
    entry: MetadataVpnPC
  ): Promise<AxiosResponse<MetadataVpnPC | undefined>> => {
    let response: AxiosResponse<MetadataVpnPC | undefined> = {
      status: 0,
      statusText: "",
      data: undefined,
      headers: {},
      config: {},
    };

    try {
      if (!entry.id) {
        response.statusText = "No Id found to update the VPN PC.";
        return response;
      }

      response = await axiosInstance?.put<MetadataVpnPC>(
        `/meta/vpn/v1/pcs/${entry.id}`,
        entry
      );
    } catch (e: any) {
      response.statusText = e?.message || "Unknown error";
    }

    return response;
  };

  /**
   * Deletes a VPN PC.
   * @param axiosInstance The axios instance for the HTTP requests.
   * @param id The VPN PC Id.
   * @returns The axios response, with the deleted VPN PC item.
   */
  static delete = async (
    axiosInstance: AxiosInstance,
    id: string
  ): Promise<AxiosResponse<MetadataVpnPC | undefined>> => {
    let response: AxiosResponse<MetadataVpnPC | undefined> = {
      status: 0,
      statusText: "",
      data: undefined,
      headers: {},
      config: {},
    };

    try {
      response = await axiosInstance?.delete<MetadataVpnPC>(
        `/meta/vpn/v1/pcs/${id}`
      );
    } catch (e: any) {
      response.statusText = e?.message || "Unknown error";
    }

    return response;
  };
}
