import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Status } from "../../schema/status";
import { RootState } from "../../store";
import { ResponseProjectDetails } from "../Projects/models";
import { list } from "./api";
import { DBoarToShow, PowerBIEmbedItem } from "./models";

export const listAsyncDashB = createAsyncThunk("dashboards/list", async () => {
  const response = await list();
  if (response.length > 0)
    return response.sort((a, b) => (a.parentType > b.parentType ? 1 : -1));
  return response;
});

interface toSortbyKey {
  sort: boolean;
  key: string;
}

interface toFindByKey {
  input: string;
  key: string;
}

export interface DashboardsState {
  dashboards: PowerBIEmbedItem[];
  dashboardsIni: PowerBIEmbedItem[];
  dashboardsSort: PowerBIEmbedItem[];
  dashBoardsToShow: DBoarToShow[];
  status: Status;
  error: string;
}

const initialState: DashboardsState = {
  dashboards: [],
  dashboardsIni: [],
  dashboardsSort: [],
  dashBoardsToShow: [],
  status: Status.void,
  error: "",
};

export const dashboardsSlice = createSlice({
  name: "dashboards",
  initialState,
  reducers: {
    sortValues: (
      state: DashboardsState,
      action: PayloadAction<toSortbyKey>
    ) => {
      const key = action.payload.key as keyof PowerBIEmbedItem;
      state.dashboards !== undefined
        ? (state.dashboards = state.dashboards
            .slice(0)
            .sort((a: PowerBIEmbedItem, b: PowerBIEmbedItem) =>
              (
                action.payload.sort
                  ? a[key].toString().toLowerCase() <
                    b[key].toString().toLowerCase()
                  : a[key].toString().toLowerCase() >
                    b[key].toString().toLowerCase()
              )
                ? 1
                : -1
            ))
        : (state.dashboards = []);
    },
    findValues: (
      state: DashboardsState,
      action: PayloadAction<toFindByKey>
    ) => {
      const key = action.payload.key as keyof PowerBIEmbedItem;
      if (state.dashboardsIni.length > 0) {
        state.dashboards = state.dashboardsIni.filter(
          (i) =>
            i[key].toLowerCase().indexOf(action.payload.input.toLowerCase()) >
            -1
        );
        state.dashboardsSort = [...state.dashboards];
      }
    },
    toShowLoad: (
      state: DashboardsState,
      action: PayloadAction<ResponseProjectDetails[]>
    ) => {
      const projsAux = action.payload.filter((projs) => projs.corporation?.id);
      const corposIds_aux = projsAux.map((proj) => proj.corporation.id);

      const projsWithDB = projsAux.filter((proj) => {
        const existOnDashes = state.dashboards.some((dash) => {
          return dash.parentType === "Corporation"
            ? proj!.corporation.id === dash.parentId
            : dash.parentType === "Company"
            ? proj!.company.id === dash.parentId
            : proj!.id === dash.parentId;
        });
        return existOnDashes;
      });

      const corporationIds = Array.from(new Set(corposIds_aux)).filter(
        (idCorp) => {
          const existInProjsWithDB = projsWithDB.some(
            (proj) => proj.corporation.id === idCorp
          );
          return existInProjsWithDB;
        }
      );

      state.dashBoardsToShow = corporationIds.map((idCorp) => {
        const projsPerCorp = projsWithDB.filter(
          (proj) => proj.corporation.id === idCorp
        );
        const corp = projsPerCorp.at(0)?.corporation;
        const dashBoardsPerCorp = state.dashboards.filter((dash) => {
          const found =
            dash.parentType === "Corporation"
              ? projsPerCorp.some((pro) => pro.corporation.id === dash.parentId)
              : dash.parentType === "Company"
              ? projsPerCorp.some((pro) => pro.companyId === dash.parentId)
              : projsPerCorp.some((pro) => pro.id === dash.parentId);
          return found;
        });
        const dashBoardsAux = dashBoardsPerCorp.map((dash) => {
          const project = projsPerCorp.find((pro) =>
            dash.parentType === "Corporation"
              ? pro.corporation.id === dash.parentId
              : dash.parentType === "Company"
              ? pro.companyId === dash.parentId
              : pro.id === dash.parentId
          );
          return {
            ...dash,
            name:
              dash.parentType === "Corporation"
                ? project?.corporation.name
                : dash.parentType === "Company"
                ? project?.company.name
                : project?.name,
          };
        });
        return {
          corporationId: corp!.id,
          corporationName: corp!.name,
          dashBoards: dashBoardsAux,
        };
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncDashB.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncDashB.fulfilled, (state, action) => {
        state.status = Status.idle;
        state.error = "";
        state.dashboards = action.payload;
        state.dashboardsIni = action.payload;
        state.dashboardsSort = action.payload;
      })
      .addCase(listAsyncDashB.rejected, (state) => {
        state.status = Status.error;
        state.error = "Error loading dashboards. Please try again later.";
      });
  },
});

export const selectDashboardsToShow = (state: RootState) =>
  state.dashboards.dashBoardsToShow;

export const selectDashboards = (state: RootState) =>
  state.dashboards.dashboards;

export const selectDashboardsStatus = (state: RootState) =>
  state.dashboards.status;

export const selectDashboard = (id: string) => (state: RootState) => {
  if (
    state.dashboards &&
    state.dashboards.dashboards &&
    state.dashboards.dashboards.length > 0
  ) {
    return state.dashboards.dashboards.find(
      (c: PowerBIEmbedItem) => c.id === id
    );
  }

  return undefined;
};

export const { sortValues, findValues, toShowLoad } = dashboardsSlice.actions;
export default dashboardsSlice.reducer;
