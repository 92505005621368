import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../schema/status";
import { RootState } from "../../store";
import { listUsersRBAC } from "./api";
import { ResponseUserIssuer, UserSimple, UserSimpleWithIssuer } from "./models";

export const listAsyncUsersRBAC = createAsyncThunk(
  "usersRBAC/list",
  async () => {
    const response = await listUsersRBAC();
    return response;
  }
);

export interface UsersRBACState {
  stringFilter: string;
  usersRBAC: ResponseUserIssuer[];
  usersRBACPlain: UserSimpleWithIssuer[];
  usersPlain: UserSimple[];
  status: Status;
  error: string;
}

const initialState: UsersRBACState = {
  stringFilter: "",
  usersRBAC: [],
  usersRBACPlain: [],
  usersPlain: [],
  status: Status.void,
  error: "",
};

export const usersRBACSlice = createSlice({
  name: "usersRBAC",
  initialState,
  reducers: {
    setStringFilter: (state: UsersRBACState, action: PayloadAction<string>) => {
      state.stringFilter = action.payload;
    },
    setToggle: (state: UsersRBACState, action: PayloadAction<string>) => {
      if (state.usersRBACPlain && state.usersRBACPlain.length > 0) {
        const index = state.usersRBACPlain.findIndex(
          (usrList) => usrList.name === action.payload
        );
        const newArray = [...state.usersRBACPlain];
        newArray[index].openDisplay = !newArray[index].openDisplay;
        state.usersRBACPlain = newArray;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncUsersRBAC.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncUsersRBAC.fulfilled, (state, action) => {
        if (action.payload === undefined) {
          state.status = Status.error;
          state.error = "Error loading user list RBAC. Please try again later.";
        } else {
          var init: UserSimple[] = [];
          state.status = Status.idle;
          state.error = "";
          state.usersRBAC = action.payload;
          action.payload.map((user) =>
            user.users.map((user) =>
              init.push({
                name: user.identity.displayName,
                id: user.identity.id,
                issuer: user.identity.issuer,
                hasDdpProperties: user.ddpProperties ? true : false,
                firstName: user.ddpProperties?.firstName,
                lastName: user.ddpProperties?.lastName,
                email: user.ddpProperties?.email,
                lastLoginDate: user.ddpProperties?.lastTokenGenerated,
              })
            )
          );
          const eux: UserSimpleWithIssuer[] = action.payload.map(
            (users: ResponseUserIssuer) => {
              const openDisplayUser = state.usersRBACPlain.find(
                (ele) => ele.name === users.name
              )?.openDisplay;
              return {
                name: users.name,
                openDisplay:
                  openDisplayUser !== undefined ? openDisplayUser : true,
                users: users.users.map((usr) => {
                  return {
                    name: usr.identity.displayName,
                    id: usr.identity.id,
                    issuer: usr.identity.issuer,
                    hasDdpProperties: usr.properties ? true : false,
                    memberId: usr.identity.id ? usr.identity.id : undefined,
                    firstName: usr.properties
                      ? usr.properties.firstName
                      : undefined,
                    lastName: usr.properties
                      ? usr.properties.lastName
                      : undefined,
                    email: usr.properties ? usr.properties.email : undefined,
                    jobTitle: usr.properties
                      ? usr.properties.jobTitle
                      : undefined,
                    companyName: usr.properties
                      ? usr.properties.companyName
                      : undefined,
                    department: usr.properties
                      ? usr.properties.department
                      : undefined,
                    city: usr.properties ? usr.properties.city : undefined,
                    countryOrRegion: usr.properties
                      ? usr.properties.countryOrRegion
                      : undefined,
                    mobilePhone: usr.properties
                      ? usr.properties.mobilePhone
                      : undefined,
                    lastLoginDate: usr.properties
                      ? usr.properties.lastTokenGenerated
                      : undefined,
                  };
                }),
              };
            }
          );
          state.usersPlain = init;
          state.usersRBACPlain = eux;
        }
      })
      .addCase(listAsyncUsersRBAC.rejected, (state) => {
        state.status = Status.error;
        state.error = "Error loading user list RBAC. Please try again later.";
      });
  },
});

export const selectUsersRBAC = (state: RootState) => state.usersRBAC.usersRBAC;

export const selectUsersRBACStringFilter = (state: RootState) =>
  state.usersRBAC.stringFilter;

export const selectUsersRBACPlain = (state: RootState) =>
  state.usersRBAC.usersRBACPlain;

export const selectUsersPlain = (state: RootState) =>
  state.usersRBAC.usersPlain;

export const selectUsersRBACStatus = (state: RootState) =>
  state.usersRBAC.status;

export const selectUsersRBACError = (state: RootState) => state.usersRBAC.error;

export const selectUsersRBACByName = (name: string) => (state: RootState) => {
  if (
    state.usersRBAC &&
    state.usersRBAC.usersRBAC &&
    state.usersRBAC.usersRBAC.length > 0
  ) {
    return state.usersRBAC.usersRBAC.find(
      (c: ResponseUserIssuer) => c.name === name
    );
  }

  return undefined;
};

export const { setStringFilter, setToggle } = usersRBACSlice.actions;
export default usersRBACSlice.reducer;
