import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "../../../..";
import { ImageUploadExtensions } from "../../../../schema/status";
import { ImageQueued } from "./models";

export async function downloadImage(id: string): Promise<any> {
  const urlRequest = "/files/v1/images/" + id;

  const response = await axiosInstance.get<ArrayBuffer | any>(urlRequest, {
    responseType: "arraybuffer",
    // headers: {
    //   Accept: "image/png",
    // },
  });
  if (response.data) return response.data;
  else return response;
}

export async function queuedImageList(): Promise<any | ImageQueued[]> {
  const response = await axiosInstance.get<any>("/files/v1/images");
  if (response.data) return response.data;
  else return response;
}

export interface InputUploadImage {
  file: File;
  machineId: string;
  dalogId: string;
}

export async function uploadImagesGeneral(
  body: InputUploadImage,
  type: ImageUploadExtensions
): Promise<any> {
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  var urlRequest = "";
  switch (type) {
    case ImageUploadExtensions.Sav:
      urlRequest = "/files/v1/images/";
      break;
    case ImageUploadExtensions.Zip:
      urlRequest = "/files/v1/images/zip";
      break;
    case ImageUploadExtensions.GZip:
      urlRequest = "/files/v1/images/gzip";
      break;
    default:
      // Handle any other cases
      console.log("Unsupported file extension");
      break;
  }
  const formData = new FormData();
  formData.append("file", body.file!);
  formData.append("machineId", body.machineId);
  formData.append("dalogId", body.dalogId);

  const response = await axiosInstance.post<any>(urlRequest, formData, config);
  return response;
}
