/* eslint-disable react-hooks/exhaustive-deps */
import { DialogType, TextField } from "@fluentui/react";
import FormDialog from "../../Generic/FormDialog";
import { CfgToMachinePinningItem } from "../models";
import { DialogSize, notification } from "web-analysis-lib";
import { useEffect, useState } from "react";
import { CfgToMachinePinningApi } from "../api";
import MachineFormItems from "../Generic/MachineFormItems";
import { MachineToList } from "../../Machines/models";

type FormData = {
  cfgName: string;
  machineId: string;
};

type CfgPinningAddEditDialogProps = {
  title?: string;
  item?: CfgToMachinePinningItem;
  confignames: Set<string>;
  isLoading?: boolean;
  onSubmit?: (data: FormData) => void;
  onClose: (listChanged: boolean) => void;
};

/**
 * Gets the CFG to Machine Pinning Add Edit dialog component
 * @param title The form title
 * @param item The selected item to edit
 * @param confignames The confignames list reference for validation
 * @param isLoading Value determining whether the form is in loading state.
 * @param onSubmit Method called when the submit button is clicked
 * @param onClose Method called when the close button is clicked
 * @returns The CFG to Machine Pinning Add Edit dialog component
 */
const CfgPinningAddEditDialog = ({
  title,
  item,
  confignames,
  isLoading,
  onSubmit,
  onClose,
}: CfgPinningAddEditDialogProps) => {
  const [machine, setMachine] = useState<MachineToList>(null);
  const [cfgName, setCfgName] = useState<string>(item?.cfgName ?? "");
  const [isValid, setIsValid] = useState<boolean>(!item);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    setErrorMessage("");
  }, [cfgName, confignames]);

  // Checks whether the data is valid..
  useEffect(() => {
    let isDataValid = machine && cfgName?.trim() !== "";
    if (item) {
      isDataValid =
        isDataValid &&
        (item.cfgName.toLocaleLowerCase() !==
          cfgName?.trim().toLocaleLowerCase() ||
          item.machineId !== machine.id);
    }

    setIsValid(isDataValid);
  }, [machine, cfgName]);

  // Handlers
  const onMachineSelected = (machine: MachineToList) => {
    setMachine(machine);
  };

  const onSubmitHandler = () => {
    let formData: FormData = {
      cfgName,
      machineId: machine.id,
    };

    onSubmit?.(formData);
  };

  return (
    <FormDialog
      title={title}
      isLoading={isLoading}
      isValid={isValid && errorMessage === ""}
      type={DialogType.normal}
      size={DialogSize.S}
      onSubmit={onSubmitHandler}
      onClose={() => onClose(false)}
    >
      <MachineFormItems
        initialMachineId={item?.machineId ?? ""}
        onMachineSelected={onMachineSelected}
      />
      <TextField
        label="CFG Name"
        value={cfgName}
        onChange={(_, newValue: string) => {
          setCfgName(newValue);
        }}
        required
        errorMessage={errorMessage}
      />
    </FormDialog>
  );
};

/**
 * Gets the CFG to Machine Pinning Add dialog component
 * @param confignames The confignames list reference for validation
 * @param onClose Method called when the close button is clicked
 * @returns The CFG to Machine Pinning Add dialog component
 */
export const CfgPinningAddDialog = ({
  confignames,
  onClose,
}: CfgPinningAddEditDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Handlers
  const onSubmit = (formData: FormData) => {
    let item: CfgToMachinePinningItem = {
      machineId: formData.machineId,
      cfgName: formData.cfgName.trim(),
    };

    setIsLoading(true);
    CfgToMachinePinningApi.create(item).then((response) => {
      setIsLoading(false);
      if (response.status !== 201) {
        notification.error(
          `Failure creating a CFG to Machine pinning entry: ${response.statusText}.`
        );
        return;
      }

      notification.success("Success creating a CFG to Machine pinning entry.");
      onClose?.(true);
    });
  };

  return (
    <CfgPinningAddEditDialog
      title={"Add CFG to Machine Pinning"}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onClose={onClose}
      confignames={confignames}
    />
  );
};

/**
 * Gets the CFG to Machine Pinning Add dialog component
 * @param item The item to edit.
 * @param confignames The confignames list reference for validation
 * @param onClose Method called when the close button is clicked
 * @returns The CFG to Machine Pinning Add dialog component
 */
export const CfgPinningEditDialog = ({
  item,
  confignames,
  onClose,
}: CfgPinningAddEditDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Handlers
  const onSubmit = (formData: FormData) => {
    let data: CfgToMachinePinningItem = {
      id: item.id,
      machineId: formData.machineId,
      cfgName: formData.cfgName.trim(),
    };

    setIsLoading(true);
    CfgToMachinePinningApi.update(data).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          `Failure updating a CFG to Machine pinning entry: ${response.statusText}.`
        );
        return;
      }

      notification.success("Success updating a CFG to Machine pinning entry.");
      onClose?.(true);
    });
  };

  return (
    <CfgPinningAddEditDialog
      title={"Edit CFG to Machine Pinning"}
      item={item}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onClose={onClose}
      confignames={confignames}
    />
  );
};
