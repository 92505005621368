export enum UserNotificationSettingsType {
  Unknown = "Unknown",
  Enabled = "Enabled",
  Selected = "Selected",
  Disabled = "Disabled",
}

export type UserNotificationSelectedSettings = {
  corporationIds: string[];
  companyIds: string[];
  projectIds: string[];
  machineIds: string[];
};

export type UserNotificationSettings = {
  email: string;
  emailSelected: UserNotificationSelectedSettings;
  teams: string;
  teamsSelected: UserNotificationSelectedSettings;
};

export type UserNotificationsEntity = {
  id: string;
  name: string;
};

export type UserNotificationSettingsMachine = UserNotificationsEntity;

export type UserNotificationSettingsProject = UserNotificationsEntity & {
  machines: UserNotificationSettingsMachine[];
};

export type UserNotificationSettingsCompany = UserNotificationsEntity & {
  projects: UserNotificationSettingsProject[];
};

export type UserNotificationSettingsCorporation = UserNotificationsEntity & {
  companies: UserNotificationSettingsCompany[];
};

/**
 * * The tree node class.
 */
export class TreeNode<T = any> {
  id: string;
  name: string;
  value: T;
  parent?: TreeNode<T>;
  children: TreeNode<T>[];

  constructor(id: string, name: string, value: T) {
    this.id = id;
    this.name = name;
    this.value = value;
    this.children = [];
  }

  /**
   * Changes the node and its children nodes value
   * @param value the new value.
   */
  changeValue = (value: T): void => {
    this.value = value;
    this.children?.forEach((node) => node.changeValue(value));
  };

  /**
   * Adds a tree node.
   * @param node The tree node to add.
   */
  addNode = (node: TreeNode<T>): void => {
    node.parent = this;
    this.children.push(node);
  };

  /**
   * Tries to change a child value.
   * @param childId The child Id.
   * @param value The value to change.
   */
  tryChangeChildValue = (childId: string, value: T): void => {
    // If it is a root node
    if (this.id === childId) {
      this.changeValue(value);
      return;
    }

    // If it is a leaf node.
    if (this.children?.length === 0) {
      return;
    }

    // Searches for the child index..
    let index = this.children.findIndex((c) => c.id === childId);
    if (index === -1) {
      this.children.forEach((child) => {
        child.tryChangeChildValue(childId, value);
      });
      return;
    }

    // If found, changes the value.
    this.children[index].changeValue(value);
  };

  /**
   * Gets the root node.
   * @returns The root node. If the node is root node, then it return itself.
   */
  getRootNode = (): TreeNode<T> => {
    if (!this.parent) {
      return { ...this };
    }

    let result: TreeNode<T> = { ...this };
    do {
      result = result.parent;
    } while (result.parent);

    return result;
  };

  /**
   * Gets the node siblings count.
   * @returns The siblings count. If the node is root node, returns -1.
   */
  getSiblingsCount = (): number => {
    if (!this.parent) {
      return -1;
    }

    return this.parent.children.length - 1;
  };

  /**
   * Gets a value determining whether all node's siblings contain a determined value.
   * @param value The value to check
   * @returns True if all sibling have the same value. Otherwise false. If the node is root node, returns false.
   */
  allSiblingsHaveValue = (value: T): boolean => {
    if (!this.parent) {
      return false;
    }

    let siblingsWithDifferentValue: TreeNode<T>[] = this.parent.children.filter(
      (c) => c.id !== this.id && c.value !== value
    );
    return siblingsWithDifferentValue.length === 0;
  };
}

/**
 * * The selectable entities tree class.
 */
export class EntitiesTree {
  corporations: Map<string, TreeNode<boolean>>;

  constructor() {
    this.corporations = new Map<string, TreeNode<boolean>>();
  }

  /**
   * Gets all companies.
   * @returns The companies map of type (id, treeNode)
   */
  getCompanies = (): Map<string, TreeNode<boolean>> => {
    let result = new Map<string, TreeNode<boolean>>();
    Array.from(this.corporations.values()).forEach((corporation) => {
      for (let company of corporation.children) {
        result.set(company.id, company);
      }
    });

    return result;
  };

  /**
   * Gets all projects.
   * @returns The projects map of type (id, treeNode)
   */
  getProjects = (): Map<string, TreeNode<boolean>> => {
    let result = new Map<string, TreeNode<boolean>>();
    Array.from(this.getCompanies().values()).forEach((company) => {
      for (let project of company.children) {
        result.set(project.id, project);
      }
    });

    return result;
  };

  /**
   * Gets all machines.
   * @returns The machines map of type (id, treeNode)
   */
  getMachines = (): Map<string, TreeNode<boolean>> => {
    let result = new Map<string, TreeNode<boolean>>();
    Array.from(this.getProjects().values()).forEach((project) => {
      for (let machine of project.children) {
        result.set(machine.id, machine);
      }
    });

    return result;
  };
}
