import { axiosInstance } from "../..";
import {
  ProjectAdd,
  ProjectDetails,
  ProjectToList,
  ProjectUpdate,
  ResponseProjectDetails,
} from "./models";

export async function listProjects(): Promise<ResponseProjectDetails[]> {
  const response = await axiosInstance.get<ResponseProjectDetails[]>(
    "/meta/read/v1/projects "
  );
  return response.data;
}

export async function deleteProject(
  project: ProjectToList
): Promise<ProjectDetails | any> {
  const urlRequest = "/meta/write/v1/projects/" + project.id;
  const response = await axiosInstance.delete<ProjectDetails | any>(urlRequest);
  if (response.data) return response.data;
  else return response;
}

export async function editProject(
  project: ProjectUpdate
): Promise<ProjectDetails | any> {
  const urlRequest = "/meta/write/v1/projects/" + project.id;
  const response = await axiosInstance.put<ProjectDetails | any>(
    urlRequest,
    project
  );
  if (response.data) return response.data;
  else return response;
}

export async function addProject(
  project: ProjectAdd
): Promise<ProjectDetails | any> {
  const urlRequest = "/meta/write/v1/projects";

  const response = await axiosInstance.post<ProjectDetails | any>(
    urlRequest,
    project
  );

  if (response.data) return response.data;
  else return response;
}
