import { useMemo, useState } from "react";
import {
  FormItemProps,
  FormItemType,
  notification,
  renderFormItems,
  useZodForm,
} from "web-analysis-lib";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import {
  PrimaryButton,
  Spinner,
  SpinnerSize,
  StackItem,
} from "@fluentui/react";
import { FormGroupProps } from "web-analysis-lib/dist/ui-kit/Form";
import SectionContainer from "../SectionContainer";
import { axiosInstance } from "../../../..";

import SectionInfo from "../SectionInfo";
import { useAppDispatch, useAppSelector } from "../../../../hooks";

import { settingsUpdateUsersRBAC } from "../../api";
import { selectUserAccountMemberID } from "../../../UserAccount/selectors";
import { selectUserSettings } from "../../selectors";
import { listAsyncSettingsUsersRBAC } from "../../reducer";



export type ThreadAddProps = {
  machineId: string;
  text: string;
};

export const useAccountUpdate = (data) => {
  return axiosInstance.post("meta/machinecv/v1/comments", data);
};

const getSchema = () =>
  z.object({
    worldMap: z.boolean(),
    dashboards: z.boolean(),
    machines: z.boolean(),
    bookmarks: z.boolean(),
    tasks: z.boolean(),
  });

const groupPropsStyles = {
  labelProps: {
    style: {
      fontWeight: 600,
    },
  },
  stackProps: {
    style: {
      flexDirection: "column",
      marginBottom: 20,
    },
  },
} as FormGroupProps;

const formStyles = {
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  padding: 16,
  marginBottom: 65,
  overflow: 'auto'
} as const;

const formItems: FormItemProps[] = [
  {
    name: "worldMap",
    type: FormItemType.Toggle,
    onText: "World Map",
    offText: "World Map",
    groupProps: {
      ...groupPropsStyles,
    },
  },
  {
    name: "dashboards",
    type: FormItemType.Toggle,
    onText: "Dashboards",
    offText: "Dashboards",
    groupProps: {
      ...groupPropsStyles,
    },
  },
  {
    name: "machines",
    type: FormItemType.Toggle,
    onText: "Machines",
    offText: "Machines",
    groupProps: {
      ...groupPropsStyles,
    },
  },
  {
    name: "bookmarks",
    type: FormItemType.Toggle,
    onText: "Bookmarks",
    offText: "Bookmarks",
    groupProps: {
      ...groupPropsStyles,
    },
  },
  {
    name: "tasks",
    type: FormItemType.Toggle,
    onText: "Tasks",
    offText: "Tasks",
    groupProps: {
      ...groupPropsStyles,
    },
  },
];

const Dashboard = () => {
  const schema = useMemo(() => getSchema(), []);
  const [isLoading, setIsLoading] = useState(false);
  
  const settings = useAppSelector(selectUserSettings);
  const userId = useAppSelector(selectUserAccountMemberID);
  const dispatch = useAppDispatch()

  
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useZodForm({
    mode: "onChange",
    schema,
    defaultValues: settings?.dashboardSettings,
  });

  const onSubmit = handleSubmit(async (data: object) => {
    if (!data) return;

    const payload = {
      ...settings,
      dashboardSettings: { ...data }
    }

    setIsLoading(true);

    await settingsUpdateUsersRBAC(userId ,payload)
      .then((response) => {
        if(response){
          notification.success("Settings updated successfully!");
        }
      })
      .catch(() => {
        notification.warning(
          "Something went wrong, please refresh the page. And try it again."
        );
      })
      .finally(() => {
        dispatch(listAsyncSettingsUsersRBAC(userId))
        setIsLoading(false)
      });
  });

  return (
    <SectionContainer headerTitle="Dashboard" >
      <SectionInfo
        title="Customise sections"
        description="Customise what you see on the dashboard section by toggling areas On or Off."
        style={{ padding: '8px 16px 10px 16px' }}
      />

      <form onSubmit={onSubmit} style={{ ...formStyles }}>
        <div className="form-items-container">
          {renderFormItems(formItems, {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          }).map((element) => (
            <StackItem key={element.key}>{element}</StackItem>
          ))}
        </div>

        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            padding: 16,
            background: "white",
            width: "100%",
            borderTop: "1px solid #F3F2F1",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <PrimaryButton
            type="submit"
            text="Update"
            disabled={!isValid || isLoading}
            style={{
              marginTop: "auto",
              alignSelf: "flex-start",
            }}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
        </div>
      </form>
    </SectionContainer>
  );
};

export default Dashboard;
