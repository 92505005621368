import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../schema/status";
import { RootState } from "../../store";
import { ResponseProjectDetails } from "../Projects/models";
import { list } from "./api";
import { WirelessGateway, WirelessGatewayResponse } from "./models";

export const listAsyncGateways = createAsyncThunk(
  "gateways/list",
  async (projs: ResponseProjectDetails[]) => {
    const response: WirelessGateway[] = await list();

    if (response.length > 0) {
      const responseVar: WirelessGatewayResponse[] = response.map((gate) => {
        const projSelected = projs.find((pro) => pro.id === gate.projectId);
        return {
          ...gate,
          projectId: projSelected?.id,
          project: projSelected?.name,
          company: projSelected?.company.name,
          corporation: projSelected?.corporation.name,
        };
      });
      return responseVar.sort((a, b) =>
        a.serialNumber > b.serialNumber ? 1 : -1
      );
    } else {
      return [];
    }
  }
);

export interface WirelessGatewaysState {
  gateways: WirelessGatewayResponse[];
  status: Status;
  error: string;
}

const initialState: WirelessGatewaysState = {
  gateways: [],
  status: Status.void,
  error: "",
};

export const wirelessGatewaysSlice = createSlice({
  name: "wirelessGateways",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncGateways.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncGateways.fulfilled, (state, action) => {
        state.status = Status.idle;
        state.error = "";
        state.gateways = action.payload!;
      })
      .addCase(listAsyncGateways.rejected, (state) => {
        state.status = Status.error;
        state.error = "Error loading gateways. Please try again later.";
      });
  },
});

export const selectWirelessGateways = (state: RootState) =>
  state.wirelessGateway.gateways;

export const selectWirelessGatewaysStatus = (state: RootState) =>
  state.wirelessGateway.status;

export const selectWirelessGateway = (id: string) => (state: RootState) => {
  if (
    state.wirelessGateway &&
    state.wirelessGateway.gateways &&
    state.wirelessGateway.gateways.length > 0
  ) {
    return state.wirelessGateway.gateways.find(
      (c: WirelessGatewayResponse) => c.id === id
    );
  }

  return undefined;
};

export const selectGatewaysProProjectOptions =
  (idProject: string) => (state: RootState) => {
    if (
      state.wirelessGateway &&
      state.wirelessGateway.gateways &&
      state.wirelessGateway.gateways.length > 0
    ) {
      return state.wirelessGateway.gateways
        .filter((c: WirelessGatewayResponse) => c.projectId === idProject)
        .map((tre) => ({
          key: tre.id!,
          text: tre.serialNumber!,
        }));
    }

    return undefined;
  };

export default wirelessGatewaysSlice.reducer;
