import { RawData } from "web-analysis-lib";
import { topMenuHeight } from "../../schema/Constants";
import { axiosInstance } from "../..";

export const RawViewStyled: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <>
      <RawData marginTop={topMenuHeight} axiosInstance={axiosInstance} />
    </>
  );
};
