import { axiosInstance } from "../..";
import {
  EntityItemWithAccountRoles,
  RequestRolesAdd,
  RequestRolesAddMany,
} from "./models";

export async function listPermissions(): Promise<EntityItemWithAccountRoles[]> {
  const response = await axiosInstance.get<EntityItemWithAccountRoles[]>(
    "/rbac/v1/rbac"
  );
  return response.data;
}

export async function deletePermission(id: string): Promise<any> {
  const urlRequest = "/rbac/v1/rbac/" + id;
  const response = await axiosInstance.delete<any>(urlRequest);
  if (response.data) return response.data;
  else return response;
}

export async function addPermissionApi(
  permission: RequestRolesAdd
): Promise<any> {
  const config = {
    roleId: permission.roleId,
    scopeLevelId: permission.scopeLevelId,
    scopeResourceId: permission.scopeResourceId,
    memberAndOrServiceAccountIds: permission.memberAndOrServiceAccountIds,
    expiresAtUtc: permission.expiresAtUtc,
  };
  const urlRequest = "/rbac/v1/rbac";

  const response = await axiosInstance.post<any>(urlRequest, config);
  if (response.data) return response.data;
  else return response;
}

export async function addManyPermissionsApi(
  config: RequestRolesAddMany
): Promise<any> {
  const urlRequest = "/rbac/v1/rbac/addMany";
  const response = await axiosInstance.post<any>(urlRequest, config);
  if (response.data) return response.data;
  else return response;
}

export async function removePermissionsOfMember(id: string): Promise<any> {
  const urlRequest = "/rbac/v1/rbac/member/" + id;
  const response = await axiosInstance.delete<any>(urlRequest);
  if (response.data) return response.data;
  else return response;
}
