/* eslint-disable react-hooks/exhaustive-deps */
import { ComboBox, DialogType, IComboBoxOption } from "@fluentui/react";
import FormDialog from "../Generic/FormDialog";
import {
  DialogSize,
  FormItemProps,
  FormItemType,
  notification,
  renderFormItems,
  useZodForm,
} from "web-analysis-lib";
import { z } from "zod";
import {
  AddEditDashboardFormData,
  DashBoardToList,
  PowerBIEmbedItemParentType,
} from "./models";
import { useEffect, useState } from "react";
import { areObjectsEqual } from "../../schema/Utils";
import type { FieldError } from "react-hook-form";
import FormItemRow from "../Generic/FormItemRow";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectCompanies } from "../Companies/reducer";
import { selectCorporations } from "../Corporations/reducer";
import { selectMachines } from "../Machines/reducer";
import { selectProjects } from "../Projects/reducer";
import ControlledComboBox from "../Generic/ControlledComboBox";
import { DashboardMetaDataAPI } from "./api";
import { listAsyncDashB } from "./reducer";

type BasicDialogProps = {
  onClose: () => void;
};

type EditDashboardDialogProps = BasicDialogProps & {
  entry: DashBoardToList;
};

type AddEditDashboardDialogProps = {
  title: string;
  entry?: DashBoardToList;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (formData: AddEditDashboardFormData) => void;
};

const uuidRegex = new RegExp(
  "[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}"
);

const formSchema = z.object({
  reportId: z
    .string()
    .min(1, "This field is required.")
    .regex(uuidRegex, "The value must be an UUID."),
  groupId: z
    .string()
    .min(1, "This field is required.")
    .regex(uuidRegex, "The value must be an UUID."),
});

const formItemProps: FormItemProps[] = [
  {
    name: "reportId",
    type: FormItemType.TextField,
    groupProps: { label: "Report ID *" },
    disabled: false,
  },
  {
    name: "groupId",
    type: FormItemType.TextField,
    groupProps: { label: "Group ID *" },
    disabled: false,
  },
];

/**
 * Gets the Dashboard add edit dialog component.
 * @param title The form dialog title.
 * @param entry The dashboard list entry.
 * @param isLoading Value indicating whether the form must be in loading state.
 * @param onSubmit Method called when the submit button is clicked.
 * @param onClose Method called when the close button or cancel button are clicked.
 * @returns The Dashboard add edit dialog component.
 */
const AddEditDashboardDialog = ({
  title,
  entry,
  isLoading,
  onSubmit,
  onClose,
}: AddEditDashboardDialogProps) => {
  const [isFormChanged, setIsFormChanged] = useState<boolean>(!entry);
  const [parentId, setParentId] = useState<string>(entry?.parentId || "");
  const [parentType, setParentType] = useState<string>(
    entry?.parentType || "Corporation"
  );
  const [parentOptions, setParentOptions] = useState<IComboBoxOption[]>([]);
  const corporations = useAppSelector(selectCorporations);
  const companies = useAppSelector(selectCompanies);
  const projects = useAppSelector(selectProjects);
  const machines = useAppSelector(selectMachines);
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema: formSchema,
    ...{
      defaultValues: {
        reportId: entry?.id || "",
        groupId: entry?.groupId || "",
      },
    },
  });

  // Checks whether the form data has changed.
  useEffect(() => {
    if (!entry) {
      return;
    }

    let formDataHasChanged = !areObjectsEqual(
      control._formValues,
      control._defaultValues
    );
    setIsFormChanged(formDataHasChanged);
  }, [watch()]);

  // Sets the parent options
  useEffect(() => {
    if (!parentType) {
      return;
    }

    // Changes the parent combobox options.
    let options: IComboBoxOption[] = [];
    switch (
      PowerBIEmbedItemParentType[
        parentType as keyof typeof PowerBIEmbedItemParentType
      ]
    ) {
      case PowerBIEmbedItemParentType.Corporation:
        options = corporations.map((item) => {
          return { key: item.id, text: item.name };
        });
        break;
      case PowerBIEmbedItemParentType.Company:
        options = companies.map((item) => {
          return { key: item.id, text: item.name };
        });
        break;
      case PowerBIEmbedItemParentType.Project:
        options = projects.map((item) => {
          return { key: item.id, text: item.name };
        });
        break;
      case PowerBIEmbedItemParentType.Machine:
        options = machines.map((item) => {
          return { key: item.id, text: item.name };
        });
        break;
    }

    setParentOptions(options);
    let selectedOptionIndex = options.findIndex((o) => o.key === parentId);
    if (selectedOptionIndex === -1) {
      setParentId("");
    }
  }, [parentType]);

  // Handlers
  const onSubmitHandler = (formData: any) => {
    if (!formData) {
      return;
    }

    let data: AddEditDashboardFormData = {
      groupId: formData.groupId,
      id: formData.reportId,
      parentType: parentType,
      parentId: parentId,
    };
    onSubmit?.(data);
  };

  return (
    <FormDialog
      title={title}
      isLoading={isLoading}
      isValid={isValid && isFormChanged && parentType !== "" && parentId !== ""}
      type={DialogType.close}
      size={DialogSize.M}
      onSubmit={handleSubmit(onSubmitHandler)}
      onClose={onClose}
    >
      <FormItemRow label="Parent type *">
        <ComboBox
          options={Object.keys(PowerBIEmbedItemParentType).map((key) => {
            return { key: key, text: PowerBIEmbedItemParentType[key] };
          })}
          selectedKey={parentType}
          onChange={(_, option) => setParentType(option.key.toString())}
        />
      </FormItemRow>
      <FormItemRow label="Parent *">
        <ControlledComboBox
          options={parentOptions}
          selectedKey={parentId}
          disabled={false}
          onKeySelected={(key: string) => setParentId(key)}
        />
      </FormItemRow>
      {renderFormItems(formItemProps, {
        control,
        errors: errors as { [schemaProp: string]: FieldError },
      })}
    </FormDialog>
  );
};

/**
 * Gets the Add dashboard dialog component.
 * @param onClose Method called when the close button or cancel button are clicked.
 * @returns The Add dashboard dialog component.
 */
export const DashboardAddDialog = ({ onClose }: BasicDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  // Handlers
  const onSubmit = (formData: AddEditDashboardFormData) => {
    setIsLoading(true);
    DashboardMetaDataAPI.createOrUpdateDashboard(formData).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error("Failure: Creating PowerBI Dashboard.");
        return;
      }

      dispatch(listAsyncDashB());
      notification.success("Success: Creating PowerBI Dashboard.");
      onClose?.();
    });
  };

  return (
    <AddEditDashboardDialog
      title={"Add New Dashboard"}
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

/**
 * Gets the Edit dashboard dialog component.
 * @param entry the Dashboard table entry
 * @param onClose Method called when the close button or cancel button are clicked.
 * @returns The Edit dashboard dialog component.
 */
export const DashboardEditDialog = ({
  entry,
  onClose,
}: EditDashboardDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  // Handlers
  const onSubmit = (formData: AddEditDashboardFormData) => {
    setIsLoading(true);
    DashboardMetaDataAPI.createOrUpdateDashboard(formData).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error("Failure: Creating PowerBI Dashboard.");
        return;
      }

      dispatch(listAsyncDashB());
      notification.success("Success: Creating PowerBI Dashboard.");
      onClose?.();
    });
  };

  return (
    <AddEditDashboardDialog
      title={"Edit Dashboard"}
      entry={entry}
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
