import {
  ConnectionEntryDetailed,
  ConnectionTableItem,
  MetadataCompany,
  MetadataProject,
  MetadataVpnPC,
} from "./models";

export type BasicDialogProps = {
  onClose: (listChanged?: boolean) => void;
};

export type MetadataProps = {
  companies: MetadataCompany[];
  projects: MetadataProject[];
};

export enum ConnectionsActionsList {
  None,
  EmailDetails,
  ConnectionDetails,
  Edit,
  Delete,
  HistoricalChanges,
  Add,
  VpnPCsDetails,
}

export type ConnectionActionDialogBaseProps<
  T = ConnectionEntryDetailed | ConnectionTableItem
> = BasicDialogProps & {
  item?: T;
  action: ConnectionsActionsList;
};

export type ConnectionItemDialogProps =
  ConnectionActionDialogBaseProps<ConnectionEntryDetailed> & {
    projects: MetadataProject[];
  };

export type AddEditDialogProps<T = ConnectionEntryDetailed | MetadataVpnPC> = {
  item?: T;
  isLoading: boolean;
  onSubmit: (data: T) => void;
  onClose: () => void;
};
