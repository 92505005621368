// DialogSelector.tsx
import { FC, useContext, useEffect, useMemo } from "react";
import { EditDialog } from "./EditDialog";
import { AddDialog } from "./AddDialog";
import { useAppSelector } from "../../../../hooks";
import { selectWirelessGateways } from "../../../Gateways/reducer";
import { selectProjects } from "../../../Projects/reducer";
import { SupportContext } from "../SupportContext";
import { Project } from "../../../Projects/models";
import {
  RequestWirelessGatewayAddAux,
  RequestWirelessGatewayUpdateAux,
  SubmissionSupport,
} from "../../models";

function findMatchUniqueId(submissionSupport: SubmissionSupport):
  | {
      gateway: RequestWirelessGatewayAddAux | RequestWirelessGatewayUpdateAux;
      type: string;
    }
  | undefined {
  if (!submissionSupport) {
    return undefined;
  }
  const { gatewaysSupportToAdd, gatewaysSupportToUpdate, matchUniqueId } =
    submissionSupport;

  const foundInAdd = gatewaysSupportToAdd.find(
    (gateway) => gateway.matchUniqueId === matchUniqueId
  );
  if (foundInAdd) {
    return { gateway: foundInAdd, type: "Add" };
  }

  const foundInUpdate = gatewaysSupportToUpdate.find(
    (gateway) => gateway.matchUniqueId === matchUniqueId
  );
  if (foundInUpdate) {
    return { gateway: foundInUpdate, type: "Edit" };
  }

  return undefined;
}

const DialogSelector: FC = ({}) => {
  const gatsList = useAppSelector(selectWirelessGateways);
  const projs = useAppSelector(selectProjects);
  const { support } = useContext(SupportContext);

  const { gateway, type } = findMatchUniqueId(support);

  return type === "Edit" ? (
    <EditDialog
      key={gateway.serialNumber}
      serialNumber={gateway.serialNumber}
      options={projs.map((pro: Project) => ({
        key: pro.id,
        text: pro.name,
      }))}
      serialNumbers={gatsList.map((gate) => gate.serialNumber)}
    />
  ) : type === "Add" ? (
    <AddDialog serialNumbers={gatsList.map((gate) => gate.serialNumber)} />
  ) : (
    <></>
  );
};

export default DialogSelector;
