import { axiosInstance } from "../..";

export interface UserExtendedProperties {
  memberId?: string;
  displayName?: string;
  email?: string;
  id?: string;
  lastName?: string;
  firstName?: string;
  jobTitle?: string;
  companyName?: string;
  department?: string;
  streetAddress?: string;
  city?: string;
  stateOrProvince?: string;
  zipOrPostalCode?: string;
  countryOrRegion?: string;
  mobilePhone?: string;
  initialPassword?: string;
}

export async function usersWithoutPermissions(): Promise<
  UserExtendedProperties[]
> {
  const response = await axiosInstance.get<UserExtendedProperties[]>(
    "/rbac/v1/users/withoutPermissions"
  );
  return response.data;
}
