import { useContext, useState } from "react";
import { MetadataVpnPC } from "../../Schema/models";
import { notification } from "web-analysis-lib";
import { VpnPCsAPI } from "../../Schema/api";
import { AxiosContext } from "../../VpnConnectionsManager/VpnConnectionsManager";
import React from "react";
import DeleteDialog from "../../../Generic/DeleteDialog";

type DeleteVpnPcDialogProps = {
  item: MetadataVpnPC;
  onClose: (listChanged?: boolean) => void;
};

/**
 * Gets the Delete VPN PC dialog component.
 * @param item The VPN PC Item to delete.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The Delete VPN PC dialog component.
 */
const DeleteVpnPcDialog = ({ item, onClose }: DeleteVpnPcDialogProps) => {
  const axiosInstance = useContext(AxiosContext);
  const [isLoading, setIsLoading] = useState(false);

  // Method called when the Delete button is clicked.
  const onDeleteHandler = (id: string) => {
    if (!axiosInstance) {
      return;
    }

    // Sends the request.
    setIsLoading(true);
    VpnPCsAPI.delete(axiosInstance, id).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          `Failure deleting a VPN PC entry: ${response.statusText}.`
        );
        return;
      }

      notification.success("Success deleting VPN PC entry.");
      onClose?.(true);
    });
  };

  return (
    <DeleteDialog
      id={item.id!}
      identifier={"VPN PC entry"}
      propertyName={"name"}
      referenceString={item.name}
      isLoading={isLoading}
      onDelete={onDeleteHandler}
      onClose={onClose}
    />
  );
};

export default DeleteVpnPcDialog;
