/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Persona, PersonaSize, Text } from "@fluentui/react";
import {
  BaseCommandBar,
  CommandBarItemProps,
  CommandBarItemType,
  computeCommandBarItems,
} from "web-analysis-lib";


import { AcceptedFile } from "web-analysis-lib/dist/ui-kit/Uploader/Uploader";

import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { selectUserAccountInformationProperties, selectUserAccountProfilePicture } from "../../../UserAccount/selectors";
import { updateProfilePciture } from "../../../UserAccount/reducer";
import { extract4Initials, getObjectUrlFromBase64, mapInitialsToNumber } from "../../../../schema/Utils";

/**
 * Gets the command bar item props
 * @param onUpload
 * @param onDelete
 * @returns
 */
const getCommandBarItemProps = (
  onUpload: (files: AcceptedFile[]) => void,
  onDelete: () => void
): CommandBarItemProps[] => {
  let result: CommandBarItemProps[] = [
    {
      key: "upload-photo",
      text: "Upload image",
      ariaLabel: "Upload photo",
      type: CommandBarItemType.Upload,
      onRenderProps: {
        maxSize: 100000000,
        accept: "image/*",
        multiple: false,
        onChange: onUpload,
      },
      iconProps: {
        iconName: "Upload",
        style: { color: "var(--dalog-blue)" },
      },
    },
    {
      key: "delete-photo",
      text: "Remove image",
      ariaLabel: "Delete photo",
      type: CommandBarItemType.Button,
      iconProps: {
        iconName: "Delete",
        style: { color: "var(--dalog-blue)" },
      },
      onClick: () => onDelete(),
    },
  ];

  return result;
};

/**
 * Gets the update user photo dialog component.
 * @param onClose Method called to close this dialog.
 * @returns The update user photo dialog component.
 */
const UpdateAvatar = () => {
  const profilePicture = useAppSelector(selectUserAccountProfilePicture);
  const accountDetails = useAppSelector(selectUserAccountInformationProperties)

  const dispatch = useAppDispatch()

  const onDeleteClick = () => {
    dispatch(updateProfilePciture(""));
  };

  const onUploadFileClick = (files: AcceptedFile[]) => {
    const file = files[0]?.file;
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target?.result;
      dispatch(updateProfilePciture(btoa(bstr as string)));
    };
    reader.readAsBinaryString(file);
  };

  return (
      <Stack style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
      }}>
        <Text block>
          <Text block className="section-container-header-title">Profile Image</Text>
          <Text block style={{
            color: '#605E5C',
            marginTop: '8px',
            lineHeight: '20px',
            maxWidth: '600px'
          }}>
            This is your account image. Note that if no image is selected, the initials of your name will be displayed instead.
          </Text>
        </Text>
        

        <BaseCommandBar
          className="update-avatar-command-bar"
          items={computeCommandBarItems(
            getCommandBarItemProps(onUploadFileClick, onDeleteClick)
          )}
        />

        <Persona
          size={PersonaSize.size120}
          imageInitials={extract4Initials(accountDetails?.displayName)}
          initialsColor={mapInitialsToNumber(
            extract4Initials(accountDetails?.displayName)
          )}
          imageUrl={
            profilePicture
              ? getObjectUrlFromBase64(profilePicture)
              : undefined
          }
        />
      </Stack>
  );
};

export default UpdateAvatar;
