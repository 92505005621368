import { axiosInstance } from "../../../..";
import { FilesExtensions } from "../../../../schema/status";
import { AlertLevel, CreateAlertLevel } from "./models";

export async function addAlertLevel(
  machineId: string,
  alert: CreateAlertLevel
): Promise<AlertLevel | any> {
  const urlRequest = "/meta/write/v1/machines/" + machineId + "/alertLevels";

  const response = await axiosInstance.post<AlertLevel | any>(
    urlRequest,
    alert
  );
  if (response.data) return response.data;
  else return response;
}

export async function editAlertLevel(
  machineId: string,
  id: string,
  alert: AlertLevel
): Promise<AlertLevel | any> {
  const urlRequest =
    "/meta/write/v1/machines/" + machineId + "/alertLevels/" + id;

  const response = await axiosInstance.put<AlertLevel | any>(urlRequest, alert);
  if (response.data) return response.data;
  else return response;
}

export async function deleteAlertLevel(
  machineId: string,
  id: string
): Promise<AlertLevel | any> {
  const urlRequest =
    "/meta/write/v1/machines/" + machineId + "/alertLevels/" + id;

  const response = await axiosInstance.delete<AlertLevel | any>(urlRequest);
  if (response.data) return response.data;
  else return response;
}

export async function alertLevelsImport(
  machineId: string,
  type: FilesExtensions,
  file_in: any
): Promise<any> {
  const formData = new FormData();
  formData.append("file", file_in!);
  const urlRequest =
    "/meta/write/v1/machines/" + machineId + "/alertLevels/import/" + type;
  const response = await axiosInstance.post<any>(urlRequest, formData);
  return response;
}

export async function alertLevelsList(
  machineId: string
): Promise<any | AlertLevel[]> {
  const response = await axiosInstance.get<any>(
    "/meta/read/internal/v1/machines/" + machineId + "/alertLevels/"
  );
  if (response.data) return response.data;
  else return response;
}
