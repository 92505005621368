// GatewayTextField.tsx
import { TextField } from "@fluentui/react";
import { FC, useContext } from "react";
import { GatewaySubmission } from "../models";
import { SecondPanelContext } from "../SubmissionsDetails";
import { SupportContext } from "../SupportContext";
import DialogSelector from "./DialogSelector";

interface GatewaySupportInputProps {
  context: SecondPanelContext;
}

const GatewaySupportInput: FC<GatewaySupportInputProps> = ({ context }) => {
  const { support } = useContext(SupportContext);

  return (
    <>
      <TextField
        defaultValue={context.initialValues.gat?.serialNumber}
        disabled={context.context !== "Gateway"}
        label="Gateway Serial"
        onChange={(ev, newVal) => {
          const gatNew: GatewaySubmission = {
            serialNumber: newVal.trim(),
            uniqueId: context.initialValues.gat.uniqueId,
            id: context.initialValues.gat.id,
          };
          context.updateFunction(gatNew, context.initialValues.gat.uniqueId);
        }}
      />

      <>{support && <DialogSelector />}</>
    </>
  );
};

export default GatewaySupportInput;
