import * as React from "react";
import { TopMenu } from "../TopMenu/TopMenu";
import { Content } from "./Content";
import { createContext, useState } from "react";
import { LeftMenuAlt } from "../LeftMenuAlt/LeftMenuAlt";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

export interface ToContextIsExpandedLeftMenu {
  value: boolean;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
}

export const leftMenuIsExpandContext = createContext<
  ToContextIsExpandedLeftMenu | undefined
>(undefined);

export const Layout: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const [leftMenu, setLeftMenu] = useState(false);

  return (
    <div>
      <leftMenuIsExpandContext.Provider
        value={{ value: leftMenu, setValue: setLeftMenu }}
      >
        <BrowserRouter>
          <TopMenu />
          <LeftMenuAlt>
            <Content />
          </LeftMenuAlt>
        </BrowserRouter>
      </leftMenuIsExpandContext.Provider>
    </div>
  );
};
