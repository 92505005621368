/* eslint-disable react-hooks/exhaustive-deps */
import { ComboBox, Label, Stack } from "@fluentui/react";
import CheckboxesListPanel from "./CheckboxNodesList";
import { EntitiesTree, TreeNode, UserNotificationSettingsType } from "./models";

type UserNotificationSettingsTabProps = {
  typeKey: string;
  tree: EntitiesTree;
  onKeyChanged: (key: string) => void;
  onTreeNodeChange: (node: TreeNode<boolean>) => void;
};

/**
 * Gets the user notification settings tab component.
 * @param typeKey The user notification status key.
 * @param tree The entities tree.
 * @param onKeyChanged Method called when the user notification status key is changed.
 * @param onTreeNodeChange Method called when a tree node status is changed.
 * @returns The user notification settings tab.
 */
const UserNotificationDialogTab = ({
  typeKey,
  tree,
  onKeyChanged,
  onTreeNodeChange,
}: UserNotificationSettingsTabProps) => {
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack.Item align="start">
        <Label>Select state:</Label>
        <ComboBox
          options={Object.keys(UserNotificationSettingsType)
            .filter(
              (key) =>
                UserNotificationSettingsType[key] !==
                UserNotificationSettingsType.Unknown
            )
            .map((type) => {
              return { key: type, text: UserNotificationSettingsType[type] };
            })}
          selectedKey={typeKey}
          onChange={(_, option) => onKeyChanged(option.key.toString())}
          style={{ minWidth: "25em" }}
        />
      </Stack.Item>
      <Stack.Item>
        {UserNotificationSettingsType[typeKey] ===
          UserNotificationSettingsType.Selected && (
          <Stack horizontal tokens={{ childrenGap: 0 }}>
            <Stack.Item>
              <CheckboxesListPanel
                title="Corporations"
                nodes={tree?.corporations}
                onCheckedChange={onTreeNodeChange}
              />
            </Stack.Item>
            <Stack.Item>
              <CheckboxesListPanel
                title="Companies"
                nodes={tree?.getCompanies()}
                onCheckedChange={onTreeNodeChange}
              />
            </Stack.Item>
            <Stack.Item>
              <CheckboxesListPanel
                title="Projects"
                nodes={tree?.getProjects()}
                onCheckedChange={onTreeNodeChange}
              />
            </Stack.Item>
            <Stack.Item>
              <CheckboxesListPanel
                title="Machines"
                nodes={tree?.getMachines()}
                onCheckedChange={onTreeNodeChange}
              />
            </Stack.Item>
          </Stack>
        )}
      </Stack.Item>
    </Stack>
  );
};

export default UserNotificationDialogTab;
