import { IPivotItemProps, Icon } from "@fluentui/react";
import { CSSProperties } from "react";

/**
 * Gets the pivot with a customized icon.
 * @param link The pivot item props.
 * @param defaultRenderer The default renderer.
 * @returns The pivot component with the customized icon.
 */
const RenderPivotWithIcon = (
  link?: IPivotItemProps,
  defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null
): JSX.Element | null => {
  const iconStyle: CSSProperties = {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignContent: "center",
    columnGap: "0.5em",
  };

  if (!link || !defaultRenderer) {
    return null;
  }
  return (
    <span style={iconStyle}>
      <Icon iconName={link.itemIcon} style={{ color: "rgb(44, 82, 159)" }} />
      {defaultRenderer({ ...link, itemIcon: undefined })}
    </span>
  );
};

export default RenderPivotWithIcon;
