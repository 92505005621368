import {
  FocusTrapZone,
  Label,
  Popup,
  Spinner,
  SpinnerSize,
  Stack,
  mergeStyleSets,
} from "@fluentui/react";
import React from "react";

/**
 * Gets the loading Dialog component.
 * @returns The loading dialog component.
 */
const LoadingPanel = () => {
  const popupStyles = mergeStyleSets({
    root: {
      background: "rgba(255, 255, 255, 0.4)",
      bottom: "0",
      left: "0",
      position: "fixed",
      right: "0",
      top: "0",
    },
    content: {
      background: "white",
      padding: "1em",
      left: "50%",
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "2px",
      boxSizing: "border-box",
    },
  });

  return (
    <Popup className={popupStyles.root} role="loading panel" aria-modal="true">
      <FocusTrapZone className={`${popupStyles.content} box-shadow-panel`}>
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          tokens={{ childrenGap: 5, padding: 5 }}
          role="loading panel"
          style={{ height: "5em", marginTop: "auto", marginBottom: "auto" }}
        >
          <Stack.Item>
            <Spinner size={SpinnerSize.large} />
          </Stack.Item>
          <Stack.Item>
            <Label>Loading data...</Label>
          </Stack.Item>
        </Stack>
      </FocusTrapZone>
    </Popup>
  );
};

export default LoadingPanel;
