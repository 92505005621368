import {
  DetailsRow,
  GroupedList,
  IGroup,
  Selection,
  SelectionMode,
  SelectionZone,
  IColumn,
  SearchBox,
  Stack,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";
import {
  CustomItemsType,
  selectScopesOfRole,
} from "../Roles/ScopesOfRole/reducer";
import { ResponseRolesOfScopeListItem } from "../Roles/models";
import { ControlledItem } from "../Roles/AddRole";

type GroupsAndItemsState = {
  key?: string;
  items: CustomItemsType[];
  groups: IGroup[];
  selection: Selection;
  inLoading: boolean;
};

type GroupsAndItemsProps = {
  data: ResponseRolesOfScopeListItem[];
  filter: string;
};

const ComputeItemsAndGroups = ({ data, filter }: GroupsAndItemsProps) => {
  const groups: IGroup[] = [];
  const items: CustomItemsType[] = [];
  var acumm = 0;
  data.forEach((ele: ResponseRolesOfScopeListItem) => {
    const filteredValues = ele.values
      .slice()
      .sort((a, b) =>
        a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1
      )
      .filter((item) =>
        item.description
          ? item.description.toLocaleLowerCase().indexOf(filter.toLowerCase()) >
            -1
            ? true
            : false
          : true
      );
    const count = filteredValues.length;
    groups.push({
      key: ele.scope.id,
      name: ele.scope.type,
      startIndex: acumm,
      count: count,
      level: 0,
    });
    acumm += count;
    items.push(
      ...filteredValues.map((val) => {
        return {
          key: val.scopeResourceId ? val.scopeResourceId : ele.scope.id,
          name: val.description ? val.description : "Root",
          scopeId: ele.scope.id,
          scopeResourceId: val.scopeResourceId,
          scope: ele.scope.type,
        };
      })
    );
  });
  return { items, groups };
};
const columns: IColumn[] = [
  {
    key: "name",
    name: "Name",
    fieldName: "name",
    minWidth: 300,
  },
];

type ScopeSelectionsProps = {
  controlledItems: ControlledItem[];
  setControlledItems: React.Dispatch<React.SetStateAction<ControlledItem[]>>;
};

export const ScopeSelections = ({
  controlledItems,
  setControlledItems,
}: ScopeSelectionsProps) => {
  const scopesPerRole = useAppSelector(selectScopesOfRole);
  const [groupsAndItems, setGroupsAndItems] = useState<GroupsAndItemsState>({
    groups: [],
    items: [],
    inLoading: true,
    selection: undefined,
  });

  useEffect(() => {
    if (controlledItems !== undefined) {
      const { items, groups } = ComputeItemsAndGroups({
        data: scopesPerRole,
        filter: "",
      });
      const selection = new Selection({
        onSelectionChanged() {
          const keysSelected = selection.getSelection();
          const itemsAux = selection.getItems();
          const controlledItemsAux: ControlledItem[] = controlledItems.map(
            (ele) => {
              if (
                keysSelected.some((keyEle) => keyEle.key === ele.key.toString())
              )
                return { ...ele, check: true };
              else if (itemsAux.some((val) => val.key === ele.key.toString())) {
                return { ...ele, check: false };
              } else return ele;
            }
          );

          setControlledItems(controlledItemsAux);
        },
      });
      selection.setItems(items);
      setGroupsAndItems({
        items,
        groups,
        selection,
        inLoading: true,
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlledItems?.length]);

  useEffect(() => {
    if (controlledItems?.length > 0) {
      const filteredItems = controlledItems?.filter(
        (ele) => ele.check === true
      );
      for (const item of filteredItems) {
        groupsAndItems?.selection?.setKeySelected(
          item.key.toString(),
          true,
          false
        );
      }
    }

    return () => {};
  }, [controlledItems, groupsAndItems.selection]);

  const onChange = (
    event?: React.ChangeEvent<HTMLInputElement>,
    filter?: string
  ) => {
    const { items, groups } = ComputeItemsAndGroups({
      data: scopesPerRole,
      filter,
    });

    const selection = new Selection({
      onSelectionChanged() {
        const keysSelected = selection.getSelection();
        const itemsAux = selection.getItems();
        const controlledItemsAux: ControlledItem[] = controlledItems.map(
          (ele) => {
            if (
              keysSelected.some((keyEle) => keyEle.key === ele.key.toString())
            )
              return { ...ele, check: true };
            else if (itemsAux.some((val) => val.key === ele.key.toString())) {
              return { ...ele, check: false };
            } else return ele;
          }
        );

        setControlledItems(controlledItemsAux);
      },
    });
    selection.setItems(items);
    setGroupsAndItems({
      key: filter,
      items,
      groups,
      selection,
      inLoading: true,
    });
  };

  const onRenderCell = (
    nestingDepth?: number,
    item?: CustomItemsType,
    itemIndex?: number,
    group?: IGroup
  ): React.ReactNode => {
    return (
      item &&
      typeof itemIndex === "number" &&
      itemIndex > -1 && (
        <DetailsRow
          columns={columns}
          groupNestingDepth={nestingDepth}
          item={item}
          itemIndex={itemIndex!}
          selection={groupsAndItems.selection}
          selectionMode={SelectionMode.multiple}
          group={group}
        />
      )
    );
  };

  return (
    <Stack style={{ padding: "50px" }}>
      <SearchBox placeholder="Search" onChange={onChange} />
      {groupsAndItems.selection && (
        <SelectionZone
          selection={groupsAndItems.selection}
          selectionMode={SelectionMode.multiple}
        >
          <GroupedList
            key={groupsAndItems.key}
            items={groupsAndItems?.items}
            onRenderCell={onRenderCell}
            selection={groupsAndItems.selection}
            groups={groupsAndItems?.groups}
          />
        </SelectionZone>
      )}
    </Stack>
  );
};
