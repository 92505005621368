/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Checkbox, IStackTokens, Label } from "@fluentui/react";
import { CSSProperties } from "react";
import { Text } from "@fluentui/react/lib/Text";
import React from "react";
import { WithNoItemsPanelHOC } from "../Generic/HOCs";
import { TreeNode } from "./models";
import { compareTreeNodes } from "./utils";
import { boxShadowStyle } from "./globalStyles";

const containerStyle: CSSProperties = {
  margin: "0.5em",
  maxHeight: "22em",
  width: "22em",
  ...boxShadowStyle,
};

const listPanelStyle: CSSProperties = {
  overflow: "scroll",
  marginTop: "0.5em",
  ...boxShadowStyle,
};

type CheckboxesListPanel2Props = {
  title: string;
  nodes: Map<string, TreeNode<boolean>>;
  onCheckedChange: (node: TreeNode<boolean>) => void;
};

type CheckboxEntity = {
  key: string;
  name: string;
  value: boolean;
  isHeader: boolean;
};

const stackTokens: IStackTokens = { childrenGap: 5, padding: 10 };

/**
 * Creates the checkboxes entities map
 * @param nodes The tree nodes map.
 * @returns The checkboxes entities (node.id, CheckboxEntity).
 */
const createNodesMap = (
  nodes: Map<string, TreeNode<boolean>>
): Map<string, CheckboxEntity> => {
  let result = new Map<string, CheckboxEntity>();
  let groups: TreeNode<boolean>[] = [];
  for (let node of Array.from(nodes.values()).sort(compareTreeNodes)) {
    // Gets all parents from nodes with parent.
    if (node.parent) {
      !groups.includes(node.parent) && groups.push(node.parent);
    } else {
      // Adds checkboxes for stray nodes.
      result.set(node.id, {
        key: node.id,
        name: node.name,
        value: node.value,
        isHeader: false,
      });
    }
  }

  // Adds checkboxes for nodes with parent.
  for (let group of groups.sort(compareTreeNodes)) {
    // Adds the header.
    result.set(group.id, {
      key: group.id,
      name: group.name,
      value: group.value,
      isHeader: true,
    });

    // Adds the checkboxes.
    group.children.forEach((node) => {
      result.set(node.id, {
        key: node.id,
        name: node.name,
        value: node.value,
        isHeader: false,
      });
    });
  }

  return result;
};

/**
 * Gets a checkbox component.
 * @param entity The checkbox entity.
 * @param onCheckedChange Method called when the checkbox value is changed.
 * @returns The checkbox component, or a label component if the entity is a header.
 */
const GetCheckboxComponent = (
  entity: CheckboxEntity,
  onCheckedChange: (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => void
): JSX.Element => {
  return entity.isHeader ? (
    <Label key={entity.key}>{entity.name}</Label>
  ) : (
    <Checkbox
      key={entity.key}
      id={entity.key}
      label={entity.name}
      onChange={onCheckedChange}
      checked={entity.value}
    />
  );
};

/**
 * Gets the checkbox nodes list component.
 * @param title The panel title
 * @param nodes the entities nodes.
 * @param onCheckedChange Method called when a tree node checkbox state has been changed.
 * @returns The entities panel component.
 */
const CheckboxesListPanel = ({
  title,
  nodes,
  onCheckedChange,
}: CheckboxesListPanel2Props) => {
  // Handlers
  const onCheckedChangeHandler = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    let targetId: string = ev?.currentTarget?.id;
    if (targetId === null && checked === null) {
      return;
    }

    // Gets the node and sends it.
    let selectedNode = nodes.get(targetId);
    if (!selectedNode) {
      return;
    }

    selectedNode.value = checked;
    onCheckedChange?.(selectedNode);
  };

  return (
    <Stack tokens={stackTokens} style={containerStyle}>
      <Stack.Item>
        <Text variant="large">{title}</Text>
      </Stack.Item>
      <Stack.Item style={listPanelStyle}>
        {WithNoItemsPanelHOC(
          nodes.size,
          <Stack tokens={stackTokens}>
            {Array.from(createNodesMap(nodes)?.values())?.map((node) => (
              <Stack.Item key={node.key}>
                {GetCheckboxComponent(node, onCheckedChangeHandler)}
              </Stack.Item>
            ))}
          </Stack>
        )}
      </Stack.Item>
    </Stack>
  );
};

export default CheckboxesListPanel;
