import { MachineDrawing } from "../../models";
import { UrlDrawingsWithIndex } from "./IconAndDrawingsMachine";
import { downloadFile } from "../../../FIles/api";

export async function downloadSortedFiles(
  machineDrawings: MachineDrawing[]
): Promise<UrlDrawingsWithIndex[] | undefined> {
  let results;

  results = await Promise.all(
    machineDrawings.map(async (config) => {
      let urls: UrlDrawingsWithIndex = undefined;
      const res = await downloadFile(config.fileId);
      if (res) {
        const auxUrl = URL.createObjectURL(new Blob([res]));
        urls = {
          url: auxUrl,
          index: config.sortIndex,
        };

        return urls;
      }
      return undefined;
    })
  );
  if (Array.isArray(results) && results.includes(undefined)) {
    return undefined;
  }
  return results;
}
