import { axiosInstance } from "../../../..";
import { Configuration } from "../../models";

export interface UpdateConfiguration {
  id: string;
  isActive: boolean;
  maxDataAgeInDaysError: number;
  maxDataAgeInDaysWarning: number;
}

export async function editConfigurationUpdate(
  machineId: string,
  configuration: UpdateConfiguration
): Promise<Configuration | any> {
  const urlRequest =
    "/meta/write/v1/machines/" +
    machineId +
    "/configurations/" +
    configuration.id;
  const response = await axiosInstance.put<UpdateConfiguration | any>(
    urlRequest,
    configuration
  );
  return response;
}
