import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  TextField,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { ConnectionEntryDetailed, MetadataProject } from "../../Schema/models";
import { Utils } from "../../Utils/utils";
import FormDialog from "../generic/FormDialog";
import { DialogSize, notification } from "web-analysis-lib";
import { AxiosContext } from "../../VpnConnectionsManager/VpnConnectionsManager";
import { VpnConnectionsAPI } from "../../Schema/api";
import React from "react";

type EmailDetailsDialogProps = {
  item: ConnectionEntryDetailed | null;
  projects: MetadataProject[];
  onClose: () => void;
};

/**
 * The email details dialog component.
 * @param entry The VPN connections table item
 * @param projects the reference projects list.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns the Email details dialog component
 */
const EmailDetailsDialog = ({
  item,
  projects,
  onClose,
}: EmailDetailsDialogProps) => {
  const axiosInstance = useContext(AxiosContext);
  const [currentValue, setCurrentValue] = useState<string>(
    item?.emailDetails || ""
  );
  const [textFieldValue, setTextFieldValue] = useState<string>(
    item?.emailDetails || ""
  );
  const [dataHasChanged, setDataHasChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [projectName, setProjectName] = useState<string>("");

  // Gets the project name
  useEffect(() => {
    let project = projects.find((p) => p.id === item?.projectId);
    if (!project) {
      return;
    }

    setProjectName(project.name);
  }, [item?.projectId, projects]);

  if (!item) {
    return null;
  }

  const onEmailContactsChanged = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (!newValue) {
      return;
    }

    // Validates the emails
    let hasErrors = !Utils.emailsAreValid(newValue);
    let dataIsEqual = !Utils.emailsHaveChanged(newValue, currentValue);
    if (!hasErrors) {
      setDataHasChanged(!dataIsEqual);
    }

    // Sets the new emails contacts
    setTextFieldValue(newValue);
    setErrorMessage(
      hasErrors ? "One or many email addresses are not valid." : ""
    );
  };

  const onSendEmailHandler = () => {
    // Saves the email information if it has changed.
    if (dataHasChanged) {
      onSaveChangesHandler(false);
    }

    window.open(
      `mailto:${textFieldValue}?subject=DALOG%20VPN-${projectName.toUpperCase()}&body=Body%20goes%20here`
    );
  };

  const onSaveChangesHandler = (close: boolean) => {
    if (!axiosInstance) {
      return;
    }

    setIsLoading(true);
    let newEntry: ConnectionEntryDetailed = {
      ...item,
      projectName: projectName,
      emailDetails: textFieldValue.trim(),
    };

    // Sends the request.
    VpnConnectionsAPI.update(axiosInstance, newEntry).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          `Failure updating VPN Connection entry: ${response.statusText}.`
        );
        return;
      }

      notification.success("Success updating VPN Connection entry.");

      if (!close) {
        setCurrentValue(newEntry?.emailDetails || "");
        setDataHasChanged(false);
        return;
      }

      onClose();
    });
  };

  return (
    <FormDialog title={"Email Contacts"} size={DialogSize.M} onClose={onClose}>
      <TextField
        label="Customer email contacts *"
        multiline
        resizable={false}
        rows={4}
        value={textFieldValue}
        errorMessage={errorMessage}
        onChange={onEmailContactsChanged}
      />
      <DialogFooter>
        <PrimaryButton
          className="primary-button"
          disabled={isLoading || errorMessage.length > 0}
          onClick={onSendEmailHandler}
          text="Send Email"
        />
        <DefaultButton
          className="secondary-button"
          disabled={!dataHasChanged || isLoading || errorMessage.length > 0}
          onClick={() => onSaveChangesHandler(true)}
          text="Save Changes"
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
        />
      </DialogFooter>
    </FormDialog>
  );
};

export default EmailDetailsDialog;
