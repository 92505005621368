import { useMemo, useState } from "react";
import {
  BaseDialog,
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
  notification,
} from "web-analysis-lib";
import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
  Text,
} from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import { maxLengthType1 } from "../../schema/Constants";
import { addWirelessGateway } from "./api";
import { RequestWirelessGatewayAdd } from "./models";
import FormItemRow from "../Generic/FormItemRow";
import ControlledComboBox from "../Generic/ControlledComboBox";
import { useProjects } from "../../Hooks/useProjects";
import { ResponseProjectDetails } from "../Projects/models";

export const minRsshPort: number = 1;
export const maxRsshPort: number = 65535 * 2;

const getSchema = (serialNumbers: string[]) =>
  z
    .object({
      projectId: z.string().optional(),
      serialNumber: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Serial Number must contain at most ${maxLengthType1} character(s)`,
        })
        .refine(
          (val) =>
            serialNumbers
              .map((serialN) => serialN.trim().toLowerCase())
              .findIndex((value) => value === val.trim().toLowerCase()) === -1,
          {
            message: "The serial number already exists",
          }
        ),
      adminPassword: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Admin Password must contain at most ${maxLengthType1} character(s)`,
        }),
      userPassword: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `User Password must contain at most ${maxLengthType1} character(s)`,
        }),
      ltePlanSize: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Name must contain at most ${maxLengthType1} character(s)`,
        }),
      publicKey: z.string().optional(),
      rsshPort: z.string().optional(),
      wifiPasswordForAP: z.string().optional(),
      wifiSSID: z.string().optional(),
      iMEI: z.string().optional(),
      wirepassSinkNodeAddress: z.string().optional(),
      wirepassChannel: z.string().optional(),
      ethernetMacAddress: z.string().optional(),
      firmware: z.string().optional(),
      active: z.boolean().optional(),
      installationLocation: z.string().optional(),
    })
    .refine(
      (input) => {
        if (!input.ltePlanSize) {
          return true;
        }
        var regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);
        return valid.test(input.ltePlanSize);
      },
      {
        path: ["ltePlanSize"],
        message: "Try a number between (1.0:1000.0)",
      }
    )
    .refine(
      (input) => {
        if (!input.rsshPort) {
          return true;
        }
        var regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);
        return valid.test(input.rsshPort);
      },
      {
        path: ["rsshPort"],
        message: `Try a number between (${minRsshPort}:${maxRsshPort})`,
      }
    )
    .refine(
      (input) => {
        if (!input.rsshPort) {
          return true;
        }
        const rsshPortNumber = Number(input.rsshPort);
        return (
          !isNaN(rsshPortNumber) &&
          rsshPortNumber >= minRsshPort &&
          rsshPortNumber <= maxRsshPort
        );
      },
      {
        path: ["rsshPort"],
        message: `Try a number between (${minRsshPort}:${maxRsshPort})`,
      }
    );

const gatewayFields: FormItemProps[] = [
  {
    name: "serialNumber",
    type: FormItemType.TextField,
    groupProps: { label: "Serial Number *" },
  },
  {
    name: "adminPassword",
    type: FormItemType.TextField,
    groupProps: { label: "Admin Password *" },
  },
  {
    name: "userPassword",
    type: FormItemType.TextField,
    groupProps: { label: "User Password *" },
  },
  {
    name: "ltePlanSize",
    type: FormItemType.TextField,
    groupProps: { label: "Lte Plan Size *" },
  },
  {
    name: "publicKey",
    type: FormItemType.TextField,
    groupProps: { label: "Public Key" },
  },
  {
    name: "rsshPort",
    type: FormItemType.TextField,
    groupProps: { label: "Rssh Port" },
  },
  {
    name: "wifiPasswordForAP",
    type: FormItemType.TextField,
    groupProps: { label: "Wifi Password For AP" },
  },
  {
    name: "wifiSSID",
    type: FormItemType.TextField,
    groupProps: { label: "Wifi SSID" },
  },
  {
    name: "iMEI",
    type: FormItemType.TextField,
    groupProps: { label: "IMEI" },
  },
  {
    name: "wirepassSinkNodeAddress",
    type: FormItemType.TextField,
    groupProps: { label: "Wirepass Sink Node Address" },
  },
  {
    name: "wirepassChannel",
    type: FormItemType.TextField,
    groupProps: { label: "Wirepass Channel" },
  },
  {
    name: "ethernetMacAddress",
    type: FormItemType.TextField,
    groupProps: { label: "Ethernet Mac Address" },
  },
  {
    name: "firmware",
    type: FormItemType.TextField,
    groupProps: { label: "Firmware" },
  },
  {
    name: "active",
    type: FormItemType.Checkbox,
    groupProps: { label: "Active" },
  },
  {
    name: "installationLocation",
    type: FormItemType.TextField,
    groupProps: { label: "Installation Location" },
  },
];

type AddDialogProps = IDialogProps & {
  serialNumbers: string[];
  show: boolean;
  onSuccess: (hasError: boolean, data: string) => void;
  onClose: () => void;
};

export const AddDialog = ({
  serialNumbers,
  show,
  onSuccess,
  onClose,
  ...rest
}: AddDialogProps) => {
  const schema = useMemo(() => getSchema(serialNumbers), [serialNumbers]);
  const { projects } = useProjects();
  const [isLoading, setLoading] = useState(false);
  const [project, setProject] = useState<ResponseProjectDetails | undefined>(
    undefined
  );
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useZodForm({
    mode: "onChange",
    schema,
    defaultValues: {
      serialNumber: "",
      adminPassword: "",
      userPassword: "",
      wirepassChannel: "26",
      active: true,
    },
  });

  // Handlers
  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);
    !project && notification.warning("Un-parented gateway");

    const toSend: RequestWirelessGatewayAdd = {
      projectId: project.id,
      adminPassword: formData.adminPassword,
      serialNumber: formData.serialNumber,
      userPassword: formData.userPassword,
      ltePlanSize: Number(formData.ltePlanSize) as number,
      rsshPort: Number(formData.rsshPort) as number,
      publicKey: formData.publicKey,
      wifiPasswordForAP: formData.wifiPasswordForAP,
      wifiSSID: formData.wifiSSID,
      iMEI: formData.iMEI,
      wirepassSinkNodeAddress: formData.wirepassSinkNodeAddress,
      wirepassChannel: formData.wirepassChannel,
      ethernetMacAddress: formData.ethernetMacAddress,
      firmware: formData.firmware,
      active: formData.active,
      installationLocation: formData.installationLocation,
    };

    await addWirelessGateway(toSend).then((response) =>
      onSuccess("status" in response, toSend.serialNumber)
    );

    handleClose();
  });

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Add new Gateway",
        closeButtonAriaLabel: "Close",
        onDismiss: handleClose,
      }}
    >
      <form onSubmit={onSubmit}>
        <FormItemRow label={"Company"} style={{ marginBottom: "0.75em" }}>
          <Text as="p" variant="medium" style={{ fontWeight: 600 }}>
            {project?.company?.name}
          </Text>
        </FormItemRow>
        <FormItemRow label="Project">
          <ControlledComboBox
            options={Array.from(projects.values()).map((project) => {
              return { key: project.id, text: project.name };
            })}
            selectedKey={project?.id}
            disabled={false}
            onKeySelected={(key: string) => setProject(projects.get(key))}
          />
        </FormItemRow>
        {renderFormItems(gatewayFields, {
          control,
          errors: errors as { [schemaProp: string]: FieldError },
        })}
        <DialogFooter>
          <PrimaryButton
            type="submit"
            text="Save Changes"
            disabled={isLoading || !isValid || !project}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
          <DefaultButton
            styles={{
              root: { border: "unset", background: "transparent" },
            }}
            text="Cancel"
            onClick={handleClose}
          />
        </DialogFooter>
      </form>
    </BaseDialog>
  );
};
