import { axiosInstance } from "../..";
import { ResponseSimplifiedSignal } from "../Machines/models";
import { CreateSignal, UpdateSignal, UpdateSignalCondition } from "./models";

export type signalsBulkNotCommon = Omit<
  ResponseSimplifiedSignal,
  "dataFrom" | "dataUntil" | "sensorSerialNo" | "sensorNo"
>;
export type signalsToEditBulk = Pick<
  UpdateSignal,
  | "sensorSerialNo"
  | "sensorNo"
  | "isInternal"
  | "bufferId"
  | "sampleRateDalog"
  | "wirelessSensorNodeId"
>;

export interface signalsBulkProps {
  notCommon: signalsBulkNotCommon[];
  editSignal: signalsToEditBulk;
}

export async function editBulkSignals(
  props: signalsBulkProps
): Promise<ResponseSimplifiedSignal | any> {
  let results;
  const propsToEditBulk: UpdateSignal[] = props.notCommon.map((sig) => {
    return { ...sig, ...props.editSignal };
  });

  results = await Promise.all(
    propsToEditBulk.map(async (config) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await editSignal(config.machineId, config.id, config);
    })
  );

  return results;
}

export async function addSignal(
  machineId: string,
  signal: CreateSignal
): Promise<ResponseSimplifiedSignal | any> {
  const urlRequest = "/meta/write/v1/machines/" + machineId + "/signals";

  const response = await axiosInstance.post<ResponseSimplifiedSignal | any>(
    urlRequest,
    signal
  );
  if (response.data) return response.data;
  else return response;
}

export async function editSignal(
  machineId: string,
  id: string,
  signal: UpdateSignal
): Promise<ResponseSimplifiedSignal | any> {
  const urlRequest = "/meta/write/v1/machines/" + machineId + "/signals/" + id;

  const response = await axiosInstance.put<ResponseSimplifiedSignal | any>(
    urlRequest,
    signal
  );
  if (response.data) return response.data;
  else return response;
}

export async function deleteSignal(
  machineId: string,
  id: string
): Promise<ResponseSimplifiedSignal | any> {
  const urlRequest = "/meta/write/v1/machines/" + machineId + "/signals/" + id;

  const response = await axiosInstance.delete<ResponseSimplifiedSignal | any>(
    urlRequest
  );
  if (response.data) return response.data;
  else return response;
}

export async function signalsImport(
  machineId: string,
  file_in: any
): Promise<any> {
  const formData = new FormData();
  formData.append("file", file_in!);
  const urlRequest =
    "/meta/write/v1/machines/" + machineId + "/signals/import/";
  const response = await axiosInstance.post<any>(urlRequest, formData);
  return response;
}

export async function signalsList(
  machineId: string
): Promise<any | ResponseSimplifiedSignal[]> {
  const response = await axiosInstance.get<any>(
    "/meta/read/v1/machines/" + machineId + "/signals/"
  );
  if (response.data) return response.data;
  else return response;
}

export async function editSignalCondition(
  machineId: string,
  id: string,
  signalCondition: UpdateSignalCondition
): Promise<ResponseSimplifiedSignal | any> {
  const urlRequest =
    "/meta/write/v1/machines/" + machineId + "/signals/" + id + "/condition";

  const response = await axiosInstance.post<ResponseSimplifiedSignal | any>(
    urlRequest,
    signalCondition
  );
  if (response.data) return response.data;
  else return response;
}
