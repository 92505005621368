import { axiosInstance } from "../..";

export async function fetchUserAccountDetails(id: string): Promise<any> {
    const response = await axiosInstance.get<any>(`/rbac/v1/users/${id}`);
    return response?.data;
}

export async function updateUserAccountDetails(id, payload): Promise<any> {
    const response = await axiosInstance.put<any>(`/rbac/v1/users/${id}`, payload);
    
    if (response.data) return response.data;

    else return response;
  }