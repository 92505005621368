import {
  Stack,
  IStackStyles,
  DefaultPalette,
  StackItem,
  INavLink,
  Nav,
  INavLinkGroup,
  INavStyles,
  Separator,
  Panel,
  PanelType,
  SearchBox,
  ActionButton,
  Spinner,
} from "@fluentui/react";
import { createContext, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  IAuthorizationResult,
  useAuthorization,
} from "../../Hooks/authorization";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  listAsyncProjs,
  selectProjects,
  selectProjectsStatus,
} from "../Projects/reducer";
import {
  listAsyncDashB,
  selectDashboards,
  selectDashboardsStatus,
  toShowLoad,
} from "../DashboardsMetadata/reducer";
import { Status } from "../../schema/status";
import { ResponseUserGet } from "../UsersRBCA/models";
import { useNetwork } from "../../Hooks/useNetwork";
import { notification } from "web-analysis-lib";
import { useAuth0 } from "@auth0/auth0-react";

export const isClickedAccountIconContext = createContext<
  React.Dispatch<React.SetStateAction<boolean>> | undefined
>(undefined);

export const isClickedUpdateUserContext = createContext<
  React.Dispatch<React.SetStateAction<boolean>> | undefined
>(undefined);

export const isClickedUpdateProfilePhotoContext = createContext<
  React.Dispatch<React.SetStateAction<boolean>> | undefined
>(undefined);

export interface localAccountInfoProps {
  val: ResponseUserGet | undefined;
  set: React.Dispatch<React.SetStateAction<ResponseUserGet | undefined>>;
}

export const localAccountInfo = createContext<
  localAccountInfoProps | undefined
>(undefined);

interface navLinkGroupDes {
  navLinkGroup: INavLinkGroup[] | undefined;
  canI: boolean | undefined;
  name: string;
}

interface dashToRender {
  name: string;
  url: string;
  icon: string;
}
export const authContext = createContext<IAuthorizationResult | undefined>(
  undefined
);

export const LeftMenuAlt: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (props) => {
  const auth = useAuthorization();
  const dashboardStatus = useAppSelector(selectDashboardsStatus);
  const [isWaffle, setIsWaffle] = useState(false);
  const dashboards = useAppSelector(selectDashboards);
  const projectStatus = useAppSelector(selectProjectsStatus);
  const dispatch = useAppDispatch();
  const projs = useAppSelector(selectProjects);
  const [dashesToRender, setDashesToRender] = useState<dashToRender[]>([]);
  const [dashesToRenderFiltered, setDashesToRenderFiltered] =
    useState<dashToRender[]>(dashesToRender);
  const navigate = useNavigate();
  const [isOpenPanel, setIsOpenPanel] = useState(false);
  const location = useLocation();
  const amIOnline = useNetwork();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (amIOnline && !auth.loading) notification.success("We are back!");
    else if (!amIOnline)
      notification.severeWarning(
        "We are offline, please check your internet connection."
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amIOnline]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        //context?.setValue(false);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;

    if (projectStatus === Status.void) dispatch(listAsyncProjs());
    if (dashboardStatus === Status.void && projectStatus === Status.idle)
      dispatch(listAsyncDashB());
    if (projectStatus === Status.idle && dashboardStatus === Status.idle) {
      dispatch(toShowLoad(projs));
    }
  }, [dashboardStatus, dispatch, projectStatus, projs, isAuthenticated]);

  useEffect(() => {
    if (projectStatus === Status.idle) {
      const dashBoardsSorted = dashboards
        .slice(0)
        .sort((a, b) => (a.parentType > b.parentType ? 1 : -1));

      const dashIni = dashBoardsSorted.map((dashS) => {
        const nameOfParent = "(" + dashS.parentType + ")" + dashS.parentId;

        return {
          name: nameOfParent,
          url:
            "dashboard/" +
            dashS.parentType.toLowerCase() +
            "/" +
            dashS.parentId,
          icon: "DashBoards",
        };
      });
      setDashesToRender(dashIni);
      setDashesToRenderFiltered(dashIni);
    }
  }, [dashboards, dashboardStatus, projectStatus]);

  const onDismiss = useCallback(
    (ev?: React.SyntheticEvent<HTMLElement>) => {
      if (!ev) {
        return;
      }
      const srcElement = ev.nativeEvent.target as Element | null;
      if (srcElement && srcElement.className.indexOf("ms-Button-icon") !== -1) {
      }
      if (srcElement && srcElement.className.indexOf("ms-Overlay") !== -1) {
      }
      setIsOpenPanel(false);
      setDashesToRenderFiltered(dashesToRender);
    },
    [dashesToRender]
  );

  // Mutating styles definition
  const stackStyles: IStackStyles = {
    root: {
      background: DefaultPalette.white,
      height: "100%",
      overflowY: "auto",
      width: "auto",
      minWidth: isWaffle
        ? auth.powerBiContributor ||
          auth.wirelessSensorContributor ||
          auth.wirelessSensorReader
          ? "220px"
          : "150px"
        : "48px",
    },
  };

  const stackStylesWaffle: IStackStyles = {
    root: {
      background: DefaultPalette.white,
      height: "110px",
    },
  };

  const navStyles: Partial<INavStyles> = {
    root: {
      width: isWaffle ? undefined : 48,
      boxSizing: "border-box",
      border: "1px solid #eee",
      overflowY: "auto",
      //opacity: "0",
      //transition: "opacity 200ms ease 0s",
    },
    link: {
      paddingLeft: 9,
    },
    groupContent: {
      margin: 0,
    },
  };

  const styles: Partial<INavStyles> = {
    root: [
      {
        height: 1,
        padding: "0px",
        background: "Grey40",
      },
    ],
  };

  const waffleGroup: INavLinkGroup[] = [
    {
      name: "",
      links: [
        {
          name: "",
          url: "./",
          icon: isWaffle ? "DoubleChevronLeft" : "DoubleChevronRight",
          key: "waffle",
          title: isWaffle ? "Show less information" : "Show more information",
        },
      ],
    },
  ];

  const homeGroup: INavLinkGroup[] = [
    {
      name: "",
      links: auth.powerBiReader
        ? [
            {
              name: "Home",
              url: "/",
              icon: "Home12",
            },
          ]
        : [
            {
              name: "Home",
              url: "/",
              icon: "Home",
            },
          ],
    },
  ];

  const assistantGroup: INavLinkGroup[] = [
    {
      name: "",
      links: [
        {
          name: "Assistant",
          url: "/assistant",
          icon: "chatbot",
        },
      ],
    },
  ];

  const machineCvGroup: INavLinkGroup[] = [
    {
      name: "",
      links: [
        {
          name: "MachineCV",
          url: "/machine-cv",
          icon: "machineCV",
        },
      ],
    },
  ];

  const analysisGroup: INavLinkGroup[] = [
    {
      name: "",
      links: [
        {
          name: "Trend View",
          url: "/trend-view",
          icon: "TrendView",
        },
        {
          name: "Raw Data",
          url: "/raw-data",
          icon: "RawView",
        },
      ],
    },
  ];

  const wirelessGroup: INavLinkGroup[] = [
    {
      name: "",
      links: [
        {
          name: "Gateways",
          url: "/gateways",
          icon: "Gateways",
        },
        {
          name: "Sensor Nodes",
          url: "/sensorNode",
          icon: "SensorsNodes",
        },
        {
          name: "Sensor Configurator",
          url: "/sensor-configurator",
          icon: "SensorConfiguration",
        },
      ],
    },
  ];

  const metaDataGroup: INavLinkGroup[] = [
    {
      name: "",
      links: [
        {
          name: "Corporations",
          url: "/corporations",
          icon: "Corporations",
        },
        {
          name: "Companies",
          url: "/companies",
          icon: "Companies",
        },
        {
          name: "Projects",
          url: "/projects",
          icon: "Projects",
        },
        {
          name: "Machines",
          url: "/machines",
          icon: "Machines",
        },
      ],
    },
  ];

  auth.imageAdministrator &&
    metaDataGroup.at(0).links.push({
      name: "Images",
      url: "/images",
      icon: "Images",
      title: "Images",
    });

  metaDataGroup.at(0).links.push({
    name: "Dataloggers",
    url: "/dataloggers",
    icon: "Datalogger",
    title: "Dataloggers",
  });

  auth.powerBiContributor &&
    metaDataGroup.at(0).links.push({
      name: "DBoard Management",
      url: "/dashboardsMetaData",
      icon: "DashBoardsManagement",
      title: "Dashboards Management",
    });

  auth.vpnReader &&
    metaDataGroup.at(0).links.push({
      name: "Vpn Connections",
      url: "/vpnConnections",
      icon: "VpnIcon",
      title: "Vpn Connections",
    });

  auth.submissionAdministrator &&
    metaDataGroup.at(0).links.push({
      name: "Submissions",
      url: "/submissions",
      icon: "ApprovalSubmissions",
      title: "Submissions",
    });

  const rBACGroup: INavLinkGroup[] = [
    {
      name: "",
      links: [
        {
          name: "Permissions",
          url: "/permissions",
          icon: "Permissions",
        },
        {
          name: "Service Accounts",
          url: "/services",
          icon: "Robot",
        },
        {
          name: "Users",
          url: "/users",
          icon: "Contact",
        },
      ],
    },
  ];

  const allLinksToSelect: navLinkGroupDes[] = [
    { navLinkGroup: homeGroup, canI: true, name: "Home Group" },
    { name: "Separator", canI: undefined, navLinkGroup: undefined },
    {
      navLinkGroup: assistantGroup,
      canI: auth.userAdministrator,
      name: "Assistant Group",
    },
    { name: "Separator", canI: undefined, navLinkGroup: undefined },
    {
      navLinkGroup: machineCvGroup,
      canI: auth.machineCvReader,
      name: "MachineCv Group",
    },
    { name: "Separator", canI: undefined, navLinkGroup: undefined },
    {
      navLinkGroup: analysisGroup,
      canI: auth.measuredDataReader,
      name: "Analysis Group",
    },
    { name: "Separator", canI: undefined, navLinkGroup: undefined },
    {
      navLinkGroup: wirelessGroup,
      canI: auth.wirelessSensorReader,
      name: "Wireless Group",
    },
    { name: "Separator", canI: undefined, navLinkGroup: undefined },
    {
      navLinkGroup: metaDataGroup,
      canI: auth.metaDataContributor,
      name: "MetaData Group",
    },
    { name: "Separator", canI: undefined, navLinkGroup: undefined },
    {
      navLinkGroup: rBACGroup,
      canI: auth.userAdministrator,
      name: "RBAC Group",
    },
  ];

  const onClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
    ev?.nativeEvent.preventDefault();
    ev?.stopPropagation();

    if (item.key === "waffle") {
      setIsWaffle((prev) => !prev);
      setIsOpenPanel(false);
    } else {
      if (item.name === "DashBoards") {
        setIsWaffle(false);
      } else setIsOpenPanel(false);

      if (item && item.url) {
        navigate(item.url, {
          state: { prevPath: location.pathname },
          replace: false,
        });
      }
    }
  };

  const onChangeSearchBox = (event, newValue): void => {
    setDashesToRenderFiltered(
      dashesToRender.filter(
        (dash) => dash.name.toLowerCase().indexOf(newValue.toLowerCase()) > -1
      )
    );
  };
  return (
    <>
      {!auth.loading ? (
        <>
          <Stack
            horizontal
            style={{
              position: "fixed",
              top: 50,
              left: 0,
              height: "100vh",
              zIndex: 999,
              width: "100%",
            }}
          >
            <Stack>
              <StackItem styles={stackStyles}>
                {allLinksToSelect
                  .filter((linkOb) => linkOb.canI === true)
                  .map((linkG, index) =>
                    linkG.navLinkGroup ? (
                      <Nav
                        styles={navStyles}
                        ariaLabel={linkG.name}
                        groups={linkG.navLinkGroup}
                        onLinkClick={onClick}
                        key={index}
                      />
                    ) : (
                      <>
                        <Separator styles={styles}></Separator>
                      </>
                    )
                  )}
              </StackItem>
              {/* <Stack verticalAlign="end"> */}
              <StackItem styles={stackStylesWaffle}>
                <>
                  <Nav
                    styles={navStyles}
                    ariaLabel={"Expandable/Collapsible"}
                    groups={waffleGroup}
                    onLinkClick={onClick}
                  />
                </>
              </StackItem>
              {/* </Stack> */}
            </Stack>

            <Panel
              styles={{
                root: {
                  marginTop: "50px",
                  marginLeft: "48px",
                  height: "100%",
                  position: "absolute",
                },
                contentInner: { backgroundColor: "#F3F2F1" },
              }}
              type={PanelType.customNear}
              customWidth={"256px"}
              hasCloseButton={false}
              isOpen={isOpenPanel}
              onDismiss={onDismiss}
              headerText="Dashboards"
              isLightDismiss={true}
            >
              <SearchBox
                styles={{
                  root: { marginTop: "40px" },
                }}
                placeholder="Search..."
                ariaLabel="Search dashboards"
                onChange={onChangeSearchBox}
              />

              {dashesToRenderFiltered.map((dash, index) => (
                <p key={index}>
                  <ActionButton
                    iconProps={{ iconName: "Dashboards" }}
                    allowDisabledFocus
                    onClick={() => navigate(dash.url, { replace: false })}
                  >
                    {dash.name}
                  </ActionButton>
                </p>
              ))}
            </Panel>

            <authContext.Provider value={{ ...auth }}>
              <>{props.children}</>
            </authContext.Provider>
          </Stack>
        </>
      ) : (
        <Spinner label="Loading data..." />
      )}
    </>
  );
};
