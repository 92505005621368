import { IconNames, Icons } from "./IconNames";

interface IconProperties {
  className?: string;
  viewBox?: string;
  title?: string;
  style?: any;
  role?: string;
  width?: string;
  height?: string;
  color?: string;
  name: IconNames;
}

const Icon: React.FC<IconProperties> = ({
  viewBox,
  title,
  width,
  height,
  name,
  color,
  ...props
}) => (
  <svg width={width} height={height} viewBox={viewBox} color={color} {...props}>
    {title && <title>{title}</title>}
    {Icons[name]()}
  </svg>
);

Icon.defaultProps = {
  viewBox: "0 0 24 24",
  width: "24",
  height: "24",
  role: "img",
  color: "#3B3A39",
};

export default Icon;
