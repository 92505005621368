import { AxiosResponse } from "axios";
import { ImageInformation, ImagesStatisticsResponse } from "./models";

const now = new Date();

const imagesInformationData: ImageInformation[] = [
  {
    id: "1",
    machineId: "46dd6187-e685-4cc5-97d5-629e9fba67d6",
    fileName: "2023_06_01_CONF_01",
    cfgName: "CONF_01",
    signalType: "Trend",
    signalCount: 961,
    timeStampAdded: new Date(2023, 6, 1).toUTCString(),
    timeStampUpdated: new Date(2023, 7, 1).toUTCString(),
  },
  {
    id: "2",
    machineId: "46dd6187-e685-4cc5-97d5-629e9fba67d6",
    fileName: "2023_10_19_CONF_02",
    cfgName: "CONF_02",
    signalType: "ShortTerm",
    signalCount: 175,
    timeStampAdded: new Date(2023, 10, 19).toUTCString(),
    timeStampUpdated: new Date(2024, 1, 10).toUTCString(),
  },
];

const imagesStatisticsData: ImagesStatisticsResponse = {
  statistics: [
    {
      average: Math.random() * 100,
      median: Math.random() * 100,
      minimum: 0,
      maximum: 100,
      count: 10,
    },
  ],
  dataExtractionDuration: [
    {
      timeStamp: now.toUTCString(),
      fileName: "File01.sav",
      overall: Math.random() * 100,
      event: Math.random() * 100,
      fastTrend: Math.random() * 100,
      short: Math.random() * 100,
      trend: Math.random() * 100,
    },
    {
      timeStamp: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours() - 3
      ).toUTCString(),
      fileName: "File02.sav",
      overall: Math.random() * 100,
      event: Math.random() * 100,
      fastTrend: Math.random() * 100,
      short: Math.random() * 100,
      trend: Math.random() * 100,
    },
    {
      timeStamp: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours() - 3
      ).toUTCString(),
      fileName: "File03.sav",
      overall: Math.random() * 100,
      event: Math.random() * 100,
      fastTrend: Math.random() * 100,
      short: Math.random() * 100,
      trend: Math.random() * 100,
    },
    {
      timeStamp: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours() - 6
      ).toUTCString(),
      fileName: "File04.sav",
      overall: Math.random() * 100,
      event: Math.random() * 100,
      fastTrend: Math.random() * 100,
      short: Math.random() * 100,
      trend: Math.random() * 100,
    },
    {
      timeStamp: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours() - 12
      ).toUTCString(),
      fileName: "File05.sav",
      overall: Math.random() * 100,
      event: Math.random() * 100,
      fastTrend: Math.random() * 100,
      short: Math.random() * 100,
      trend: Math.random() * 100,
    },
    {
      timeStamp: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 1
      ).toUTCString(),
      fileName: "File06.sav",
      overall: Math.random() * 100,
      event: Math.random() * 100,
      fastTrend: Math.random() * 100,
      short: Math.random() * 100,
      trend: Math.random() * 100,
    },
    {
      timeStamp: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 2
      ).toUTCString(),
      fileName: "File07.sav",
      overall: Math.random() * 100,
      event: Math.random() * 100,
      fastTrend: Math.random() * 100,
      short: Math.random() * 100,
      trend: Math.random() * 100,
    },
    {
      timeStamp: new Date(2023, 12, 1).toUTCString(),
      fileName: "File08.sav",
      overall: Math.random() * 100,
      event: Math.random() * 100,
      fastTrend: Math.random() * 100,
      short: Math.random() * 100,
      trend: Math.random() * 100,
    },
    {
      timeStamp: new Date(2032, 12, 1).toUTCString(),
      fileName: "File09.sav",
      overall: Math.random() * 100,
      event: Math.random() * 100,
      fastTrend: Math.random() * 100,
      short: Math.random() * 100,
      trend: Math.random() * 100,
    },
    {
      timeStamp: new Date(2023, 12, 1).toUTCString(),
      fileName: "File10.sav",
      overall: Math.random() * 100,
      event: Math.random() * 100,
      fastTrend: Math.random() * 100,
      short: Math.random() * 100,
      trend: Math.random() * 100,
    },
  ],
};

export class MockImagesAPI {
  static list = async (): Promise<AxiosResponse<ImageInformation[]>> => {
    let response: AxiosResponse<ImageInformation[]> = {
      data: imagesInformationData,
      status: 200,
      statusText: "OK",
      headers: {},
      config: {},
    };

    await new Promise((f) => setTimeout(f, 1000));

    return response;
  };

  static getStatistics = async (
    startDate: Date,
    endDate: Date
  ): Promise<AxiosResponse<ImagesStatisticsResponse>> => {
    let dataExtractionDuration =
      imagesStatisticsData.dataExtractionDuration.filter(
        (d) =>
          new Date(d.timeStamp).getTime() >= startDate.getTime() &&
          new Date(d.timeStamp).getTime() <= endDate.getTime()
      );

    let response: AxiosResponse<ImagesStatisticsResponse> = {
      data: {
        statistics: [...imagesStatisticsData.statistics],
        dataExtractionDuration: dataExtractionDuration,
      },
      status: 200,
      statusText: "OK",
      headers: {},
      config: {},
    };

    await new Promise((f) => setTimeout(f, 1000));

    return response;
  };
}
