import { axiosInstance } from "../../..";
import { ResponseWirelessSensorNodeDetails } from "../models";

export async function fetchData(
  id: string
): Promise<ResponseWirelessSensorNodeDetails> {
  const response = await axiosInstance.get<ResponseWirelessSensorNodeDetails>(
    `/meta/read/internal/v1/wirelessSensorNodes/${id}`
  );
  return response.data;
}
