export enum ImageType {
  None,
  Image,
  Zip,
  GZip,
  BusyBee,
  FLS,
  Wireless,
}

export enum ImagesTimeRanges {
  LastHour = "Last hour",
  Last3Hours = "Last 3 hours",
  Last6Hours = "Last 6 hours",
  Last12Hours = "Last 12 hours",
  LastDay = "Last day",
  LastMonth = "Last month",
  Custom = "Custom",
}

export type ImageInformation = {
  id: string;
  machineId: string;
  fileName: string;
  cfgName: string;
  signalType: string;
  signalCount: number;
  timeStampAdded: string;
  timeStampUpdated: string;
};

export type CfgToMachinePinningItem = {
  machineId: string;
  cfgName: string;
  id?: string;
  machineName?: string;
  createdByDisplayName?: string;
  createdAt?: string;
  updatedByDisplayName?: string;
  updatedAt?: string;
  projectId?: string;
  projectName?: string;
  companyName?: string;
  corporationName?: string;
};

export type UploadImageAdditionalFormData = {
  machineId?: string;
  dalogId?: string;
  isFileEncrypted?: boolean;
};

export type UploadImageFormData = UploadImageAdditionalFormData & {
  file: File;
};

export type TimeRange = {
  dateFrom: Date;
  dateTo: Date;
};

export type ImagesStatistics = {
  average: number;
  median: number;
  minimum: number;
  maximum: number;
  count: number;
};

export type ImageDataExtractionDuration = {
  timeStamp: string;
  fileName: string;
  overall: number;
  event: number;
  fastTrend: number;
  short: number;
  trend: number;
};

export type ImagesStatisticsResponse = {
  statistics: ImagesStatistics[];
  dataExtractionDuration: ImageDataExtractionDuration[];
};
