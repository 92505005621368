import { axiosInstance } from "../../..";
import { ResponseSimplifiedSignal } from "../../Machines/models";

export async function signalsPerSensorGet(
  machineId: string,
  sensorNo: string
): Promise<any | ResponseSimplifiedSignal[]> {
  const response = await axiosInstance.get<any>(
    "/meta/read/v1/machines/" + machineId + "/sensors/" + sensorNo + "/signals"
  );
  if (response.data) return response.data;
  else return response;
}
