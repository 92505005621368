/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  useTableFilters,
  notification,
  Table,
  Column,
  CommandBarItemProps,
  CommandBarItemType,
} from "web-analysis-lib";
import { useAppSelector } from "../../../hooks";
import { WithLoadingPanelHOC } from "../../Generic/HOCs";
import { selectMachinesToList } from "../../Machines/reducer";
import ImagesPivotContainer from "../Generic/ImagesPivotContainer";
import { ImagesAPI } from "../api";
import { ImageType } from "../models";
import {
  UploadImageWithMachineDialog,
  UploadImageFlsDialog,
  UploadImageWirelessDialog,
} from "./UploadImageDialogs";
import { format } from "../../../schema/Utils";

type TableItem = {
  corporationName: string;
  companyName: string;
  projectName: string;
  machineName: string;
  fileName: string;
  cfgName: string;
  signalType: string;
  signalCount: number;
  timeStampAdded: string;
  timeStampUpdated: string;
};

type ImageTableProps = {
  hasAddPermissions: boolean;
};

type ActionDialogProps = {
  imageType: ImageType;
  onClose: (listHasChanged: boolean) => void;
};

const timeoutDelay: number = 60000 * 5;

const tableColumns: Column[] = [
  {
    key: "corporationName",
    name: "Corporation",
    fieldName: "corporationName",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "companyName",
    name: "Company",
    fieldName: "companyName",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "projectName",
    name: "Project",
    fieldName: "projectName",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "machineName",
    name: "Machine",
    fieldName: "machineName",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "fileName",
    name: "File Name",
    fieldName: "fileName",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "cfgName",
    name: "Configname",
    fieldName: "cfgName",
    minWidth: 100,
    isSortable: true,
  },

  {
    key: "signalType",
    name: "Signal Type",
    fieldName: "signalType",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "signalCount",
    name: "Signal Count",
    fieldName: "signalCount",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "timeStampAdded",
    name: "Added At",
    fieldName: "timeStampAdded",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "timeStampUpdated",
    name: "Updated At",
    fieldName: "timeStampUpdated",
    minWidth: 100,
  },
];

/**
 * Gets the command bar items props.
 * @param onAddImage Method called when the Add Image button is clicked.
 * @param onAddZipImage Method called when the Add Zip Image button is clicked.
 * @param onAddGZipImage Method called when the Add GZip Image button is clicked.
 * @param onAddBusyBeeImage Method called when the Add BusyBee Image button is clicked.
 * @param onAddFlsImage Method called when the Add FLS Image button is clicked.
 * @param onAddWirelessImage Method called when the Add Wireless Image button is clicked.
 * @returns The Command bar item props list.
 */
const getBarItems = (
  onAddImage: () => void,
  onAddZipImage: () => void,
  onAddGZipImage: () => void,
  onAddBusyBeeImage: () => void,
  onAddFlsImage: () => void,
  onAddWirelessImage: () => void
): CommandBarItemProps[] => {
  let actionProps: CommandBarItemProps[] = [
    {
      key: "addImage",
      text: "Add Image",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Add" },
      onClick: onAddImage,
    },
    {
      key: "addZipImage",
      text: "Add Zip Image",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "ZipFolder" },
      onClick: onAddZipImage,
    },
    {
      key: "addGzipImage",
      text: "Add GZip Image",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "PageAdd" },
      onClick: onAddGZipImage,
    },
    {
      key: "addBusyBeeImage",
      text: "Add BusyBee Image",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "WebAppBuilderFragmentCreate" },
      onClick: onAddBusyBeeImage,
    },
    {
      key: "addFlsImage",
      text: "Add FLS Image",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "AddToShoppingList" },
      onClick: onAddFlsImage,
    },
    {
      key: "addWirelessImage",
      text: "Add Wireless Image",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Streaming" },
      onClick: onAddWirelessImage,
    },
  ];

  return actionProps;
};

/**
 * Gets the action dialog component.
 * @param selectedItem The selected item object.
 * @param confignames The confignames reference list used for validation
 * @param onClose Method called to close the selected action dialog
 * @returns The action dialog component.
 */
const ActionDialog = ({ imageType, onClose }: ActionDialogProps) => {
  let result: JSX.Element = null;

  switch (imageType) {
    case ImageType.Image:
      result = (
        <UploadImageWithMachineDialog
          title={"Add Image"}
          accept={".img, .sav"}
          imageType={ImageType.Image}
          onClose={onClose}
        />
      );
      break;
    case ImageType.Zip:
      result = (
        <UploadImageWithMachineDialog
          title={"Add Zip Image"}
          accept={".zip"}
          imageType={ImageType.Zip}
          onClose={onClose}
        />
      );
      break;
    case ImageType.GZip:
      result = (
        <UploadImageWithMachineDialog
          title={"Add GZip Image"}
          accept={".gz"}
          imageType={ImageType.GZip}
          onClose={onClose}
        />
      );
      break;
    case ImageType.BusyBee:
      result = (
        <UploadImageWithMachineDialog
          title={"Add BusyBee Image"}
          accept={".sav"}
          imageType={ImageType.BusyBee}
          onClose={onClose}
        />
      );
      break;
    case ImageType.FLS:
      result = <UploadImageFlsDialog onClose={onClose} />;
      break;

    case ImageType.Wireless:
      result = <UploadImageWirelessDialog onClose={onClose} />;
      break;
  }

  return result;
};

const ImagesTable = ({ hasAddPermissions }: ImageTableProps) => {
  const [imageType, setImageType] = useState<ImageType>(ImageType.None);
  const [listHasChanged, setListHasChanged] = useState<boolean>(true);
  const [tableItems, setTableItems] = useState<TableItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const machines = useAppSelector(selectMachinesToList);
  const { filters, handleSearch } = useTableFilters<TableItem>({
    keys: [
      "corporationName",
      "companyName",
      "projectName",
      "machineName",
      "fileName",
      "cfgName",
    ],
  });

  // Configures a timeout to update the images table.
  useEffect(() => {
    if (listHasChanged) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setListHasChanged(true);
    }, timeoutDelay);

    return () => clearTimeout(timeoutId);
  }, [listHasChanged]);

  // Get the images information items
  useEffect(() => {
    if (machines.length === 0 || !listHasChanged) {
      return;
    }

    setIsLoading(true);
    ImagesAPI.list().then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          `Failure getting the images list: ${response.statusText}`
        );
        return;
      }

      let data: TableItem[] = response.data.map((item) => {
        let machine = machines?.find((m) => m.id === item.machineId);
        let tableItem: TableItem = {
          corporationName: machine?.corporationName ?? "",
          companyName: machine?.companyName ?? "",
          projectName: machine?.projectName ?? "",
          machineName: machine?.name ?? "",
          fileName: item.fileName,
          cfgName: item.cfgName,
          signalType: item.signalType,
          signalCount: item.signalCount,
          timeStampAdded: format(new Date(item.timeStampAdded)),
          timeStampUpdated: format(new Date(item.timeStampUpdated)),
        };

        return tableItem;
      });

      setTableItems(data);
    });

    setListHasChanged(false);
  }, [machines.length, listHasChanged]);

  // Handlers
  const onClose = (listHasChanged: boolean) => {
    setImageType(ImageType.None);
    listHasChanged && setListHasChanged(listHasChanged);
  };

  return (
    <ImagesPivotContainer
      title={"Images"}
      hasPermissions={hasAddPermissions}
      commandBarItemProps={getBarItems(
        () => setImageType(ImageType.Image),
        () => setImageType(ImageType.Zip),
        () => setImageType(ImageType.GZip),
        () => setImageType(ImageType.BusyBee),
        () => setImageType(ImageType.FLS),
        () => setImageType(ImageType.Wireless)
      )}
      handleSearch={handleSearch}
    >
      {WithLoadingPanelHOC(
        !isLoading,
        <Table
          items={tableItems}
          columns={tableColumns}
          header={{ title: "Images" }}
          hasSelection={false}
          persistOpts={{
            key: "images-table",
            version: 2,
          }}
          filters={filters}
        />
      )}

      <ActionDialog imageType={imageType} onClose={onClose} />
    </ImagesPivotContainer>
  );
};

export default ImagesTable;
