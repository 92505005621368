import { IconButton, Stack, Text } from "@fluentui/react";
import { CSSProperties, PropsWithChildren, useState } from "react";
import classnames from "classnames";

type ToggleContainerProps = {
  id?: string;
  title: string;
  sideText: string;
  open: boolean;
  show: boolean;
  onOpen?: (id: string) => void;
};

type ToggleIconProps = {
  open: boolean;
};

const textEllipsis: CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "80%",
  color: "#fff",
  paddingLeft: "0.5em",
};

const onHoverStyle = {
  root: {
    selectors: {
      ":hover": { background: "#0078D4 !important" },
    },
  },
};

/**
 * Gets the toggle icon component
 *  @param open A value indicating whether the toggle is opened or closed.
 *  @returns The stylized toggle icon component
 */
const ToggleIcon = ({ open }: ToggleIconProps) => {
  const iconName = open ? "ChevronUp" : "ChevronDown";
  return (
    <IconButton
      className="toggle-overview-button"
      iconProps={{
        iconName: iconName,
        styles: {
          root: {
            color: "#fff",
          },
        },
      }}
      styles={onHoverStyle}
    />
  );
};

/**
 * The toggle container component.
 * @param id The element HTML id.
 * @param title The title.
 * @param sideText The side text.
 * @param open A value indicating the default state.
 * @param show A value indicating whether or not the control should be shown.
 * @param onOpen Function called when the toggle container is opening.
 * @param children The React props.children property.
 * @returns the ToggleContainer component.
 */
export const ToggleContainer = ({
  id,
  title,
  sideText,
  open,
  show,
  children,
  onOpen,
}: PropsWithChildren<ToggleContainerProps>) => {
  const [isOpen, setIsOpen] = useState(open);
  const handleClick = (e) => {
    !isOpen && onOpen?.(e.currentTarget.id);
    setIsOpen(!isOpen);
  };

  return (
    <Stack
      style={{
        contentVisibility: "visible",
        paddingBottom: "0.5em",
        display: show ? "flex" : "none",
      }}
    >
      <Stack
        id={id}
        horizontal
        verticalAlign="center"
        className={classnames({ header: true, "header--open": isOpen })}
        onClick={handleClick}
        style={{ padding: "0.10em" }}
      >
        <Text style={textEllipsis}>{sideText}</Text>
        <Stack horizontal className="header-items" verticalAlign="center">
          <Text style={textEllipsis} variant="medium">
            {title}
          </Text>
        </Stack>
        <Stack horizontal horizontalAlign="end">
          <ToggleIcon open={isOpen} />
        </Stack>
      </Stack>
      <div hidden={!isOpen}>{children}</div>
    </Stack>
  );
};
