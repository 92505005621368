import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../../schema/status";
import { RootState } from "../../../store";
import { fetchData } from "./api";
import { CompanyDetailsWithChilds } from "../models";

export const listAsyncCompaDetails = createAsyncThunk(
  "companyDetail",
  async (id: string) => {
    const response = await fetchData(id);
    return response;
  }
);

export interface CompanyDetailsState {
  companyDet: CompanyDetailsWithChilds;
  status: Status;
  error: string;
}

const initialState: CompanyDetailsState = {
  companyDet: {
    corporationId: "",
    id: "",
    name: "",
    number: "",
    corporation: { id: "", name: "", number: "" },
    projects: [],
  },
  status: Status.loading,
  error: "",
};

export const companyDetailsSlice = createSlice({
  name: "companyDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncCompaDetails.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncCompaDetails.fulfilled, (state, action) => {
        state.status = Status.idle;
        state.error = "";
        state.companyDet = action.payload;
      })
      .addCase(listAsyncCompaDetails.rejected, (state) => {
        state.status = Status.error;
        state.error =
          "Error loading the list of projects. Please try again later.";
      });
  },
});

export const selectCompProjects = (state: RootState) =>
  state.companyDetails.companyDet.projects;

export const selectCompDetails = (state: RootState) =>
  state.companyDetails.companyDet;

export const selectCompanyDetailsStatus = (state: RootState) =>
  state.companyDetails.status;

export const selectCompanyDetailsError = (state: RootState) =>
  state.companyDetails.error;

export default companyDetailsSlice.reducer;
