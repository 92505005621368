import {
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TooltipHost,
  IObjectWithKey,
} from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import {
  BaseDialog,
  Column,
  DialogSize,
  Table,
  TableProps,
  FilePreview,
} from "web-analysis-lib";

import { formatFileSize } from "../../schema/Utils";
import { CreateFile, File } from "../FIles/models";
import { addBulkFiles, getFilesOfMachine } from "../FIles/api";
import { NoFilesToSelect } from "./NoFilesToSelect";
import AddDocumentDialog from "./AddDocumentDialog";
import { axiosInstance } from "../..";

type GetColumnsOpts = {
  idSelected: string;
  onSelected: (id: string) => void;
};

const getColumns = ({ idSelected, onSelected }: GetColumnsOpts): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: "Name",
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: (file) => {
      // TODO: should we use Link from react-router-dom?
      return (
        <FilePreview
          axiosInstance={axiosInstance}
          file={{
            name: file.name,
            id: file.id,
          }}
        />
        // <Link underline href="" style={{ fontSize: 13 }}>
        //   {name}
        // </Link>
      );
    },
  },
  {
    key: "size",
    fieldName: "size",
    name: "Size",
    minWidth: 100,
    //isSortable: true,
    onRender: ({ sizeInBytes }: File) => {
      return (
        <span style={{ fontSize: 13, color: "currentColor" }}>
          {formatFileSize(sizeInBytes)}
        </span>
      );
    },
  },
  {
    fieldName: "actions",
    key: "actions",
    name: "Actions",
    minWidth: 150,
    onRender: ({ id }) => (
      <div style={{ display: "flex" }} key={id + "actions"}>
        {onSelected && (
          <TooltipHost
            key={id + "actions2"}
            content={"Select as Icon "}
            styles={{ root: { display: "flex" } }}
          >
            {idSelected === id ? (
              <>
                <PrimaryButton
                  onClick={() => onSelected(id)}
                  text={"Selected Icon"}
                />
              </>
            ) : (
              <>
                <DefaultButton
                  onClick={() => onSelected(id)}
                  text={"Select as Icon"}
                />
              </>
            )}
          </TooltipHost>
        )}
      </div>
    ),
  },
];

export type AddDocumentBase = {
  description: string;
};

export type UploadedFile<T = AddDocumentBase> = AcceptedFile & T;

type AcceptedFile = {
  id: string;
  name: string;
  type: string;
  size: number;
  isValid: boolean;
  dateAdded: string;
  file: File;
};

type SelectFilesOfMachineDialogProps = {
  machineId: string;
  iconId: string;
  hidden: boolean;
  onClose: () => void;
  onSubmit: (id: string) => void;
};

type ItemsSelected = File & IObjectWithKey;

const SelectFileIconOfMachine = ({
  machineId,
  iconId,
  hidden,
  onClose,
  onSubmit,
}: SelectFilesOfMachineDialogProps) => {
  const [localFiles, setLocalFiles] = useState<ItemsSelected[]>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>(iconId);
  const [showAddMoreFiles, setShowAddMoreFiles] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;
    refreshFiles(machineId, setLocalFiles, iconId, isMounted);
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-files-dialog-machine",
        version: 1,
      },
      items: localFiles,
      hasSelection: false,
      columns: getColumns({
        idSelected: selectedId,
        onSelected: (fileId) => {
          setSelectedId(fileId);
        },
      }),
    }),
    [localFiles, selectedId]
  );

  return (
    <>
      <BaseDialog
        hidden={hidden}
        dialogContentProps={{
          type: DialogType.close,
          title: "Choose Icon from Files",
          onDismiss: () => {
            onClose();
            setSelectedId(undefined);
          },
        }}
        size={DialogSize.L}
      >
        {!localFiles ? (
          <Spinner size={SpinnerSize.large} />
        ) : localFiles.length > 0 ? (
          <>
            <Table {...tableProps} />
            <DefaultButton
              text="Add more image files"
              onClick={() => {
                setShowAddMoreFiles(true);
              }}
            />
            <DialogFooter>
              <PrimaryButton
                text="Assign Machine Icon"
                onClick={() => {
                  setIsSubmitting(true);
                  onSubmit(selectedId);
                  setSelectedId(undefined);
                  onClose();
                  setIsSubmitting(false);
                }}
                disabled={selectedId === undefined || selectedId === null}
                onRenderIcon={() =>
                  isSubmitting ? <Spinner size={SpinnerSize.xSmall} /> : null
                }
              />
              <DefaultButton
                text="Cancel"
                onClick={() => {
                  onClose();
                  setSelectedId(undefined);
                }}
              />
            </DialogFooter>
          </>
        ) : (
          <NoFilesToSelect
            machineId={machineId}
            onClose={() => onClose()}
            onSubmit={() =>
              refreshFiles(machineId, setLocalFiles, iconId, true)
            }
          />
        )}
      </BaseDialog>
      {showAddMoreFiles && (
        <AddDocumentDialog
          hidden={!showAddMoreFiles}
          onClose={() => setShowAddMoreFiles(false)}
          onSubmit={(data) => {
            const filesToCreate: CreateFile[] = data.map((ele) => {
              return {
                description: ele.description,
                file: ele.file,
                machineId: machineId,
                name: ele.name,
              };
            });
            addBulkFiles(filesToCreate)
              .then((resp) => console.log({ resp }))
              .finally(() =>
                refreshFiles(machineId, setLocalFiles, iconId, true)
              );
          }}
          accept={".jpg,.jpeg,.png"}
        />
      )}
    </>
  );
};

export default SelectFileIconOfMachine;

function refreshFiles(
  machineId: string,
  setLocalFiles: React.Dispatch<React.SetStateAction<ItemsSelected[]>>,
  iconId: string,
  isMounted: boolean
) {
  getFilesOfMachine(machineId).then((resp: File[]) => {
    isMounted &&
      setLocalFiles(
        resp
          .filter((ele) => ele.type.split("/").at(0) === "image")
          .sort((a, b) =>
            iconId === a.id
              ? -1
              : iconId === b.id
              ? 1
              : a.name > b.name
              ? 1
              : -1
          )
      );
  });
}
