import { ActionButton, IconButton, Stack } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import DeleteConnectionDialog from "./DeleteConnectionDialog";
import EmailDetailsDialog from "./EmailDetailsDialog";
import ConnectionDetailsDialog from "./ConnectionDetailsDialog";
import {
  ConnectionEntryDetailed,
  ConnectionTableItem,
  MetadataProject,
} from "../../Schema/models";
import LoadingPanel from "../generic/LoadingPanel";
import { EditConnectionDialog } from "./AddEditConnectionDialog";
import {
  ConnectionActionDialogBaseProps,
  ConnectionItemDialogProps,
  ConnectionsActionsList,
} from "../../Schema/viewModels";
import HistoricalChangesListDialog from "../historicalChanges/HistoricalChangesListDialog";
import { Table, notification } from "web-analysis-lib";
import { Column, Filter } from "web-analysis-lib/dist/ui-kit/Table/Table";
import { AxiosContext } from "../../VpnConnectionsManager/VpnConnectionsManager";
import { AxiosInstance } from "axios";
import { VpnConnectionsAPI } from "../../Schema/api";
import React from "react";
import { Utils } from "../../Utils/utils";

type TableColumnProps = {
  hasAdminPermissions: boolean;
  onEmailDetailsHandler: (entry: ConnectionsTableItem) => void;
  onDetailsHandler: (entry: ConnectionsTableItem) => void;
  onEditHandler: (entry: ConnectionsTableItem) => void;
  onDeleteHandler: (entry: ConnectionsTableItem) => void;
  onHistoricalChangesHandler: (entry: ConnectionsTableItem) => void;
};

export type ConnectionsTableItem = {
  id: string;
  projectName: string;
  companyName: string;
  automationStatus: string;
  connectionStatus: string;
  comments?: string;
};

export type ConnectionsListProps = {
  hasAdminPermissions: boolean;
  projects: MetadataProject[];
  filters: Filter[] | undefined;
  tableItems: ConnectionsTableItem[];
  onConnectionDataChanged: () => void;
};

type ColoredCellProps = {
  text: string;
  color: string;
};

/**
 * Gets a colored cell component
 * @param text the displayed text.
 * @param color the color text.
 * @returns The colored cell component.
 */
const ColoredCell = ({ text, color }: ColoredCellProps) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        margin: "auto",
        backgroundColor: color,
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span className="table-item-text">{text}</span>
    </div>
  );
};

/**
 * Gets the basic columns for the table.
 * @param onDetails Method called when the project action button is clicked.
 * @returns The columns array.
 */
const getTableDataColumns = (
  onDetails: (item: ConnectionsTableItem) => void
): Column[] => {
  let result: Column[] = [
    {
      key: "projectName",
      name: "Project",
      fieldName: "projectName",
      minWidth: 200,
      isSortable: true,
      isPadded: true,
      onRender: (item: ConnectionsTableItem) => (
        <ActionButton
          key={item.id + "projectNameButton"}
          className="table-action-button"
          title={`Show VPN connection details for the ${item.projectName} project`}
          onClick={() => onDetails(item)}
        >
          {item.projectName}
        </ActionButton>
      ),
    },
    {
      key: "companyName",
      name: "Company",
      fieldName: "companyName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "automationStatus",
      name: "Automation Status",
      fieldName: "automationStatus",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "connectionStatus",
      name: "Connection Status",
      fieldName: "connectionStatus",
      minWidth: 120,
      isSortable: true,
      onRender: (item: ConnectionTableItem) => (
        <ColoredCell
          text={item.connectionStatus}
          color={Utils.getConnectionStatusColor(item.connectionStatus)}
        />
      ),
    },
    {
      key: "comments",
      name: "Comments",
      fieldName: "comments",
      minWidth: 300,
      isSortable: false,
      isMultiline: true,
      isPadded: true,
    },
  ];

  return result;
};

/**
 * Gets the connections table columns.
 * @param onEmailDetailsHandler Method called when the email details action button is clicked.
 * @param onDetailsHandler Method called when the details action button is clicked.
 * @param onEditHandler Method called when the edit action button is clicked.
 * @param onDeleteHandler Method called when the delete action button is clicked.
 * @param onHistoricalChangesHandler Method called when the historical changes action button is clicked.
 * @returns
 */
const getTableColumns = ({
  hasAdminPermissions,
  onEmailDetailsHandler,
  onDetailsHandler,
  onEditHandler,
  onDeleteHandler,
  onHistoricalChangesHandler,
}: TableColumnProps): Column[] => {
  let result: Column[] = getTableDataColumns(onDetailsHandler);
  let actionColumns: Column = {
    key: "actions",
    name: "Actions",
    fieldName: "actions",
    minWidth: 150,
    onRender: (item: ConnectionsTableItem) => (
      <Stack horizontal>
        <IconButton
          key={item.id + "customerEmailContacts"}
          className="table-icon-button"
          title="Show customer email contacts"
          ariaLabel="Show customer email contacts"
          iconProps={{
            iconName: "Mail",
          }}
          onClick={() => onEmailDetailsHandler(item)}
        />
        <IconButton
          key={item.id + "edit"}
          className="table-icon-button"
          title="Edit VPN connection"
          ariaLabel="Edit VPN connection"
          iconProps={{
            iconName: "Edit",
          }}
          onClick={() => onEditHandler(item)}
        />
        {hasAdminPermissions && (
          <IconButton
            key={item.id + "delete"}
            className="table-icon-button"
            title="Delete VPN connection"
            ariaLabel="Delete VPN connection"
            iconProps={{
              iconName: "Delete",
            }}
            onClick={() => onDeleteHandler(item)}
          />
        )}
        {hasAdminPermissions && (
          <IconButton
            key={item.id + "historicalChanges"}
            className="table-icon-button"
            title="Show VPN connection details historical changes"
            ariaLabel="Show VPN connection details historical changes"
            iconProps={{
              iconName: "BookAnswers",
            }}
            onClick={() => onHistoricalChangesHandler(item)}
          />
        )}
      </Stack>
    ),
  };

  result.push(actionColumns);
  return result;
};

/**
 * Gets a VPN connection entry details.
 * @param axiosInstance The axios instance.
 * @param id The VPN Connection id.
 * @returns The connection detailed entry if exists. Otherwise, null.
 */
const getItemDetails = async (
  axiosInstance: AxiosInstance,
  id: string
): Promise<ConnectionEntryDetailed | null> => {
  const response = await VpnConnectionsAPI.get(axiosInstance, id);
  if (response.status !== 200) {
    notification.error(
      `Failure getting the VPN connection details: ${response.statusText}.`
    );

    return null;
  }

  return response.data as ConnectionEntryDetailed;
};

/**
 * Gets the item action dialog component, according to an action selection
 * @param item The selected VPN Connections detailed item.
 * @param action The selected action.
 * @param companies The companies list.
 * @param projects The projects list.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The current dialog component. Or null if there is no selected item.
 */
const ItemActionDialog = ({
  item,
  action,
  projects,
  onClose,
}: ConnectionItemDialogProps) => {
  const [selectedItem, setSelectedItem] =
    useState<ConnectionEntryDetailed | null>(item || null);
  const [selectedAction, setSelectedAction] =
    useState<ConnectionsActionsList>(action);

  // Sets the selected item and action.
  useEffect(() => {
    if (!item) {
      return;
    }

    setSelectedItem(item);
    if (action === ConnectionsActionsList.None) {
      return;
    }

    setSelectedAction(action);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  // Selects the dialog.
  let result: JSX.Element | null = null;
  if (!selectedItem || selectedAction === ConnectionsActionsList.None) {
    return result;
  }

  // Function called when the Edit button on the Connection details dialog is clicked.
  const onEditEntryHandler = (data: ConnectionEntryDetailed) => {
    if (!data) {
      return;
    }

    setSelectedItem(data);
    setSelectedAction(ConnectionsActionsList.Edit);
  };

  switch (selectedAction) {
    case ConnectionsActionsList.EmailDetails:
      result = (
        <EmailDetailsDialog
          item={selectedItem}
          projects={projects}
          onClose={onClose}
        />
      );
      break;

    case ConnectionsActionsList.ConnectionDetails:
      result = (
        <ConnectionDetailsDialog
          item={selectedItem}
          projects={projects}
          onEdit={onEditEntryHandler}
          onClose={onClose}
        />
      );
      break;

    case ConnectionsActionsList.Edit:
      result = (
        <EditConnectionDialog
          item={selectedItem}
          projects={projects}
          onClose={onClose}
        />
      );
      break;
  }

  return result;
};

/**
 * Gets the table item action dialog component.
 * @param item The selected connection table item.
 * @param action The selected action.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The table item action dialog component.
 */
const TableItemActionDialog = ({
  item,
  action,
  onClose,
}: ConnectionActionDialogBaseProps<ConnectionTableItem>) => {
  let result: JSX.Element | null = null;
  if (!item) {
    return result;
  }

  switch (action) {
    case ConnectionsActionsList.HistoricalChanges:
      result = <HistoricalChangesListDialog item={item} onClose={onClose} />;
      break;

    case ConnectionsActionsList.Delete:
      result = <DeleteConnectionDialog item={item} onClose={onClose} />;
      break;
  }

  return result;
};

/**
 * Gets the Vpn connection list component
 * @param entries The VPN connections entries list.
 * @returns The VPN connection list component.
 */
const ConnectionsList = ({
  hasAdminPermissions,
  projects,
  filters,
  tableItems,
  onConnectionDataChanged,
}: ConnectionsListProps) => {
  const axiosInstance = useContext(AxiosContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTableItem, setSelectedTableItem] =
    useState<ConnectionTableItem | null>(null);
  const [selectedItem, setSelectedItem] =
    useState<ConnectionEntryDetailed | null>(null);
  const [selectedAction, setSelectedAction] = useState<ConnectionsActionsList>(
    ConnectionsActionsList.None
  );

  const onEmailDetailsHandler = async (entry: ConnectionsTableItem) => {
    setIsLoading(true);
    let item = await getItemDetails(axiosInstance!, entry.id);
    setIsLoading(false);
    if (!item) {
      notification.error(
        "Error getting Email details. Please try again later."
      );
      return;
    }

    setSelectedItem(item);
    setSelectedAction(ConnectionsActionsList.EmailDetails);
  };

  const onDetailsHandler = async (entry: ConnectionsTableItem) => {
    setIsLoading(true);
    let item = await getItemDetails(axiosInstance!, entry.id);
    setIsLoading(false);
    if (!item) {
      notification.error(
        "Error getting connection details. Please try again later."
      );
      return;
    }

    setSelectedItem(item);
    setSelectedAction(ConnectionsActionsList.ConnectionDetails);
  };

  const onDeleteHandler = async (entry: ConnectionsTableItem) => {
    setSelectedTableItem(entry);
    setSelectedAction(ConnectionsActionsList.Delete);
  };

  const onEditHandler = async (entry: ConnectionsTableItem) => {
    setIsLoading(true);
    let item = await getItemDetails(axiosInstance!, entry.id);
    setIsLoading(false);
    if (!item) {
      notification.error(
        "Error getting connection details. Please try again later."
      );
      return;
    }

    setSelectedItem(item);
    setSelectedAction(ConnectionsActionsList.Edit);
  };

  const onHistoricalChangesHandler = (entry: ConnectionsTableItem) => {
    setSelectedTableItem(entry);
    setSelectedAction(ConnectionsActionsList.HistoricalChanges);
  };

  const onCloseHandler = (listChanged?: boolean) => {
    listChanged && onConnectionDataChanged?.();
    setSelectedAction(ConnectionsActionsList.None);
    setSelectedItem(null);
    setSelectedTableItem(null);
  };

  return (
    <Stack style={{ padding: 8 }}>
      <Table
        persistOpts={{
          key: "table-vpn-connections",
          version: 2,
        }}
        header={{ title: "VPN Connections" }}
        hasSelection={false}
        items={tableItems}
        columns={getTableColumns({
          hasAdminPermissions,
          onEmailDetailsHandler,
          onDetailsHandler,
          onEditHandler,
          onDeleteHandler,
          onHistoricalChangesHandler,
        })}
        filters={filters}
      />
      {selectedItem && (
        <ItemActionDialog
          item={selectedItem}
          action={selectedAction}
          projects={projects}
          onClose={onCloseHandler}
        />
      )}
      {selectedTableItem && (
        <TableItemActionDialog
          item={selectedTableItem}
          onClose={onCloseHandler}
          action={selectedAction}
        />
      )}
      {isLoading && <LoadingPanel />}
    </Stack>
  );
};

export default ConnectionsList;
