import EditUserNotificationPage from "../UserNotifications/EditUserNotificationPage";
import Contact from "./Sections/Contact";
import Dashboard from "./Sections/Dashboard";
import Information from "./Sections/Information";
import List from "./Sections/List";
import Tabs from "./Tabs/Tabs";

const Settings = () => {
  const tabs = [
    {
      label: "Account",
      icon: "Contact",
      hasIcon: true,
      content: [
        { label: "Contact", content: <Contact /> },
        { label: "Information", hasIcon: false, content: <Information /> },
        // { label: "Security", hasIcon: false, content: <Security /> },
      ],
    },
    {
      label: "General",
      icon: "Settings",
      hasIcon: true,
      content: [
        { label: "Dashboard", hasIcon: false, content: <Dashboard /> },
        { label: "List", hasIcon: false, content: <List /> },
        // { label: "Notifications", hasIcon: false, content: <Notifications /> },
      ],
    },
    {
      label: "Notifications",
      icon: "Ringer",
      hasIcon: true,
      content: <EditUserNotificationPage />
    },

  ];

  return <Tabs tabs={tabs} hasExtraClass hasHeaderContent />;
};

export default Settings;
