import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { scopesOfRole } from "./api";
import { ResponseRolesOfScopeListItem } from "../models";
import { ScopeLevelType, Status } from "../../../schema/status";
import { RootState } from "../../../store";
import { IGroup, IObjectWithKey } from "@fluentui/react";

export const listAsyncScopesOfRole = createAsyncThunk(
  "scopesOfRole/list",
  async (id: string) => {
    const response = await scopesOfRole(id);
    if (response.length > 0) return response;
    return response;
  }
);

export type CustomItemsType = IObjectWithKey & {
  name: string;
  scopeId: string;
  scopeResourceId: string;
  scope: ScopeLevelType;
};

export interface ScopesOfRolesState {
  scopesOfRole: ResponseRolesOfScopeListItem[];
  items: CustomItemsType[] | undefined;
  groups: IGroup[];
  status: Status;
  error: string;
}

const initialState: ScopesOfRolesState = {
  scopesOfRole: [],
  items: undefined,
  groups: [],
  status: Status.void,
  error: "",
};

export const scopesOfRoleSlice = createSlice({
  name: "scopesOfRole",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncScopesOfRole.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncScopesOfRole.fulfilled, (state, action) => {
        const retrievedValue = action.payload.scopes;
        state.status = Status.idle;
        state.error = "";
        state.scopesOfRole = retrievedValue;
        const groups: IGroup[] = [];
        const items: CustomItemsType[] = [];
        var acumm = 0;
        retrievedValue.forEach((ele: ResponseRolesOfScopeListItem) => {
          groups.push({
            key: ele.scope.id,
            name: ele.scope.type,
            startIndex: acumm,
            count: ele.values.length,
            level: 0,
          });
          acumm += ele.values.length;
          items.push(
            ...ele.values.map((val) => {
              return {
                key: val.scopeResourceId ? val.scopeResourceId : ele.scope.id,
                name: val.description ? val.description : "Root",
                scopeId: ele.scope.id,
                scopeResourceId: val.scopeResourceId,
                scope: ele.scope.type,
              };
            })
          );
        });
        state.groups = groups;
        state.items = items;
      })
      .addCase(listAsyncScopesOfRole.rejected, (state) => {
        state.status = Status.error;
        state.error = "Error loading scopesOfRole. Please try again later.";
      });
  },
});

export const selectScopesOfRole = (state: RootState) =>
  state.scopesOfRole.scopesOfRole;

export const selectScopesOfRoleGroups = (state: RootState) =>
  state.scopesOfRole.groups;

export const selectScopesOfRoleItems = (state: RootState) =>
  state.scopesOfRole.items;

export const selectScopesOfRoleStatus = (state: RootState) =>
  state.scopesOfRole.status;

export const selectScopeScopesOfRole = (id: string) => (state: RootState) => {
  if (
    state.scopesOfRole &&
    state.scopesOfRole.scopesOfRole &&
    state.scopesOfRole.scopesOfRole.length > 0
  ) {
    return state.scopesOfRole.scopesOfRole.find(
      (c: ResponseRolesOfScopeListItem) => c.scope.id === id
    );
  }

  return undefined;
};

export default scopesOfRoleSlice.reducer;
