import {
  DefaultButton,
  DialogFooter,
  PrimaryButton,
  Stack,
} from "@fluentui/react";

type ConfigTextProps = {
  htmlText: JSX.Element;
  onClose: () => void;
  show: boolean;
};

/**
 * Gets the config text component.
 * @param htmlText the configuration text HTML element
 * @param onClose Method called to close/hide this component.
 * @param show Value indicating whether to show this form.
 * @returns The configuration text component.
 */
const ConfigText = ({ show, htmlText, onClose }: ConfigTextProps) => {
  if (!show) {
    return <></>;
  }

  // Handlers
  const onCopyToClipboard = () => {
    let configString = "";
    htmlText.props?.children?.props?.children
      ?.filter((c) => typeof c === "string")
      .forEach((c) => (configString += c.toString() + "\n"));

    navigator.clipboard.writeText(configString);
  };

  return (
    <Stack>
      {htmlText}
      <DialogFooter>
        <PrimaryButton text="Copy to Clipboard" onClick={onCopyToClipboard} />
        <DefaultButton
          text="Done"
          onClick={onClose}
          style={{ border: "none" }}
        />
      </DialogFooter>
    </Stack>
  );
};

export default ConfigText;
