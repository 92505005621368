import { axiosInstance } from "../..";
import { WithOutPermissionsUserExtendedProperties } from "../UsersRBCA/models";

export async function pendingListUsersRBAC(): Promise<
  WithOutPermissionsUserExtendedProperties[] | any
> {
  const response = await axiosInstance.get<any>(
    "/rbac/v1/users/withoutPermissions "
  );
  return response.data;
}
