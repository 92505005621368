import ImagesPivotContainer from "../Generic/ImagesPivotContainer";

type ParkedImagesListProps = {
  hasSearchPermissions: boolean;
};

const ParkedImagesList = ({ hasSearchPermissions }: ParkedImagesListProps) => {
  return (
    <ImagesPivotContainer
      title={"Parked Images"}
      hasPermissions={hasSearchPermissions}
      commandBarItemProps={[]}
      handleSearch={(_) => {}}
    ></ImagesPivotContainer>
  );
};

export default ParkedImagesList;
