export enum PowerBIEmbedItemParentType {
  Corporation = "Corporation",
  Company = "Company",
  Project = "Project",
  Machine = "Machine",
}

export interface MinimalPowerBIEmbedItem {
  parentId: string;
  parentType: string;
}
export interface PowerBIEmbedItem extends MinimalPowerBIEmbedItem {
  groupId: string;
  id: string;
}

export interface RequestPowerBIAddOrUpdate extends PowerBIEmbedItem {
  parentType: string;
}

export interface ResponsePowerBIGet {
  embedToken: string;
  embedUrl: string;
  id: string;
  parentId: string;
  groupId: string;
}

export interface PowerBIEmbedItemToShow extends PowerBIEmbedItem {
  name: string | undefined;
}

export interface DBoarToShow {
  corporationName: string;
  corporationId: string;
  dashBoards: PowerBIEmbedItemToShow[];
}

export interface DashBoardToList {
  parentType: string;
  parentName: string;
  parentId: string;
  groupId: string;
  id: string;
}

export type AddEditDashboardFormData = {
  parentType?: string;
  parentId?: string;
  id?: string;
  groupId?: string;
};
