import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../schema/status";
import { RootState } from "../../store";
import { WirelessGatewayResponse } from "../Gateways/models";
import { ResponseMachineDetails } from "../Machines/models";
import { list } from "./api";
import { WirelessSensorNode, WirelessSensorNodeResponse } from "./models";

export const listAsyncSensorNodes = createAsyncThunk(
  "sensorNodes/list",
  async (wirelessAndMachs: {
    wireless: WirelessGatewayResponse[];
    machs: ResponseMachineDetails[];
  }) => {
    const response: WirelessSensorNode[] = await list();

    if (response.length > 0) {
      const responseVar: WirelessSensorNodeResponse[] = response.map((sen) => {
        const wirelessAux = wirelessAndMachs.wireless.find(
          (tre) => tre.id === sen.wirelessGatewayId
        )!;
        const mach = wirelessAndMachs.machs.find(
          (mach) => mach.id === sen.machineId
        )!;
        return {
          ...sen,
          projectId: mach?.projectId,
          project: mach?.project.name,
          company: mach?.company.name,
          corporation: mach?.corporation.name,
          wirelessGateway: wirelessAux?.serialNumber,
          machine: mach?.dalogId,
        };
      });

      return responseVar.sort((a, b) =>
        a.sensorNodeId > b.sensorNodeId ? 1 : -1
      );
    } else return [];
  }
);

export interface WirelessSensorNodesState {
  sensorNodes: WirelessSensorNodeResponse[];
  status: Status;
  error: string;
}

const initialState: WirelessSensorNodesState = {
  sensorNodes: [],
  status: Status.void,
  error: "",
};

export const wirelessSensorNodesSlice = createSlice({
  name: "wirelessSensorNodes",
  initialState,
  reducers: {
    resetState: (state: WirelessSensorNodesState) => {
      state.status = Status.void;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncSensorNodes.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncSensorNodes.fulfilled, (state, action) => {
        state.status = Status.idle;
        state.error = "";
        state.sensorNodes = action.payload!;
      })
      .addCase(listAsyncSensorNodes.rejected, (state) => {
        state.status = Status.error;
        state.error = "Error loading sensorNodes. Please try again later.";
      });
  },
});

export const selectWirelessSensorNodes = (state: RootState) =>
  state.wirelessSensorNode.sensorNodes;

export const selectWirelessSensorNodesStatus = (state: RootState) =>
  state.wirelessSensorNode.status;

export const selectWirelessSensorNode = (id: string) => (state: RootState) => {
  if (
    state.wirelessSensorNode &&
    state.wirelessSensorNode.sensorNodes &&
    state.wirelessSensorNode.sensorNodes.length > 0
  ) {
    return state.wirelessSensorNode.sensorNodes.find(
      (c: WirelessSensorNodeResponse) => c.id === id
    );
  }

  return undefined;
};

export const { resetState } = wirelessSensorNodesSlice.actions;
export default wirelessSensorNodesSlice.reducer;
