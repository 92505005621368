import { useMemo, useState, useEffect } from "react";
import {
  BaseDialog,
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "web-analysis-lib";
import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
} from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import {
  maxLengthType1,
  maxLengthType2,
  textStyle,
} from "../../../../schema/Constants";
import {
  CreateFrequencyCatalogueItem,
  FrequencyCatalogueDbEntry,
} from "./models";
import {
  addFrequencyCatalogueItem,
  deleteFrequencyCatalogueItem,
  editFrequencyCatalogueItem,
} from "./api";

const getSchema = (context: "add" | "edit" | "delete" | "addWithGroup") =>
  z
    .object({
      groupName: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Name must contain at most ${maxLengthType2} character(s)`,
        }),
      itemName: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Name must contain at most ${maxLengthType2} character(s)`,
        }),
      frequencyInHz: z.string().optional(),
      description: z
        .string()
        .max(maxLengthType1, {
          message: `Name must contain at most ${maxLengthType1} character(s)`,
        })
        .optional(),
    })
    .refine(
      (input) => {
        if (!input.frequencyInHz) {
          return true;
        }
        var regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);
        return valid.test(input.frequencyInHz);
      },
      {
        path: ["frequencyInHz"],
        message: "Try a number.",
      }
    );

function getFields(isDisabled: boolean): FormItemProps[] {
  return [
    {
      name: "groupName",
      type: FormItemType.TextField,
      groupProps: { label: "Group Name" },
      disabled: isDisabled,
    },
    {
      name: "itemName",
      type: FormItemType.TextField,
      groupProps: { label: "Item Name" },
    },
    {
      name: "frequencyInHz",
      type: FormItemType.TextField,
      groupProps: { label: "Frequency In Hz" },
    },
    {
      name: "description",
      type: FormItemType.TextField,
      groupProps: { label: "Description" },
    },
  ];
}

type AddOrEditDialogProps = IDialogProps & {
  machineId: string;
  data: undefined | string | FrequencyCatalogueDbEntry;
  show: boolean;
  onSuccess: (
    hasError: boolean,
    context: "add" | "edit" | "delete" | "addWithGroup"
  ) => void;
  onClose: () => void;
  context: "add" | "edit" | "delete" | "addWithGroup";
};

export const CUDDialog = ({
  machineId,
  data,
  show,
  onSuccess,
  onClose,
  context,
  ...rest
}: AddOrEditDialogProps) => {
  const schema = useMemo(() => getSchema(context), [context]);

  const [isLoading, setLoading] = useState(false);
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && data && typeof data !== "string"
      ? {
          defaultValues: {
            groupName: data.groupName,
            itemName: data.itemName,
            frequencyInHz: data.frequencyInHz.toString(),
            description: data.description,
          },
        }
      : {
          defaultValues: {
            groupName: data as string,
          },
        }),
  });

  useEffect(() => {
    data && typeof data !== "string"
      ? reset({
          groupName: data?.groupName,
          itemName: data?.itemName,
          frequencyInHz: data?.frequencyInHz.toString(),
          description: data?.description || "",
        })
      : typeof data === "string"
      ? reset({
          groupName: data,
          itemName: "",
          frequencyInHz: "0.0",
          description: "",
        })
      : reset({
          groupName: "",
          itemName: "",
          frequencyInHz: "0.0",
          description: "",
        });
  }, [data, reset]);

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    const toSend: CreateFrequencyCatalogueItem = {
      groupName: formData.groupName,
      itemName: formData.itemName,
      frequencyInHz: Number(formData.frequencyInHz) as number,
      description: formData.description ?? "",
    };

    switch (context) {
      case "add":
        await addFrequencyCatalogueItem(machineId, toSend).then((response) =>
          onSuccess("status" in response, context)
        );
        break;
      case "addWithGroup":
        await addFrequencyCatalogueItem(machineId, toSend).then((response) =>
          onSuccess("status" in response, context)
        );
        break;
      case "edit":
        await editFrequencyCatalogueItem(
          typeof data !== "string" && "id" in data ? data.id : "",
          machineId,
          typeof data !== "string" && "id" in data && { id: data.id, ...toSend }
        ).then((response) => onSuccess("status" in response, context));
        break;
      case "delete":
        await deleteFrequencyCatalogueItem(
          typeof data === "string" && data,
          machineId
        ).then((response) => onSuccess("status" in response, context));
        break;
    }

    handleClose();
  });

  const handleDelete = async () => {
    setLoading(true);

    await deleteFrequencyCatalogueItem(
      typeof data === "string" && data,
      machineId
    ).then((response) => onSuccess("status" in response, context));

    handleClose();
  };

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <>
      {context && (
        <BaseDialog
          {...rest}
          hidden={!show}
          dialogContentProps={{
            type: DialogType.normal,
            title:
              context === "addWithGroup"
                ? "Add a new item to a new group "
                : context.charAt(0).toUpperCase() +
                  context.slice(1) +
                  " Frequency Catalogue Item",
            closeButtonAriaLabel: "Close",
            onDismiss: handleClose,
          }}
        >
          {["add", "edit", "addWithGroup"].includes(context) ? (
            <form onSubmit={onSubmit}>
              {renderFormItems(getFields(context === "add"), {
                control,
                errors: errors as { [schemaProp: string]: FieldError },
              })}
              <DialogFooter>
                <PrimaryButton
                  type="submit"
                  text="Save Changes"
                  disabled={isLoading || !isValid}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                />
                <DefaultButton
                  styles={{
                    root: { border: "unset", background: "transparent" },
                  }}
                  text="Cancel"
                  onClick={handleClose}
                />
              </DialogFooter>
            </form>
          ) : ["delete"].includes(context) ? (
            <>
              <div style={textStyle}>
                You are about to delete selected Frequency Catalogue Item.
              </div>

              <DialogFooter>
                <PrimaryButton
                  text="Delete"
                  disabled={isLoading}
                  onClick={handleDelete}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                />
                <DefaultButton text="Close" onClick={handleClose} />
              </DialogFooter>
            </>
          ) : (
            <></>
          )}
        </BaseDialog>
      )}
    </>
  );
};
