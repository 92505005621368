import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../schema/status";
import { settingsUsersRBAC } from "./api";

export const DEFAULT_ROWS_PER_TABLE = 25;
export const DEFAULT_GRID_ITEMS_COUNT = 6

export const defaultDashboardSettings = {
  worldMap: true,
  dashboards: false,
  machines: true,
  bookmarks: false,
  tasks: true
}

export const getDefaultListSettings = () => {
  const rowsPerTable = JSON.parse(localStorage.getItem('rows-per-page') || `${DEFAULT_ROWS_PER_TABLE}`);
  
  return { rowsPerTable, gridItemsCount: DEFAULT_GRID_ITEMS_COUNT }
}

export const listAsyncSettingsUsersRBAC = createAsyncThunk(
  "settingsUsersRBAC/list",
  async (id: string) => {
    let response = await settingsUsersRBAC(id);

    const isEmptyDashboardSettings = Object.keys(response?.dashboardSettings || []).length === 0;
    const isEmptyListSettings = Object.keys(response?.list || []).length === 0;

    if(isEmptyDashboardSettings) {
      response = {
        ...response,
        dashboardSettings: defaultDashboardSettings,
      }
    }

    if(isEmptyListSettings){
      response = {
        ...response,
        list: getDefaultListSettings()
      }
    }

    if(response?.list?.rowsPerTable){
      localStorage.setItem('rows-per-page', response?.list?.rowsPerTable || DEFAULT_ROWS_PER_TABLE);
    }

    return response;
  }
);

const initialState = {
  response: {
    bookmarks: [],
    dashboardSettings: defaultDashboardSettings,
    list: getDefaultListSettings(),
  },
  status: Status.void,
  error: "",
};

export const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncSettingsUsersRBAC.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncSettingsUsersRBAC.fulfilled, (state, action) => {
        if (action.payload === undefined) {
          state.status = Status.error;
          state.error = "Error loading settings list RBAC. Please try again later.";
        } 
        
        if(action?.payload) {
          state.response = action?.payload
        } 
      })
      .addCase(listAsyncSettingsUsersRBAC.rejected, (state) => {
        state.status = Status.error;
        state.error =
          "Error loading settings list RBAC. Please try again later.";
      });
  },
});

export default userSettingsSlice.reducer;
