import {
  DialogFooter,
  PrimaryButton,
  Spinner,
  DefaultButton,
  DialogType,
  SpinnerSize,
} from "@fluentui/react";
import {
  BaseDialog,
  FormItemProps,
  FormItemType,
  notification,
  renderFormItems,
  useZodForm,
} from "web-analysis-lib";
import { z } from "zod";
import {
  ResponseSimplifiedSignal,
  StandstillSettings,
  StandstillSignal,
} from "../models";
import { useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { MachineStandstillSettingsAddOrUpdate } from "./api";
import {
  ComboBoxCustomV1,
  propsCustomComboBoxV1,
} from "../../Generic/ComboBoxV1";
import { format } from "../../../schema/Utils";

const getSchema = () =>
  z
    .object({
      thresholdHigh1: z.string().optional().nullable(),
      thresholdLow1: z.string().optional().nullable(),
      thresholdHigh2: z.string().optional().nullable(),
      thresholdLow2: z.string().optional().nullable(),
    })
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);

        if (input.thresholdHigh1) {
          return valid.test(input.thresholdHigh1);
        } else return true;
      },
      {
        path: ["thresholdHigh1"],
        message: "Try a decimal number",
      }
    )
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);
        if (input.thresholdLow1) {
          return valid.test(input.thresholdLow1);
        } else return true;
      },
      {
        path: ["thresholdLow1"],
        message: "Try a decimal number",
      }
    )
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);
        if (input.thresholdHigh2) {
          return valid.test(input.thresholdHigh2);
        } else return true;
      },
      {
        path: ["thresholdHigh2"],
        message: "Try a decimal number",
      }
    )
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);
        if (input.thresholdLow2) {
          return valid.test(input.thresholdLow2);
        } else return true;
      },
      {
        path: ["thresholdLow2"],
        message: "Try a decimal number",
      }
    );
type EditDialogProps = {
  standstillSettings: StandstillSettings;
  signals: ResponseSimplifiedSignal[];
  show: boolean;
  onSuccess: () => void;
  onClose: () => void;
  machineId: string;
};

export const EditStandstill = ({
  standstillSettings,
  signals,
  show,
  onSuccess,
  onClose,
  machineId,
}: EditDialogProps) => {
  const [isLoading, setLoading] = useState(false);
  const [idSelected, setIdSelected] = useState<string>(
    standstillSettings?.primary ? standstillSettings?.primary.signalId : ""
  );

  const [idSelected2, setIdSelected2] = useState<string>(
    standstillSettings?.secondary ? standstillSettings?.secondary.signalId : ""
  );

  const schema = useMemo(() => getSchema(), []);
  const thresholdFields: FormItemProps[] = [
    {
      name: "thresholdHigh1",
      type: FormItemType.TextField,
      groupProps: { label: "High" },
    },
    {
      name: "thresholdLow1",
      type: FormItemType.TextField,
      groupProps: { label: "Low" },
    },
    {
      name: "thresholdHigh2",
      type: FormItemType.TextField,
      groupProps: { label: "High" },
    },
    {
      name: "thresholdLow2",
      type: FormItemType.TextField,
      groupProps: { label: "Low" },
    },
  ];

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!standstillSettings && {
      defaultValues: {
        thresholdHigh1: standstillSettings?.primary?.thresholdHigh
          ? standstillSettings?.primary?.thresholdHigh.toString()
          : "",
        thresholdLow1: standstillSettings?.primary?.thresholdLow
          ? standstillSettings?.primary?.thresholdLow.toString()
          : "",
        thresholdHigh2: standstillSettings?.secondary?.thresholdHigh
          ? standstillSettings?.secondary?.thresholdHigh.toString()
          : "",
        thresholdLow2: standstillSettings?.secondary?.thresholdLow
          ? standstillSettings?.secondary?.thresholdLow.toString()
          : "",
      },
    }),
  });

  const fieldAll = renderFormItems(thresholdFields, {
    control,
    errors: errors as { [schemaProp: string]: FieldError },
  });

  const argsIdField: propsCustomComboBoxV1 = {
    keyMe: 30,
    label: "Select Primary Signal * ",
    options: signals
      ?.map((sig) => ({
        key: sig.id,
        text:
          sig.name +
          " (" +
          format(new Date(sig.dataFrom)) +
          ", " +
          format(new Date(sig.dataUntil)) +
          ")",
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1)),
    selection: setIdSelected,
    errorMessage: "",
    defaultKey: idSelected,
  };

  const textFComp = ComboBoxCustomV1(argsIdField);

  const argsIdField1: propsCustomComboBoxV1 = {
    keyMe: 20,
    label: "Select Secondary Signal",
    options: signals
      ?.map((sig) => ({
        key: sig.id,
        text:
          sig.name +
          " (SS No: " +
          sig.sensorSerialNo +
          ") (" +
          format(new Date(sig.dataFrom)) +
          ", " +
          format(new Date(sig.dataUntil)) +
          ") ",
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1)),
    selection: setIdSelected2,
    errorMessage: "",
    defaultKey: idSelected2,
  };

  const textFComp1 = ComboBoxCustomV1(argsIdField1);

  const getStandstillThreshold = (value: string): number | undefined => {
    let result: number | undefined = undefined;
    let text = value?.trim();
    if (text && typeof parseFloat(text) === "number") {
      result = Number(text);
    }

    return result;
  };

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    var primarySignal: StandstillSignal = {
      signalId: idSelected,
      thresholdHigh: getStandstillThreshold(formData.thresholdHigh1),
      thresholdLow: getStandstillThreshold(formData.thresholdLow1),
    };

    var secondarySignal: StandstillSignal = {
      signalId: idSelected2,
      thresholdHigh: getStandstillThreshold(formData.thresholdHigh2),
      thresholdLow: getStandstillThreshold(formData.thresholdLow2),
    };

    const dataToUpdate: StandstillSettings = {
      primary: idSelected ? primarySignal : null,
      secondary: idSelected2 ? secondarySignal : null,
    };

    await MachineStandstillSettingsAddOrUpdate(machineId, dataToUpdate).then(
      (response) => {
        response["status"] >= 200 && response["status"] < 300
          ? onSuccess()
          : response["text"]
          ? notification.error(response["text"])
          : notification.error("Something went wrong.");
      }
    );
    handleClose();
  });

  const handleClose = () => {
    // reset state
    setLoading(false);

    onClose?.();
  };

  return (
    <>
      <BaseDialog
        hidden={!show}
        dialogContentProps={{
          type: DialogType.normal,
          title: standstillSettings ? "Update" : "Add",
          closeButtonAriaLabel: "Close",
          onDismiss: handleClose,
        }}
      >
        <form onSubmit={onSubmit}>
          {textFComp}
          {fieldAll.at(0)}
          {fieldAll.at(1)}
          {textFComp1}
          {fieldAll.at(2)}
          {fieldAll.at(3)}
          <DialogFooter>
            <PrimaryButton
              type="submit"
              text="Save Changes"
              disabled={isLoading}
              onRenderIcon={() =>
                isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
              }
            />
            <DefaultButton
              styles={{
                root: { border: "unset", background: "transparent" },
              }}
              text="Cancel"
              onClick={() => {
                handleClose();
              }}
            />
          </DialogFooter>
        </form>
      </BaseDialog>
    </>
  );
};
