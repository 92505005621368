import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import companiesReducer from "./Components/Companies/reducer";
import corporationsReducer from "./Components/Corporations/reducer";
import projectsReducer from "./Components/Projects/reducer";
import machinesReducer from "./Components/Machines/reducer";
import errorsReducer from "./schema/reducer";
import corporationDetailsReducer from "./Components/Corporations/CorporationDetails/reducer";
import companyDetailsReducer from "./Components/Companies/CompaniesDetails/reducer";
import projectDetailsReducer from "./Components/Projects/ProjectsDetails/reducer";
import machineDetailsReducer from "./Components/Machines/MachineDetails/reducer";
import wirelessGatewayReducer from "./Components/Gateways/reducer";
import wirelessGatewayDetailsReducer from "./Components/Gateways/WirelessGatewaysDetails/reducer";
import wirelessSensorNodeReducer from "./Components/SensorNodes/reducer";
import wirelessSensorNodeReducerDetails from "./Components/SensorNodes/WirelessSensorNodeDetails/reducer";
import dashboardsReducer from "./Components/DashboardsMetadata/reducer";
import permissionsReducer from "./Components/Permissions/reducer";
import rolesReducer from "./Components/Roles/reducer";
import scopesOfRoleReducer from "./Components/Roles/ScopesOfRole/reducer";
import serviceAccountReducer from "./Components/ServiceAccount/reducer";
import serviceAccountProductsReducer from "./Components/ServiceAccount/reducerProducts";
import usersRBACReducer from "./Components/UsersRBCA/reducer";
import usersPendingRBACReducer from "./Components/UsersPendingRBAC/reducer";
import {
  dataloggers325Reducer,
  dataloggers555Reducer,
  dataloggers650Reducer,
  dataloggers850EcoReducer,
  dataloggers850Reducer,
} from "./Components/Dataloggers/reducer";
import userSettingsReducer from "./Components/Settings/reducer";
import userAccountReducer from "./Components/UserAccount/reducer";

export const store = configureStore({
  reducer: {
    corporations: corporationsReducer,
    corporationDetails: corporationDetailsReducer,
    companies: companiesReducer,
    companyDetails: companyDetailsReducer,
    projects: projectsReducer,
    projectDetails: projectDetailsReducer,
    machines: machinesReducer,
    machineDetails: machineDetailsReducer,
    errorS: errorsReducer,
    wirelessGateway: wirelessGatewayReducer,
    wirelessGatewayDetails: wirelessGatewayDetailsReducer,
    wirelessSensorNode: wirelessSensorNodeReducer,
    wirelessSensorNodeDetails: wirelessSensorNodeReducerDetails,
    dashboards: dashboardsReducer,
    permissions: permissionsReducer,
    roles: rolesReducer,
    scopesOfRole: scopesOfRoleReducer,
    serviceAccounts: serviceAccountReducer,
    serviceAccountsProducts: serviceAccountProductsReducer,
    usersRBAC: usersRBACReducer,
    usersPendingRBAC: usersPendingRBACReducer,
    dataloggers325: dataloggers325Reducer,
    dataloggers555: dataloggers555Reducer,
    dataloggers650: dataloggers650Reducer,
    dataloggers850Eco: dataloggers850EcoReducer,
    dataloggers850: dataloggers850Reducer,
    userSettings: userSettingsReducer,
    userAccount: userAccountReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
