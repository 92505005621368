import {
  D325,
  D850,
  D850Eco,
  DBasic,
  DModels,
  ServiceAccountDdpConfigItem,
} from "./models";
import { axiosInstance } from "../..";
import { AxiosResponse } from "axios";
import { Utils } from "./utils";

type DataloggerApiResponse = {
  data: any;
  status: number;
  statusText: string;
};

/**
 * * The dataloggers API class.
 */
export class DataloggersAPI {
  // Gets the dataloggers list.
  static list = async (model: DModels): Promise<DataloggerApiResponse> => {
    let response: AxiosResponse<D325[] | DBasic[] | D850Eco[] | D850[], any>;
    let result: DataloggerApiResponse = {
      data: null,
      status: 0,
      statusText: "",
    };

    switch (model) {
      case DModels.D325:
        response = await axiosInstance.get<D325[]>("/dataloggers/v1/d325");
        break;
      case DModels.D555:
        response = await axiosInstance.get<DBasic[]>("/dataloggers/v1/d555");
        break;
      case DModels.D650:
        response = await axiosInstance.get<DBasic[]>("/dataloggers/v1/d650");
        break;
      case DModels.D850Eco:
        response = await axiosInstance.get<D850Eco[]>(
          "/dataloggers/v1/d850Eco"
        );
        break;
      case DModels.D850:
        response = await axiosInstance.get<D850[]>("/dataloggers/v1/d850");
        break;
    }

    result = {
      data: response.data,
      status: response.status,
      statusText: response.statusText,
    };

    return result;
  };

  // Creates a new datalogger item
  static create = async (
    model: DModels,
    entry: D325 | DBasic | D850Eco | D850
  ): Promise<DataloggerApiResponse> => {
    let url: string = "";
    let result: DataloggerApiResponse = {
      data: null,
      status: 0,
      statusText: "",
    };

    switch (model) {
      case DModels.D325:
        url = "/dataloggers/v1/d325/";
        break;
      case DModels.D555:
        url = "/dataloggers/v1/d555/";
        break;
      case DModels.D650:
        url = "/dataloggers/v1/d650/";
        break;
      case DModels.D850Eco:
        url = "/dataloggers/v1/d850Eco/";
        break;
      case DModels.D850:
        url = "/dataloggers/v1/d850/";
        break;
    }

    // Sends the request
    const response = await axiosInstance.post<D325 | DBasic | D850Eco | D850>(
      url,
      entry
    );

    result = {
      status: response.status,
      statusText:
        response.status === 201
          ? `Success: Creating datalogger new entry for the project with Id ${entry.projectId}.`
          : `$Error ${response.status}. Failure creating datalogger new entry for the project with Id ${entry.projectId}.`,
      data: response.status === 201 ? response.data : null,
    };

    return result;
  };

  // Gets a specific datalogger
  static get = async (
    model: DModels,
    id: string
  ): Promise<DataloggerApiResponse> => {
    let url: string = "";
    let result: DataloggerApiResponse = {
      data: null,
      status: 0,
      statusText: "",
    };

    switch (model) {
      case DModels.D325:
        url = `/dataloggers/v1/d325/${id}`;
        break;
      case DModels.D555:
        url = `/dataloggers/v1/d555/${id}`;
        break;
      case DModels.D650:
        url = `/dataloggers/v1/d650/${id}`;
        break;
      case DModels.D850Eco:
        url = `/dataloggers/v1/d850Eco/${id}`;
        break;
      case DModels.D850:
        url = `/dataloggers/v1/d850/${id}`;
        break;
    }

    // Sends the request
    const response = await axiosInstance.get<D325 | DBasic | D850Eco | D850>(
      url
    );

    result = {
      status: response.status,
      statusText:
        response.status === 200
          ? `Success: Getting a datalogger entry with Id ${id}`
          : `$Error ${response.status}. Failure getting datalogger with Id ${id}.`,
      data: response.status === 200 ? response.data : null,
    };

    return result;
  };

  // Updates a specific datalogger.
  static update = async (
    model: DModels,
    entry: D325 | DBasic | D850Eco | D850
  ): Promise<DataloggerApiResponse> => {
    let url: string = "";
    let result: DataloggerApiResponse = {
      data: null,
      status: 0,
      statusText: "",
    };

    switch (model) {
      case DModels.D325:
        url = `/dataloggers/v1/d325/${entry.id}`;
        break;
      case DModels.D555:
        url = `/dataloggers/v1/d555/${entry.id}`;
        break;
      case DModels.D650:
        url = `/dataloggers/v1/d650/${entry.id}`;
        break;
      case DModels.D850Eco:
        url = `/dataloggers/v1/d850Eco/${entry.id}`;
        break;
      case DModels.D850:
        url = `/dataloggers/v1/d850/${entry.id}`;
        break;
    }

    // Sends the request
    const response = await axiosInstance.put<D325 | DBasic | D850Eco | D850>(
      url,
      entry
    );

    result = {
      status: response.status,
      statusText:
        response.status === 200
          ? `Success: Updating datalogger entry with Id ${entry.id}`
          : `$Error ${response.status}. Failure updating datalogger with Id ${entry.id}.`,
      data: response.status === 200 ? response.data : null,
    };

    return result;
  };

  // Deletes a datalogger.
  static delete = async (
    model: DModels,
    id: string
  ): Promise<DataloggerApiResponse> => {
    let url: string = "";
    let result: DataloggerApiResponse = {
      data: null,
      status: 0,
      statusText: "",
    };

    switch (model) {
      case DModels.D325:
        url = `/dataloggers/v1/d325/${id}`;
        break;
      case DModels.D555:
        url = `/dataloggers/v1/d555/${id}`;
        break;
      case DModels.D650:
        url = `/dataloggers/v1/d650/${id}`;
        break;
      case DModels.D850Eco:
        url = `/dataloggers/v1/d850Eco/${id}`;
        break;
      case DModels.D850:
        url = `/dataloggers/v1/d850/${id}`;
        break;
    }

    // Sends the request
    const response = await axiosInstance.delete<D325 | DBasic | D850Eco | D850>(
      url
    );

    result = {
      status: response.status,
      statusText:
        response.status === 200
          ? `Success: Deleting datalogger entry with Id ${id}`
          : `$Error ${response.status}. Failure deleting datalogger with Id ${id}.`,
      data: response.status === 200 ? response.data : null,
    };

    return result;
  };
}

const mockRequest = async (data: any): Promise<DataloggerApiResponse> => {
  let result: DataloggerApiResponse = {
    status: 0,
    statusText: "",
    data: null,
  };

  await Utils.sleep(1000);
  let responseStatus = Math.random();
  if (responseStatus >= 0.8) {
    result.status = 400;
    result.statusText = "Error sending request.";
  } else {
    result = {
      status: 200,
      statusText: "Request successful.",
      data: data,
    };
  }

  return result;
};

export class MockServiceAccountsAPI {
  // Lists the mock services accounts with enough permissions.
  static list = async (): Promise<DataloggerApiResponse> => {
    let data: ServiceAccountDdpConfigItem[] = [];
    for (let i = 0; i < 6; i++) {
      data.push({
        name: `Service account ${i + 1}`,
        apiKey: `ApiKey ${i + 1}`,
      });
    }

    let result = await mockRequest(data);
    return result;
  };
}
