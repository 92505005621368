import { Stack } from "@fluentui/react";
import { PropsWithChildren, CSSProperties } from "react";
import { Text } from "@fluentui/react/lib/Text";

type FormItemRowProps = React.HTMLAttributes<HTMLElement> & {
  label: string;
};

/**
 * Gets a form item row component
 * @param label The label text.
 * @param children The input element(s)
 * @returns The form item row component.
 */
const FormItemRow = ({
  label,
  children,
  ...rest
}: PropsWithChildren<FormItemRowProps>) => {
  const containerStyle: CSSProperties = {
    marginTop: "8px",
    marginBottom: "8px",
  };

  const labelStyle: CSSProperties = {
    minWidth: "160px",
    marginRight: "8px",
  };

  const inputElementStyle: CSSProperties = { width: "100%" };

  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
      style={{ ...containerStyle, ...rest.style }}
    >
      <Stack.Item style={labelStyle}>
        <Text variant="medium">{label}</Text>
      </Stack.Item>
      <Stack.Item style={inputElementStyle}>{children}</Stack.Item>
    </Stack>
  );
};

export default FormItemRow;
