import {
  DefaultButton,
  IStackTokens,
  PrimaryButton,
  Stack,
  StackItem,
  Pivot,
  PivotItem,
  TextField,
  ScrollablePane,
  ScrollbarVisibility,
  TooltipHost,
  Checkbox,
  Text,
  DialogType,
  IDialogProps,
  DialogFooter,
} from "@fluentui/react";
import { Icon as IconF } from "@fluentui/react";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import { Status } from "../../schema/status";
import { RequestRolesAddMany } from "./models";
import {
  listAsyncUsersRBAC,
  selectUsersPlain,
  selectUsersRBACPlain,
  selectUsersRBACStatus,
} from "../UsersRBCA/reducer";
import {
  BaseDialog,
  Column,
  DialogSize,
  Table,
  notification,
} from "web-analysis-lib";
import { UserSimple } from "../UsersRBCA/models";
import {
  listAsyncServiceAccount,
  selectServiceAccountsDdpProperties,
  selectServiceAccountsStatus,
} from "../ServiceAccount/reducer";
import { addManyPermissionsApi } from "./api";
import { ServiceAccountAddRole } from "../ServiceAccount/models";
import { iconStyle } from "../../schema/Constants";
import { ServiceAccountAddRoleToShow } from "../ServiceAccount/AddDialog";
import { RolesComponentToAdd } from "../Roles/RolesComponentToAdd";

const titleStyle: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  borderRight: "1px solid #E1DFDD",
  paddingRight: 24,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "auto",
};

const title2Style: CSSProperties = {
  fontSize: 14,
  fontWeight: 600,
  borderRight: "1px solid #E1DFDD",
  padding: 24,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "auto",
};

const stackTokens: IStackTokens = {
  childrenGap: 20,
};

export interface CheckOptionsUsersOrServices extends UserSimple {
  type: string;
}

type AddDialogProps = IDialogProps & {
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const AddDialog = ({
  show,
  onClose,
  onSuccess,
  ...rest
}: AddDialogProps) => {
  const [rolesToAdd, setRolesToAdd] = useState<ServiceAccountAddRoleToShow[]>(
    []
  );

  const dispatch = useAppDispatch();
  const [selectToggle, setSelectToggle] = useState<
    "user" | "service" | "table"
  >("user");
  const [selectedUsersIds, setSelectedUsersIds] = useState<string[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<
    CheckOptionsUsersOrServices[]
  >([]);

  const [addManyPermissions, setAddManyPermissions] =
    useState<RequestRolesAddMany>();

  const usersPlain = useAppSelector(selectUsersPlain);
  const usersRBACPlain = useAppSelector(selectUsersRBACPlain);
  const usersStatus = useAppSelector(selectUsersRBACStatus);

  const serviceAccounts = useAppSelector(selectServiceAccountsDdpProperties);
  const serviceAccountsStatus = useAppSelector(selectServiceAccountsStatus);
  const [filteringTextUser, setFilteringTextUser] = useState("");

  useEffect(() => {
    setAddManyPermissions({
      ...addManyPermissions,
      roles: rolesToAdd as ServiceAccountAddRole[],
    });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesToAdd]);

  useEffect(() => {
    if (usersStatus === Status.void) dispatch(listAsyncUsersRBAC());
    if (serviceAccountsStatus === Status.void)
      dispatch(listAsyncServiceAccount());
    return () => {};
  }, [dispatch, serviceAccountsStatus, usersStatus]);

  const onDelete = (roles: ServiceAccountAddRoleToShow) => {
    setRolesToAdd(
      rolesToAdd.filter(
        (rol) =>
          rol.roleId !== roles.roleId ||
          rol.scopeLevelId !== roles.scopeLevelId ||
          rol.scopeResourceId !== roles.scopeResourceId
      )
    );
  };

  const handleChangeUserFilter = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const userValue = (e.target as HTMLInputElement).value;
    setFilteringTextUser(userValue.toLocaleLowerCase());
  };

  const membersPivot = useCallback(() => {
    const onChangeCheckbox = (
      ev,
      checked: boolean,
      id: string,
      type: string
    ) => {
      if (checked) {
        setSelectedUsersIds([...selectedUsersIds, id]);
        setAddManyPermissions({
          ...addManyPermissions,
          memberAndOrServiceAccountIds: [...selectedUsersIds, id],
        });

        setSelectedMembers([
          ...selectedMembers,
          type === "User"
            ? { ...usersPlain.find((mem) => mem.id === id), type: type }
            : {
                id: serviceAccounts.find((mem) => mem.memberId === id).memberId,
                name: serviceAccounts.find((mem) => mem.memberId === id)
                  .displayName,
                type: type,
              },
        ]);
      } else {
        setSelectedUsersIds((prev) => prev.filter((x) => x !== id));
        setAddManyPermissions({
          ...addManyPermissions,
          memberAndOrServiceAccountIds: selectedUsersIds.filter(
            (x) => x !== id
          ),
        });

        setSelectedMembers(selectedMembers.filter((mem) => mem.id !== id));
      }
    };
    const getColumnsMembers = () => {
      const columns: Column[] = [
        {
          key: "name",
          name: "Name",
          fieldName: "name",
          minWidth: 130,
          isSortable: true,
        },
        {
          key: "type",
          name: "Type",
          fieldName: "type",
          minWidth: 130,
          isSortable: true,
        },
        {
          key: "id",
          name: "Id",
          fieldName: "id",
          minWidth: 250,
          isSortable: true,
        },
        {
          key: "typeIcon",
          name: "",
          fieldName: "typeIcon",
          minWidth: 50,
          isSortable: false,
          isExportable: false,
          onRender: (userOrService: CheckOptionsUsersOrServices) => (
            <div style={{ display: "flex" }}>
              <TooltipHost
                key={1}
                content={"Type"}
                styles={{ root: { display: "flex" } }}
              >
                <IconF
                  iconName={
                    userOrService.type === "User"
                      ? "ContactInfo"
                      : "AzureServiceEndpoint"
                  }
                  style={iconStyle}
                />
              </TooltipHost>
            </div>
          ),
        },
      ];
      return columns;
    };

    return (
      <>
        <Stack verticalFill>
          <Stack horizontal>
            {selectToggle === "user" ? (
              <PrimaryButton
                onClick={() => {
                  setSelectToggle("user");
                }}
                styles={{
                  root: {
                    margin: "15px",
                    width: 200,
                    marginRight: 20,
                  },
                }}
              >
                Select Users
              </PrimaryButton>
            ) : (
              <DefaultButton
                onClick={() => {
                  setSelectToggle("user");
                }}
                styles={{
                  root: {
                    margin: "15px",
                    width: 200,
                    marginRight: 20,
                  },
                }}
              >
                Select Users
              </DefaultButton>
            )}

            {selectToggle === "service" ? (
              <PrimaryButton
                onClick={() => {
                  setSelectToggle("service");
                }}
                styles={{
                  root: {
                    margin: "15px",
                    width: 200,
                    marginRight: 20,
                  },
                }}
              >
                Select Services
              </PrimaryButton>
            ) : (
              <DefaultButton
                onClick={() => {
                  setSelectToggle("service");
                }}
                styles={{
                  root: {
                    margin: "15px",
                    width: 200,
                    marginRight: 20,
                  },
                }}
              >
                Select Services
              </DefaultButton>
            )}
            {selectToggle === "table" ? (
              <PrimaryButton
                onClick={() => {
                  setSelectToggle("table");
                }}
                styles={{
                  root: {
                    margin: "15px",
                    width: 200,
                    marginRight: 20,
                  },
                }}
              >
                Show All Selected
              </PrimaryButton>
            ) : (
              <DefaultButton
                onClick={() => {
                  setSelectToggle("table");
                }}
                styles={{
                  root: {
                    margin: "15px",
                    width: 200,
                    marginRight: 20,
                  },
                }}
              >
                Show All Selected
              </DefaultButton>
            )}
          </Stack>

          {selectToggle === "table" && (
            <Stack horizontal>
              <StackItem style={{ width: "100%" }}>
                <Table
                  persistOpts={{
                    key: "table-members",
                    version: 2,
                  }}
                  header={{
                    title: "Members selection",
                  }}
                  items={selectedMembers}
                  columns={getColumnsMembers()}
                  //filters={filters}
                  hasSelection={false}
                  isLoading={false}
                />
              </StackItem>
            </Stack>
          )}
          {selectToggle === "user" && (
            <>
              <StackItem styles={{ root: { padding: 20 } }}>
                <Text style={titleStyle}>Select Users</Text>
                <TextField
                  label="Filter:"
                  description="Type to filter"
                  onChange={handleChangeUserFilter}
                  value={filteringTextUser}
                />

                {usersRBACPlain.map((idProv) => {
                  const aux = idProv.users.filter(
                    (us) =>
                      us.name.toLowerCase().indexOf(filteringTextUser) > -1 ||
                      us.issuer.toLowerCase().indexOf(filteringTextUser) > -1
                  );
                  return (
                    <div key={idProv.name}>
                      {aux.length > 0 && (
                        <Text style={title2Style}>{idProv.name}</Text>
                      )}
                      {aux.map((usr) => {
                        return (
                          <Checkbox
                            label={usr.name}
                            key={usr.id}
                            onChange={(ev, checked) =>
                              onChangeCheckbox(ev, checked, usr.id, "User")
                            }
                            styles={{ root: { padding: 2 } }}
                            checked={
                              selectedUsersIds.find((id) => id === usr.id)
                                ? true
                                : false
                            }
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </StackItem>
            </>
          )}

          {selectToggle === "service" && (
            <>
              <StackItem styles={{ root: { padding: 20 } }}>
                <Text style={titleStyle}>Select Services</Text>
                <TextField
                  label="Filter:"
                  description="Type to filter"
                  onChange={handleChangeUserFilter}
                  value={filteringTextUser}
                />
                {serviceAccounts
                  .filter(
                    (us) =>
                      us.displayName.toLowerCase().indexOf(filteringTextUser) >
                      -1
                  )
                  .map((usr) => {
                    return (
                      <Checkbox
                        label={usr.displayName}
                        key={usr.memberId}
                        onChange={(ev, checked) =>
                          onChangeCheckbox(ev, checked, usr.memberId, "Account")
                        }
                        styles={{ root: { padding: 2 } }}
                        checked={
                          selectedUsersIds.find((id) => id === usr.memberId)
                            ? true
                            : false
                        }
                      />
                    );
                  })}
              </StackItem>
            </>
          )}
        </Stack>
      </>
    );
  }, [
    addManyPermissions,
    filteringTextUser,
    selectToggle,
    selectedMembers,
    selectedUsersIds,
    serviceAccounts,
    usersPlain,
    usersRBACPlain,
  ]);

  const handleClose = () => {
    onClose?.();
  };

  return (
    <>
      <BaseDialog
        {...rest}
        hidden={!show}
        dialogContentProps={{
          type: DialogType.close,
          title: "Add Permissions",
          closeButtonAriaLabel: "Close",
          onDismiss: handleClose,
        }}
        styles={{
          main: {
            "@media (min-width: 480px)": {
              height: "67vh",
            },
          },
        }}
        size={DialogSize.L}
      >
        <div>
          <Stack
            tokens={stackTokens}
            horizontalAlign="center"
            styles={{ root: { display: "grid" } }}
          >
            <StackItem>
              <Pivot aria-label="Pivots to add permission">
                <PivotItem headerText={"Members"} itemKey={"members"}>
                  <ScrollablePane
                    scrollContainerFocus={true}
                    scrollbarVisibility={ScrollbarVisibility.auto}
                    scrollContainerAriaLabel={"scroll-projects"}
                    style={{
                      height: "52vh",
                      position: "relative",
                    }}
                  >
                    {membersPivot()}
                  </ScrollablePane>
                </PivotItem>

                <PivotItem headerText={"Role"} itemKey={"role"}>
                  <ScrollablePane
                    scrollContainerFocus={true}
                    scrollbarVisibility={ScrollbarVisibility.auto}
                    scrollContainerAriaLabel={"scroll-projects"}
                    style={{
                      height: "52vh",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    {RolesComponentToAdd({
                      rolesToAdd,
                      setRolesToAdd,
                      onDelete,
                    })}
                  </ScrollablePane>
                </PivotItem>
              </Pivot>
            </StackItem>
          </Stack>
        </div>
        <DialogFooter>
          <PrimaryButton
            styles={{
              root: { width: 200, marginRight: 20 },
            }}
            text="Assign"
            onClick={async () => {
              await addManyPermissionsApi(addManyPermissions)
                .then((res) => {
                  if (res.statusText === "Created") {
                    notification.success("Permissions added");
                    //dispatch(listAsyncPermi());
                  } else notification.error("Something went wrong");

                  onSuccess();
                })
                .catch(console.error);
            }}
          />
        </DialogFooter>
      </BaseDialog>
    </>
  );
};
