import { useMemo, useState } from "react";
import {
  FormItemProps,
  FormItemType,
  notification,
  renderFormItems,
  useZodForm,
} from "web-analysis-lib";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import {
  PrimaryButton,
  Spinner,
  SpinnerSize,
  StackItem,
} from "@fluentui/react";
import { FormGroupProps } from "web-analysis-lib/dist/ui-kit/Form";
import SectionContainer from "../SectionContainer";
import { axiosInstance } from "../../../..";

import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { selectAllUserAccountProperties, selectUserAccountInformationProperties, selectUserAccountMemberID } from "../../../UserAccount/selectors";
import { updateUserAccountDetails } from "../../../UserAccount/api";
import { getAsyncUserAccountDetails } from "../../../UserAccount/reducer";

export type ThreadAddProps = {
  machineId: string;
  text: string;
};

export const useAccountUpdate = (data) => {
  return axiosInstance.post("meta/machinecv/v1/comments", data);
};

const getSchema = () =>
  z.object({
    lastName: z.string().min(1, { message: "This field is required" }),
    firstName: z.string().min(1, { message: "This field is required" }),
    displayName: z.string().optional(),
    companyName: z.string().optional(),
    department: z.string().optional(),
    jobTitle: z.string().optional(),
  });

const groupPropsStyles = {
  labelProps: {
    style: {
      fontWeight: 600,
    },
  },
  stackProps: {
    style: {
      flexDirection: "column",
      marginBottom: 12,
      maxWidth: 600,
    },
  },
} as FormGroupProps;

const formStyles = {
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  padding: 16,
  marginBottom: 65,
  overflow: 'auto'
} as const;

const formItems: FormItemProps[] = [
  {
    name: "firstName",
    type: FormItemType.TextField,
    placeholder: "Example: John",
    groupProps: {
      label: "First name",
      ...groupPropsStyles,
    },
  },
  {
    name: "lastName",
    type: FormItemType.TextField,
    placeholder: "Example: Doe",
    groupProps: {
      label: "Last name",
      ...groupPropsStyles,
    },
  },
  {
    name: "displayName",
    type: FormItemType.TextField,
    placeholder: "Example: Johnny",
    groupProps: {
      label: "Display name",
      ...groupPropsStyles,
    },
  },
  {
    name: "companyName",
    type: FormItemType.TextField,
    placeholder: "Example: My Company",
    groupProps: {
      label: "Company name",
      ...groupPropsStyles,
    },
  },
  {
    name: "department",
    type: FormItemType.TextField,
    placeholder: "Example: Development",
    groupProps: {
      label: "Department",
      ...groupPropsStyles,
    },
  },
  {
    name: "jobTitle",
    type: FormItemType.TextField,
    placeholder: "Example: Web Developer",
    groupProps: {
      label: "Job title",
      ...groupPropsStyles,
    },
  },
];

const Information = () => {
  const schema = useMemo(() => getSchema(), []);
  const [isLoading, setIsLoading] = useState(false);

  const allUserAccountProperties = useAppSelector(selectAllUserAccountProperties)
  const informationProperties = useAppSelector(selectUserAccountInformationProperties);
  const userId = useAppSelector(selectUserAccountMemberID);
  const dispatch = useAppDispatch()

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useZodForm({
    mode: "onChange",
    schema,
    defaultValues: informationProperties,
  });


  const onSubmit = handleSubmit(async (data: object) => {
    if (!data) return;

    setIsLoading(true);
    
    const payload = {
      ...allUserAccountProperties,
      ...data
    }

    await updateUserAccountDetails(userId, payload)
      .then((response) => {
        if(response){
          notification.success("Settings updated successfully!");
        }
      })
      .catch(() => {
        notification.warning(
          "Something went wrong, please refresh the page. And try it again."
        );
      })
      .finally(() => {
        dispatch(getAsyncUserAccountDetails(userId))
        setIsLoading(false)
      });
  });

  return (
    <SectionContainer headerTitle="Information">
      <form onSubmit={onSubmit} style={{ ...formStyles }}>
        <div className="form-items-container">
          {renderFormItems(formItems, {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          }).map((element) => (
            <StackItem key={element.key}>{element}</StackItem>
          ))}
        </div>

        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            padding: 16,
            background: "white",
            width: "100%",
            borderTop: "1px solid #F3F2F1",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <PrimaryButton
            type="submit"
            text="Update"
            disabled={!isValid || isLoading}
            style={{
              marginTop: "auto",
              alignSelf: "flex-start",
            }}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
        </div>
      </form>
    </SectionContainer>
  );
};

export default Information;
