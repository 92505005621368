import { AxiosRequestConfig, AxiosResponse } from "axios";
import { axiosInstance } from "../..";
import {
  CreateMachine,
  MachineDetails,
  MachineFixedCondition,
  ResponseMachineDetails,
  ResponseMachineDetailsWithChilds,
  UpdateMachine,
} from "./models";
import { convertXLSXtoCSV } from "../../schema/Utils";

export async function D850CFGConvert(
  id: string,
  file_in: any
): Promise<AxiosResponse<any>> {
  const formData = new FormData();
  formData.append("file", file_in!);
  const config: AxiosRequestConfig = {
    responseType: "blob",
  };

  const urlRequest = "/meta/write/v1/machines/" + id + "/d850/convert";
  const response = await axiosInstance.post<any>(urlRequest, formData, config);
  return response;
}

export async function listMachines(): Promise<ResponseMachineDetails[]> {
  const response = await axiosInstance.get<ResponseMachineDetails[]>(
    "/meta/read/v1/machines "
  );
  return response.data;
}

export async function deleteMachine(
  id: string
): Promise<AxiosResponse<MachineDetails, any>> {
  const urlRequest = "/meta/write/v1/machines/" + id;
  const response = await axiosInstance.delete<MachineDetails>(urlRequest);
  return response;
}

export async function editMachine(
  machine: UpdateMachine
): Promise<MachineDetails | any> {
  const urlRequest = "/meta/write/v1/machines/" + machine.id;
  const response = await axiosInstance.put<MachineDetails | any>(
    urlRequest,
    machine
  );
  if (response.data) return response.data;
  else return response;
}

export const updateMachine = async (
  machine: UpdateMachine
): Promise<AxiosResponse<MachineDetails, any>> => {
  const urlRequest = "/meta/write/v1/machines/" + machine.id;
  const response = await axiosInstance.put<MachineDetails>(urlRequest, machine);
  return response;
};

export async function machineExport(id: string, type: string): Promise<any> {
  const response = await axiosInstance.get<any>(
    "/meta/read/v1/machines/" + id + "/" + type + "/export"
  );
  if (response.data) return response.data;
  else return response;
}

export async function AlertLevelsExport(id: string): Promise<any> {
  const response = await axiosInstance.get<any>(
    "/meta/read/internal/v1/machines/" + id + "/alertLevels/export?format=csv"
  );
  if (response.data) return response.data;
  else return response;
}

export async function machineImport(
  id: string,
  type: string,
  file_in: any
): Promise<any> {
  const formData = new FormData();

  file_in.type ===
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ? await convertXLSXtoCSV(file_in)
        .then((file) => {
          formData.append("file", file!);
        })
        .catch((error) => {
          console.error("Error converting XLSX to CSV:", error);
        })
    : formData.append("file", file_in!);

  const urlRequest = "/meta/write/v1/machines/" + id + "/" + type + "/import";
  const response = await axiosInstance.post<any>(urlRequest, formData);
  return response;
}

export async function machineDetails(
  id: string
): Promise<ResponseMachineDetailsWithChilds | any> {
  const response = await axiosInstance.get<
    ResponseMachineDetailsWithChilds | any
  >("/meta/read/v1/machines/" + id);
  if (response.data) return response.data;
  else return response;
}

export const createMachine = async (
  machine: CreateMachine
): Promise<AxiosResponse<MachineDetails, any>> => {
  const config: CreateMachine = {
    dalogId: machine.dalogId,
    gearbox: machine.gearbox,
    manufacturer: machine.manufacturer,
    name: machine.name,
    notation: machine.notation,
    process: machine.process,
    projectId: machine.projectId,
    customerCode: machine.customerCode,
    type: machine.type,
  };
  const urlRequest = "/meta/write/v1/machines";

  const response = await axiosInstance.post<MachineDetails | any>(
    urlRequest,
    config
  );
  return response;
};

export async function getMachineFixedCondition(
  id: string
): Promise<MachineFixedCondition | any> {
  const response = await axiosInstance.get<MachineFixedCondition | any>(
    "/meta/write/v1/machines/" + id + "/fixedCondition"
  );
  if (response.data) return response.data;
  else return response;
}

export async function updateMachineFixedCondition(
  id: string,
  config: MachineFixedCondition
): Promise<MachineFixedCondition | any> {
  const response = await axiosInstance.put<MachineFixedCondition | any>(
    "/meta/write/v1/machines/" + id + "/fixedCondition",
    config
  );
  if (response.data) return response.data;
  else return response;
}
