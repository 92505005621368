/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, useState, useEffect } from "react";
import {
  BaseDialog,
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "web-analysis-lib";
import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
} from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import { UpdateSignalCondition } from "./models";

import { getOptionFromEnum } from "../../schema/Utils";

import { editSignalCondition } from "./api";
import { SignalCondition } from "../../schema/status";

const getSchema = () =>
  z.object({
    condition: z.nativeEnum(SignalCondition),
  });

const fields: FormItemProps[] = [
  {
    name: "condition",
    type: FormItemType.Dropdown,
    groupProps: { label: "Condition" },
    options: getOptionFromEnum(SignalCondition),
  },
];

type AddOrEditDialogProps = IDialogProps & {
  machineId: string;
  data: UpdateSignalCondition;
  show: boolean;
  onSuccess: (hasError: boolean) => void;
  onClose: () => void;
};

export const EditCondition = ({
  machineId,
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}: AddOrEditDialogProps) => {
  const schema = useMemo(() => getSchema(), []);

  const [isLoading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        condition: data.condition,
      },
    }),
  });

  useEffect(() => {
    data
      ? reset({
          condition: data.condition,
        })
      : reset({
          condition: SignalCondition.NoStatus,
        });
  }, [data, reset]);

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    const toSend: UpdateSignalCondition = {
      id: data?.id,
      condition: formData.condition,
    };

    await editSignalCondition(machineId, data?.id, toSend).then((response) =>
      onSuccess("status" in response)
    );

    handleClose();
  });

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <>
      {
        <BaseDialog
          {...rest}
          hidden={!show}
          dialogContentProps={{
            type: DialogType.normal,
            title: " Edit signal condition",
            closeButtonAriaLabel: "Close",
            onDismiss: handleClose,
          }}
        >
          {
            <form onSubmit={onSubmit}>
              {renderFormItems(fields, {
                control,
                errors: errors as { [schemaProp: string]: FieldError },
              })}

              <DialogFooter>
                <PrimaryButton
                  type="submit"
                  text="Save Changes"
                  disabled={isLoading || !isValid}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                />
                <DefaultButton
                  styles={{
                    root: { border: "unset", background: "transparent" },
                  }}
                  text="Cancel"
                  onClick={handleClose}
                />
              </DialogFooter>
            </form>
          }
        </BaseDialog>
      }
    </>
  );
};
