import { axiosInstance } from "../..";
import { Company, CompanyDetails, CreateCompany } from "./models";

export async function listCompanies(): Promise<Company[]> {
  const response = await axiosInstance.get<Company[]>(
    "/meta/read/v1/companies "
  );
  return response.data;
}

export async function deleteCompany(
  company: Company
): Promise<CompanyDetails | any> {
  const urlRequest = "/meta/write/v1/companies/" + company.id;
  const response = await axiosInstance.delete<CompanyDetails | any>(urlRequest);
  if (response.data) return response.data;
  else return response;
}

export async function editCompany(
  company: Company
): Promise<CompanyDetails | any> {
  const config: Company = {
    name: company.name,
    number: company.number,
    id: company.id,
    corporationId: company.corporationId,
  };
  const urlRequest = "/meta/write/v1/companies/" + company.id;
  const response = await axiosInstance.put<CompanyDetails | any>(
    urlRequest,
    config
  );
  if (response.data) return response.data;
  else return response;
}

export async function addCompany(
  company: CreateCompany
): Promise<CompanyDetails | any> {
  const config = {
    name: company.name,
    number: company.number,
    corporationId: company.corporationId,
  };
  const urlRequest = "/meta/write/v1/companies";

  const response = await axiosInstance.post<CompanyDetails | any>(
    urlRequest,
    config
  );
  if (response.data) return response.data;
  else return response;
}
