import { FormEvent, useState } from "react";
import {
  TextField,
  DialogFooter,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  DefaultButton,
  DialogType,
  IDialogContentProps,
} from "@fluentui/react";
import { BaseDialog, DialogSize } from "web-analysis-lib";

type DeleteDialogProps = {
  id: string;
  identifier: string;
  propertyName: string;
  referenceString: string;
  isLoading: boolean;
  onDelete: (id: string) => void;
  onClose: () => void;
};

/**
 * Gets the generic delete dialog component.
 * @param id The item Id.
 * @param identifier The item identifier
 * @param propertyName The property named used to confirm deletion.
 * @param isLoading A value indicating whether the form is in loading state.
 * @param onDelete Method called when the delete button is clicked.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns
 */
const DeleteDialog = ({
  id,
  identifier,
  propertyName,
  referenceString,
  isLoading,
  onDelete,
  onClose,
}: DeleteDialogProps) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [textFieldValue, setTextFieldValue] = useState<string>("");

  // Method called when the Delete button is clicked.
  const onDeleteHandler = () => {
    if (!id) {
      return;
    }

    onDelete?.(id);
  };

  // Method called when the text field value is changed.
  const onTextChanged = (
    _: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    if (!newValue) {
      setErrorMessage("");
      setTextFieldValue("");
      return;
    }

    setErrorMessage(
      newValue.toLowerCase().trim() === referenceString.toLowerCase().trim()
        ? ""
        : `The input ${propertyName} is not the same as the entry ${propertyName}.`
    );
    setTextFieldValue(newValue);
  };

  const dialogContentProps: IDialogContentProps = {
    type: DialogType.close,
    isMultiline: true,
    title: "Attention!",
    closeButtonAriaLabel: "Close",
    onDismiss: onClose,
  };

  return (
    <BaseDialog
      hidden={false}
      dialogContentProps={dialogContentProps}
      size={DialogSize.M}
    >
      <p className="normal-text">
        You are about to permanently delete a {identifier}.
      </p>
      <br />
      <TextField
        label={`Please confirm typing the ${propertyName}: *`}
        value={textFieldValue}
        onChange={onTextChanged}
        errorMessage={errorMessage}
      />
      <DialogFooter>
        <PrimaryButton
          className="primary-button"
          disabled={
            isLoading || textFieldValue.length === 0 || errorMessage.length > 0
          }
          onClick={onDeleteHandler}
          text="Delete"
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
        />
        <DefaultButton
          className="secondary-button"
          disabled={isLoading}
          onClick={onClose}
          text="Cancel"
        />
      </DialogFooter>
    </BaseDialog>
  );
};

export default DeleteDialog;
