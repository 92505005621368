import {
  MachineCVDriveLoad,
  MachineCVPerformance,
  MachineCVHome,
  MachineCVTasks,
  MachineCVComments,
  MachineCVInfo,
  MachineCVDocuments,
  MachineCVThreads,
  MachineCVSensors,
  MachineCVReports,
  MachineCVSummary,
} from "web-analysis-lib";
import { axiosInstance } from "../..";

export const Home = () => <MachineCVHome axiosInstance={axiosInstance} />;

export const DriveLoad = () => (
  <MachineCVDriveLoad axiosInstance={axiosInstance} />
);

export const Performance = () => (
  <MachineCVPerformance axiosInstance={axiosInstance} />
);

export const Sensors = () => (
  <MachineCVSensors axiosInstance={axiosInstance} />
);

export const Tasks = () => <MachineCVTasks axiosInstance={axiosInstance} />;

export const Comments = () => (
  <MachineCVComments axiosInstance={axiosInstance} />
);

export const Info = () => <MachineCVInfo axiosInstance={axiosInstance} />;

export const Documents = () => (
  <MachineCVDocuments axiosInstance={axiosInstance} />
);

export const Threads = () => (
  <MachineCVThreads axiosInstance={axiosInstance} />
);

export const Reports = () => (
  <MachineCVReports axiosInstance={axiosInstance} />
);

export const Summary = () => (
  <MachineCVSummary axiosInstance={axiosInstance} />
);
