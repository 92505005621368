import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  Draft,
} from "@reduxjs/toolkit";
import { D325, D850, D850Eco, DBasic, DModels } from "./models";
import { RootState } from "../../store";
import { DataloggersAPI } from "./api";
import { Status } from "../../schema/status";
import { WritableDraft } from "immer/dist/internal";
import { Utils } from "./utils";

interface DataloggerState<T = D325 | DBasic | D850Eco | D850> {
  dataloggers: T[];
  selectedItem: T | null;
  status: Status;
  error: string;
}

/**
 * * Dataloggers States
 */
const initialStateD325: DataloggerState<D325> = {
  dataloggers: [],
  selectedItem: null,
  status: Status.void,
  error: "",
};

const initialStateD555: DataloggerState<DBasic> = {
  dataloggers: [],
  selectedItem: null,
  status: Status.void,
  error: "",
};

const initialStateD650: DataloggerState<DBasic> = {
  dataloggers: [],
  selectedItem: null,
  status: Status.void,
  error: "",
};

const initialStateD850Eco: DataloggerState<D850Eco> = {
  dataloggers: [],
  selectedItem: null,
  status: Status.void,
  error: "",
};

const initialStateD850: DataloggerState<D850> = {
  dataloggers: [],
  selectedItem: null,
  status: Status.void,
  error: "",
};

/**
 * * API Async Thunks
 */
export const listDataloggersAsync = (model: DModels) => {
  const result = createAsyncThunk(
    `dataloggers/${Utils.getDModelsKey(model)}/list`,
    async () => {
      const response = await DataloggersAPI.list(model);
      return response;
    }
  );

  return result;
};

/**
 * * Extra reducers
 */
const listReducers = <T = D325 | DBasic | D850Eco | D850>(
  model: DModels,
  builder: ActionReducerMapBuilder<WritableDraft<DataloggerState<T>>>
) => {
  builder
    .addCase(listDataloggersAsync(model).pending, (state) => {
      state.status = Status.loading;
    })
    .addCase(listDataloggersAsync(model).fulfilled, (state, action) => {
      state.status = Status.idle;
      state.error = "";
      state.dataloggers = action.payload.data as Draft<Draft<T>>[];
    })
    .addCase(listDataloggersAsync(model).rejected, (state) => {
      state.status = Status.error;
      state.error = `Error loading ${model} dataloggers. Please try again later.`;
    });
};

/**
 * * Redux slices.
 */

export const d325Slice = createSlice({
  name: "D325",
  initialState: initialStateD325,
  reducers: {},
  extraReducers: (builder) => {
    listReducers<D325>(DModels.D325, builder);
  },
});

export const d555Slice = createSlice({
  name: "D555",
  initialState: initialStateD555,
  reducers: {},
  extraReducers: (builder) => {
    listReducers<DBasic>(DModels.D555, builder);
  },
});

export const d650Slice = createSlice({
  name: "D650",
  initialState: initialStateD650,
  reducers: {},
  extraReducers: (builder) => {
    listReducers<DBasic>(DModels.D650, builder);
  },
});

export const d850EcoSlice = createSlice({
  name: "D850Eco",
  initialState: initialStateD850Eco,
  reducers: {},
  extraReducers: (builder) => {
    listReducers<D850Eco>(DModels.D850Eco, builder);
  },
});

export const d850Slice = createSlice({
  name: "D850",
  initialState: initialStateD850,
  reducers: {},
  extraReducers: (builder) => {
    listReducers<D850>(DModels.D850, builder);
  },
});

/**
 * * Selectors
 */
export const selectDataloggers = (
  model: DModels
): ((state: RootState) => D325[] | DBasic[] | D850Eco[] | D850[]) => {
  switch (model) {
    case DModels.D325:
      return (state: RootState) => state.dataloggers325.dataloggers;
    case DModels.D555:
      return (state: RootState) => state.dataloggers555.dataloggers;
    case DModels.D650:
      return (state: RootState) => state.dataloggers650.dataloggers;
    case DModels.D850Eco:
      return (state: RootState) => state.dataloggers850Eco.dataloggers;
    case DModels.D850:
      return (state: RootState) => state.dataloggers850.dataloggers;
  }
};

export const selectDataloggersStatus = (
  model: DModels
): ((state: RootState) => Status) => {
  switch (model) {
    case DModels.D325:
      return (state: RootState) => state.dataloggers325.status;
    case DModels.D555:
      return (state: RootState) => state.dataloggers555.status;
    case DModels.D650:
      return (state: RootState) => state.dataloggers650.status;
    case DModels.D850Eco:
      return (state: RootState) => state.dataloggers850Eco.status;
    case DModels.D850:
      return (state: RootState) => state.dataloggers850.status;
  }
};

/**
 * * Exports
 */
export const dataloggers325Reducer = d325Slice.reducer;
export const dataloggers555Reducer = d555Slice.reducer;
export const dataloggers650Reducer = d650Slice.reducer;
export const dataloggers850EcoReducer = d850EcoSlice.reducer;
export const dataloggers850Reducer = d850Slice.reducer;
