/* eslint-disable react-hooks/exhaustive-deps */
import {
  DialogSize,
  FormItemProps,
  FormItemType,
  notification,
  renderFormItems,
  useZodForm,
} from "web-analysis-lib";
import { DialogType, Text } from "@fluentui/react";
import { ZodRawShape, z } from "zod";
import { FieldError } from "react-hook-form";
import { useAppDispatch } from "../../hooks";
import {
  D325,
  D850,
  AdjustmentCardTypes,
  D850Eco,
  D850OS,
  DBasic,
  DModels,
} from "./models";
import { useEffect, useState } from "react";
import { Utils } from "./utils";
import { DataloggerDialogsProps, DataloggerFormData } from "./formModels";
import FormDialog from "../Generic/FormDialog";
import { listDataloggersAsync } from "./reducer";
import { DataloggersAPI } from "./api";
import ControlledComboBox from "../Generic/ControlledComboBox";
import FormItemRow from "../Generic/FormItemRow";
import { areObjectsEqual } from "../../schema/Utils";
import { useProjects } from "../../Hooks/useProjects";
import { ResponseProjectDetails } from "../Projects/models";

/**
 * Gets the form schema
 * @param model The current datalogger model.
 * @returns The form schema.
 */
const getFormSchema = (model: DModels) => {
  // Basic schema
  let formSchema: ZodRawShape = {};

  switch (model) {
    case DModels.D325:
    case DModels.D555:
    case DModels.D650:
      // Adds validations to additional fields for the datalogger 325, 555 and 650 schemas
      formSchema = {
        macAddress: z
          .string()
          .length(17, "The MAC address must have the form XX-XX-XX-XX-XX-XX.")
          .regex(
            Utils.regexMacAddress,
            "The MAC address must have the form XX-XX-XX-XX-XX-XX."
          ),
        firmwareVersion: z
          .string()
          .min(1, { message: "Please insert a Firmware version." }),
      };

      // IP Address (555 and 650 dataloggers)
      if (model !== DModels.D325) {
        formSchema = {
          ...formSchema,
          ipAddress: z
            .union([
              z.string().length(0, {
                message:
                  "Please insert an IP address of the form xxx.xxx.xxx.xxx",
              }),
              z.string().regex(Utils.regexIpAddress),
            ])
            .optional()
            .transform((e) => (e === "" ? "" : e)),
          subnetMask: z
            .union([
              z.string().length(0, {
                message:
                  "Please insert an IP address of the form xxx.xxx.xxx.xxx",
              }),
              z.string().regex(Utils.regexIpAddress),
            ])
            .optional()
            .transform((e) => (e === "" ? "" : e)),
          gateway: z
            .union([
              z.string().length(0, {
                message:
                  "Please insert an IP address of the form xxx.xxx.xxx.xxx",
              }),
              z.string().regex(Utils.regexIpAddress),
            ])
            .optional()
            .transform((e) => (e === "" ? "" : e)),
        };
      }

      break;
    case DModels.D850:
    case DModels.D850Eco:
      // Adds validations to additional fields for the datalogger 850 and 850 Eco schemas
      if (model === DModels.D850) {
        formSchema = {
          operativeSystem: z
            .string()
            .min(1, { message: "Please select an operative system." }),
          ipAddress: z
            .union([
              z.string().length(0, {
                message:
                  "Please insert an IP address of the form xxx.xxx.xxx.xxx",
              }),
              z.string().regex(Utils.regexIpAddress),
            ])
            .optional()
            .transform((e) => (e === "" ? "" : e)),
          subnetMask: z
            .union([
              z.string().length(0, {
                message:
                  "Please insert an IP address of the form xxx.xxx.xxx.xxx",
              }),
              z.string().regex(Utils.regexIpAddress),
            ])
            .optional()
            .transform((e) => (e === "" ? "" : e)),
          gateway: z
            .union([
              z.string().length(0, {
                message:
                  "Please insert an IP address of the form xxx.xxx.xxx.xxx",
              }),
              z.string().regex(Utils.regexIpAddress),
            ])
            .optional()
            .transform((e) => (e === "" ? "" : e)),
        };
      }

      if (model === DModels.D850 || model === DModels.D850Eco) {
        formSchema = {
          ...formSchema,
          basisCardMacAddress: z
            .string()
            .length(17, "The MAC address must have the form XX-XX-XX-XX-XX-XX.")
            .regex(
              Utils.regexMacAddress,
              "The MAC address must have the form XX-XX-XX-XX-XX-XX."
            ),
          adjustmentCardType: z.string().optional(),
        };
      }
      break;
  }

  return z.object(formSchema);
};

/**
 * Gets the form props.
 * @param model The current datalogger model.
 * @param disableModelSelection Value determining whether the model dropdown field should be disabled.
 * @param projectsRef The projects reference list.
 * @param onModelChanged Function called when the datalogger model is changed.
 * @returns The form item props array.
 */
const getFormProps = (model: DModels): FormItemProps[] => {
  // Basic datalogger form fields.
  let result: FormItemProps[] = [];

  switch (model) {
    case DModels.D325:
    case DModels.D555:
    case DModels.D650:
      result.push(
        {
          name: "macAddress",
          type: FormItemType.TextField,
          groupProps: { label: "MAC Address *" },
        },
        {
          name: "firmwareVersion",
          type: FormItemType.TextField,
          groupProps: { label: "Firmware Version *" },
        }
      );

      if (model !== DModels.D325) {
        result.push(
          {
            name: "ipAddress",
            type: FormItemType.TextField,
            groupProps: { label: "IP Address " },
          },
          {
            name: "subnetMask",
            type: FormItemType.TextField,
            groupProps: { label: "Subnet Mask " },
          },
          {
            name: "gateway",
            type: FormItemType.TextField,
            groupProps: { label: "Gateway " },
          }
        );
      }
      break;

    case DModels.D850:
    case DModels.D850Eco:
      // Adds fields for the 850 and 850 Eco dataloggers.
      if (model === DModels.D850) {
        result.push(
          {
            name: "operativeSystem",
            type: FormItemType.Dropdown,
            groupProps: { label: "Operative System *" },
            options: Object.keys(D850OS).map((os) => {
              return { key: os, text: D850OS[os] };
            }),
            placeholder: "Select an operative system...",
            allowFreeform: false,
          },
          {
            name: "ipAddress",
            type: FormItemType.TextField,
            groupProps: { label: "IP Address " },
          },
          {
            name: "subnetMask",
            type: FormItemType.TextField,
            groupProps: { label: "Subnet Mask " },
          },
          {
            name: "gateway",
            type: FormItemType.TextField,
            groupProps: { label: "Gateway " },
          }
        );
      }

      if (model === DModels.D850Eco || model === DModels.D850) {
        result.push(
          {
            name: "basisCardMacAddress",
            type: FormItemType.TextField,
            groupProps: { label: "BasisCard MAC Address *" },
          },
          {
            name: "adjustmentCardType",
            type: FormItemType.Dropdown,
            groupProps: { label: "Adjustment Card Type " },
            options: Object.keys(AdjustmentCardTypes).map((type) => {
              return { key: type, text: AdjustmentCardTypes[type] };
            }),
            placeholder: "Select a type...",
            allowFreeform: false,
          }
        );
      }
      break;
  }

  return result;
};

/**
 * Gets the form default values for an existing datalogger entry.
 * @param entry The datalogger item entry
 * @returns The default form values.
 */
const getDefaultValues = (
  model: DModels,
  entry: D325 | DBasic | D850Eco | D850
): DataloggerFormData => {
  let result: DataloggerFormData = {
    model: Utils.getDModelsKey(model),
    projectId: entry.projectId,
  };

  switch (model) {
    case DModels.D325:
      let d325Entry = entry as D325;
      if (d325Entry) {
        result = {
          ...result,
          macAddress: d325Entry.macAddress,
          firmwareVersion: d325Entry.firmwareVersion,
        };
      }

      break;
    case DModels.D555:
    case DModels.D650:
      let dBasicEntry = entry as DBasic;
      if (dBasicEntry) {
        result = {
          ...result,
          macAddress: dBasicEntry.macAddress,
          firmwareVersion: dBasicEntry.firmwareVersion,
          ipAddress: dBasicEntry.ipAddress ? dBasicEntry.ipAddress : "",
          subnetMask: dBasicEntry.subnetMask ? dBasicEntry.subnetMask : "",
          gateway: dBasicEntry.gateway ? dBasicEntry.gateway : "",
        };
      }

      break;

    case DModels.D850Eco:
      let d850EcoEntry = entry as D850Eco;
      if (d850EcoEntry) {
        result = {
          ...result,
          basisCardMacAddress: d850EcoEntry.basisCard.macAddress,
          adjustmentCardType: d850EcoEntry.adjustmentCard.adjustmentCardType,
        };
      }

      break;

    case DModels.D850:
      let d850Entry = entry as D850;
      if (d850Entry) {
        result = {
          ...result,
          operativeSystem: d850Entry.operativeSystem,
          basisCardMacAddress: d850Entry.basisCard.macAddress,
          adjustmentCardType: d850Entry.adjustmentCard.adjustmentCard,
          ipAddress: d850Entry.ipAddress ? d850Entry.ipAddress : "",
          subnetMask: d850Entry.subnetMask ? d850Entry.subnetMask : "",
          gateway: d850Entry.gateway ? d850Entry.gateway : "",
        };
      }
      break;
  }

  return result;
};

/**
 * Gets the basic datalogger add-edit form component.
 * @param model The datalogger model.
 * @param title The dialog title
 * @param entry The datalogger entry.
 * @param isLoading True to block the buttons and show the spinner animation. Otherwise, set to false.
 * @param disableModelSelection Value determining whether the model dropdown field should be disabled.
 * @param onSubmit Function called when the submit button is clicked.
 * @param onClose Event handler that must be implemented to close this dialog.
 * @returns The Datalogger add dialog component.
 */
const DataloggerAddEditForm = ({
  model,
  title,
  entry,
  isLoading,
  disableModelSelection,
  onSubmit,
  onClose,
}: DataloggerDialogsProps) => {
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(
    entry === null || entry === undefined
  );
  const [selectedModelKey, setSelectedModelKey] = useState(
    Utils.getDModelsKey(model ? model : DModels.D850)
  );
  const { projects } = useProjects();
  const [selectedProject, setSelectedProject] = useState<
    ResponseProjectDetails | undefined
  >(undefined);

  const formSchema = getFormSchema(DModels[selectedModelKey]);
  const defaultValues: DataloggerFormData = entry
    ? getDefaultValues(model, entry)
    : {
        model: selectedModelKey,
        projectId: entry?.projectId || "",
        macAddress: "",
      };

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema: formSchema,
    ...{
      defaultValues,
    },
  });

  // Sets the initial project
  useEffect(() => {
    if (projects.size === 0 || !entry) {
      return;
    }

    setSelectedProject(projects.get(entry.projectId));
  }, [entry, projects.size]);

  // Checks whether the entity has changed.
  useEffect(() => {
    if (!control) {
      return;
    }

    let areEqual =
      areObjectsEqual(control._defaultValues, control._formValues) &&
      entry?.projectId === selectedProject?.id;
    setDataHasChanged(!areEqual);
  }, [watch()]);

  // Function called when the submit button is clicked.
  const onSubmitHandler = handleSubmit((formData: DataloggerFormData) => {
    // Sets manually the model and project.
    let data: DataloggerFormData = {
      ...formData,
      model: selectedModelKey,
      projectId: selectedProject.id,
    };

    // Submits.
    onSubmit?.(data);
  });

  return (
    <FormDialog
      title={title}
      type={DialogType.normal}
      size={DialogSize.M}
      isLoading={isLoading}
      isValid={
        isValid && selectedModelKey !== "" && selectedProject && dataHasChanged
      }
      onSubmit={onSubmitHandler}
      onClose={onClose}
    >
      <FormItemRow label={"Company"} style={{ marginBottom: "0.75em" }}>
        <Text variant="medium" style={{ fontWeight: 600 }}>
          {selectedProject?.company?.name}
        </Text>
      </FormItemRow>
      <FormItemRow label={"Project *"}>
        <ControlledComboBox
          options={Array.from(projects.values()).map((project) => {
            return { key: project.id, text: project.name };
          })}
          disabled={false}
          selectedKey={selectedProject?.id}
          onKeySelected={(key) => setSelectedProject(projects.get(key))}
        />
      </FormItemRow>
      <FormItemRow label={"Model *"}>
        <ControlledComboBox
          options={Object.keys(DModels).map((model) => {
            return { key: model, text: DModels[model] };
          })}
          selectedKey={defaultValues.model}
          disabled={disableModelSelection}
          onKeySelected={(key) => setSelectedModelKey(key)}
        />
      </FormItemRow>
      {renderFormItems(getFormProps(DModels[selectedModelKey]), {
        control,
        errors: errors as { [schemaProp: string]: FieldError },
      })}
    </FormDialog>
  );
};

/**
 * Builds a new datalogger entry.
 * @param formData The form data.
 * @returns The datalogger item.
 */
const buildNewDataloggerEntry = (
  formData: DataloggerFormData
): D325 | DBasic | D850Eco | D850 => {
  let model = DModels[formData.model];
  let result: D325 | DBasic | D850Eco | D850;
  switch (model) {
    case DModels.D325:
      result = {
        projectId: formData.projectId,
        macAddress: formData.macAddress.trim(),
        firmwareVersion: formData.firmwareVersion.trim(),
        machineIds: [],
      } as D325;
      break;

    case DModels.D555:
    case DModels.D650:
      result = {
        projectId: formData.projectId,
        macAddress: formData.macAddress.trim(),
        firmwareVersion: formData.firmwareVersion.trim(),
        machineIds: [],
        ipAddress:
          !formData.ipAddress || formData.ipAddress.length === 0
            ? null
            : Utils.formatIpAddress(formData.ipAddress),
        subnetMask:
          !formData.subnetMask || formData.subnetMask.length === 0
            ? null
            : Utils.formatIpAddress(formData.subnetMask),
        gateway:
          !formData.gateway || formData.gateway.length === 0
            ? null
            : Utils.formatIpAddress(formData.gateway),
      } as DBasic;
      break;

    case DModels.D850Eco:
      result = {
        projectId: formData.projectId,
        basisCard: {
          macAddress: formData.basisCardMacAddress.trim(),
        },
        adjustmentCard: {
          adjustmentCardType: formData.adjustmentCardType,
        },
        machineIds: [],
      } as D850Eco;
      break;

    case DModels.D850:
      result = {
        projectId: formData.projectId,
        operativeSystem: formData.operativeSystem,
        basisCard: {
          macAddress: formData.basisCardMacAddress.trim(),
        },
        adjustmentCard: {
          adjustmentCard: formData.adjustmentCardType,
        },
        machineIds: [],
        ipAddress:
          !formData.ipAddress || formData.ipAddress.length === 0
            ? null
            : Utils.formatIpAddress(formData.ipAddress),
        subnetMask:
          !formData.subnetMask || formData.subnetMask.length === 0
            ? null
            : Utils.formatIpAddress(formData.subnetMask),
        gateway:
          !formData.gateway || formData.gateway.length === 0
            ? null
            : Utils.formatIpAddress(formData.gateway),
      } as D850;
      break;
  }

  return result;
};

/**
 * Builds an updated datalogger entry from an existing entry.
 * @param model The datalogger model.
 * @param item The existing entry.
 * @param formData The form data.
 * @returns The updated entry.
 */
const buildDataloggerEntry = (
  model: DModels,
  item: D325 | DBasic | D850Eco | D850,
  formData: DataloggerFormData
): D325 | DBasic | D850Eco | D850 => {
  let result: D325 | DBasic | D850Eco | D850;
  switch (model) {
    case DModels.D325:
      result = {
        ...item,
        projectId: formData.projectId,
        macAddress: formData.macAddress.trim(),
        firmwareVersion: formData.firmwareVersion.trim(),
      } as D325;
      break;

    case DModels.D555:
    case DModels.D650:
      result = {
        ...item,
        projectId: formData.projectId,
        macAddress: formData.macAddress.trim(),
        firmwareVersion: formData.firmwareVersion.trim(),
        ipAddress:
          formData.ipAddress.length === 0
            ? null
            : Utils.formatIpAddress(formData.ipAddress),
        subnetMask:
          formData.subnetMask.length === 0
            ? null
            : Utils.formatIpAddress(formData.subnetMask),
        gateway:
          formData.gateway.length === 0
            ? null
            : Utils.formatIpAddress(formData.gateway),
      } as DBasic;
      break;

    case DModels.D850Eco:
      let d850Eco = item as D850Eco;
      if (!d850Eco) {
        break;
      }

      result = {
        ...d850Eco,
        projectId: formData.projectId,
        basisCard: {
          ...d850Eco.basisCard,
          macAddress: formData.basisCardMacAddress.trim(),
        },
        adjustmentCard: {
          ...d850Eco.adjustmentCard,
          adjustmentCardType: formData.adjustmentCardType,
        },
      } as D850Eco;
      break;

    case DModels.D850:
      let d850 = item as D850;
      if (!d850) {
        break;
      }

      result = {
        ...d850,
        operativeSystem: formData.operativeSystem,
        projectId: formData.projectId,
        basisCard: {
          ...d850.basisCard,
          macAddress: formData.basisCardMacAddress.trim(),
        },
        adjustmentCard: {
          ...d850.adjustmentCard,
          adjustmentCard: formData.adjustmentCardType,
        },
        ipAddress:
          formData.ipAddress.length === 0
            ? null
            : Utils.formatIpAddress(formData.ipAddress),
        subnetMask:
          formData.subnetMask.length === 0
            ? null
            : Utils.formatIpAddress(formData.subnetMask),
        gateway:
          formData.gateway.length === 0
            ? null
            : Utils.formatIpAddress(formData.gateway),
      } as D850;
      break;
  }

  return result;
};

/**
 * Gets the datalogger add dialog component.
 * @param onClose Event handler that must be implemented to close this dialog.
 * @returns The datalogger add dialog component.
 */
export const DataloggerAddDialog = ({ onClose }: DataloggerDialogsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onSubmit = (formData: DataloggerFormData) => {
    // Builds the new item.
    let model: DModels = DModels[formData.model];
    let newItem: D325 | DBasic | D850Eco | D850 =
      buildNewDataloggerEntry(formData);

    // Sends the request
    setIsLoading(true);
    DataloggersAPI.create(model, newItem).then((response) => {
      setIsLoading(false);
      if (response.status !== 201) {
        notification.error(
          `Failure: Adding a new ${model} datalogger. Please try again later.`
        );
        return;
      }

      notification.success(`Success: Adding a new ${model} datalogger. `);
      dispatch(listDataloggersAsync(model)());
      onClose();
    });
  };

  return (
    <DataloggerAddEditForm
      isLoading={isLoading}
      entry={null}
      title={"Add New Datalogger"}
      disableModelSelection={false}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

/**
 * Gets the datalogger edit dialog component.
 * @param model The datalogger model.
 * @param entry The datalogger table item entry
 * @param onClose Event handler that must be implemented to close this dialog.
 * @returns The datalogger edit dialog component.
 */
export const DataloggerEditDialog = ({
  model,
  entry,
  onClose,
}: DataloggerDialogsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onSubmit = (formData: DataloggerFormData) => {
    // Builds the new item.
    let newItem: D325 | DBasic | D850Eco | D850 = buildDataloggerEntry(
      model,
      entry,
      formData
    );

    // Resets the machines Ids if the project entry has been changed.
    if (newItem.projectId !== entry.projectId) {
      newItem = { ...newItem, machineIds: [] };
    }

    // Sends the request
    setIsLoading(true);
    DataloggersAPI.update(model, newItem).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          `Failure: Updating a new ${model} datalogger. Please try again later.`
        );
        return;
      }

      notification.success(`Success: Updating a new ${model} datalogger. `);
      dispatch(listDataloggersAsync(model)());
      onClose();
    });
  };

  return (
    <DataloggerAddEditForm
      model={model}
      isLoading={isLoading}
      entry={entry}
      title={"Edit Datalogger"}
      disableModelSelection={true}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
