import { Text } from "@fluentui/react/lib/Text";
import "./SectionInfo.css";
import React from "react";

type SectionContainerProps = {
  title?: string;
  description?: string;
  style?: any;
};

const SectionInfo = ({ title, description, style }: SectionContainerProps) => {
  return (
    <div style={style}>
      <Text block className="section-info-title">
        {title}
      </Text>
      <Text block className="section-info-description">
        {description}
      </Text>
    </div>
  );
};

export default SectionInfo;
