import { axiosInstance } from "../../..";
import { ResponseRolesOfScope } from "../models";

export async function scopesOfRole(
  id: string
): Promise<ResponseRolesOfScope | any> {
  const urlRequest = "/rbac/v1/rbac/roles/" + id + "/scopes/";
  const response = await axiosInstance.get<ResponseRolesOfScope | any>(
    urlRequest
  );
  if (response.data) return response.data;
  else return response;
}
