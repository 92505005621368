import { BaseDialog, DialogSize, notification } from "web-analysis-lib";
import {
  DialogType,
  DialogFooter,
  IDialogProps,
  PrimaryButton,
  Spinner,
  DefaultButton,
  SpinnerSize,
  TextField,
  Stack,
} from "@fluentui/react";
import { useState } from "react";
import { MachineToList } from "./models";
import { deleteMachine } from "./api";
import { useAppDispatch } from "../../hooks";
import { listAsyncMachines } from "./reducer";
import { Text } from "@fluentui/react/lib/Text";

type DeleteDialogProps = IDialogProps & {
  data: MachineToList;
  onClose: () => void;
};

export const DeleteConfirm = ({
  data,
  onClose,
  ...rest
}: DeleteDialogProps) => {
  const [isValid, setValid] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    setLoading(true);
    deleteMachine(data.id).then((response) => {
      setLoading(false);
      if (response.status !== 200) {
        notification.error("Failure: Deleting a machine.");
        return;
      }

      dispatch(listAsyncMachines());
      notification.success("Success: Deleting a machine.");
      onClose?.();
    });
  };

  const onDalogIdChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = (e.target as HTMLInputElement).value;
    const parsedValue = value.trim().toLowerCase();
    setValid(data.dalogId === parsedValue);
  };

  const handleClose = () => {
    // reset state
    setLoading(false);

    setValid(false);

    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      hidden={false}
      dialogContentProps={{
        title: "Attention!",
        type: DialogType.close,
        onDismiss: handleClose,
      }}
      size={DialogSize.M}
    >
      <Stack tokens={{ childrenGap: 10 }}>
        <Stack.Item>
          <Text variant="medium">You are about to delete a machine.</Text>
        </Stack.Item>
        <Stack.Item>
          <TextField
            required
            label="Please confirm typing the dalog Id:"
            onChange={onDalogIdChange}
          />
        </Stack.Item>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          text="Delete"
          disabled={isLoading || !isValid}
          onClick={handleDelete}
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
        />
        <DefaultButton text="Close" disabled={isLoading} onClick={onClose} />
      </DialogFooter>
    </BaseDialog>
  );
};
