import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface ErrorState {
  show: boolean;
  error: string;
  text: string;
  status: number | string;
}

export interface ErrorHandleState {
  error: ErrorState;
}

const initialState: ErrorHandleState = {
  error: {
    show: false,
    error: "",
    text: "",
    status: 0,
  },
};

export const errorSlice = createSlice({
  name: "errorS",
  initialState,
  reducers: {
    setError: (state: ErrorHandleState, action: PayloadAction<ErrorState>) => {
      state.error = action.payload;
    },
  },
});

export const selectError = (state: RootState) => state.errorS.error;

export const { setError } = errorSlice.actions;
export default errorSlice.reducer;
