import { Column, Table } from "web-analysis-lib";
import { ImageDataExtractionDuration } from "../models";
import { WithLoadingPanelHOC } from "../../Generic/HOCs";
import { format } from "../../../schema/Utils";
import { Filter } from "web-analysis-lib/dist/ui-kit/Table/Table";

const tableColumns: Column[] = [
  {
    key: "timeStamp",
    name: "Time Stamp",
    fieldName: "timeStamp",
    minWidth: 150,
    isSortable: true,
    onRender: (item: ImageDataExtractionDuration) =>
      format(new Date(item.timeStamp), "Y-MM-dd HH:mm"),
  },
  {
    key: "fileName",
    name: "File Name",
    fieldName: "fileName",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "overall",
    name: "Overall",
    fieldName: "overall",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "event",
    name: "Event",
    fieldName: "event",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "fastTrend",
    name: "Fast Trend",
    fieldName: "fastTrend",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "short",
    name: "Short",
    fieldName: "short",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "trend",
    name: "Trend",
    fieldName: "trend",
    minWidth: 100,
    isSortable: true,
  },
];

type ImagesDataExtractionTableProps = {
  tableItems: ImageDataExtractionDuration[];
  filters: Filter[];
  isLoading: boolean;
};

/**
 * Gets the images statistics data extraction table component.
 * @param tableItems The table items.
 * @param filters The table search filters
 * @param isLoading Value determining whether the table is in loading state.
 * @returns The images statistics data extraction table component.
 */
const ImagesDataExtractionTable = ({
  tableItems,
  filters,
  isLoading,
}: ImagesDataExtractionTableProps) => {
  return WithLoadingPanelHOC(
    !isLoading,
    <Table
      items={tableItems}
      columns={tableColumns}
      header={{ title: "Images data extraction duration" }}
      hasSelection={false}
      filters={filters}
      persistOpts={{
        key: "images-statistics-table",
        version: 2,
      }}
    />
  );
};

export default ImagesDataExtractionTable;
