import { axiosInstance } from "../..";
import { Corporation, CreateCorporation } from "./models";

export async function list(): Promise<Corporation[]> {
  const response = await axiosInstance.get<Corporation[]>(
    "/meta/read/v1/corporations"
  );
  return response.data;
}

export async function deleteCorporation(
  corpo: Corporation
): Promise<Corporation | any> {
  const urlRequest = "/meta/write/v1/corporations/" + corpo.id;
  const response = await axiosInstance.delete<Corporation | any>(urlRequest);
  if (response.data) return response.data;
  else return response;
}

export async function editCorporation(
  corpo: Corporation
): Promise<Corporation | any> {
  const config = {
    name: corpo.name,
    number: corpo.number,
    id: corpo.id,
  };
  const urlRequest = "/meta/write/v1/corporations/" + corpo.id;
  const response = await axiosInstance.put<Corporation | any>(
    urlRequest,
    config
  );
  if (response.data) return response.data;
  else return response;
}

export async function addCorporation(corpo: CreateCorporation): Promise<any> {
  const config = {
    name: corpo.name,
    number: corpo.number,
  };
  const urlRequest = "/meta/write/v1/corporations";

  const response = await axiosInstance.post<Corporation | any>(
    urlRequest,
    config
  );

  if (response.data) return response.data;
  else return response;
}
