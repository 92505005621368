import { axiosInstance } from "../..";
import {
  RequestWirelessSensorNodeAdd,
  WirelessSensorNode,
  WirelessSensorNodeToEditBulk,
} from "./models";

export async function list(): Promise<WirelessSensorNode[]> {
  const response = await axiosInstance.get<WirelessSensorNode[]>(
    "/meta/read/internal/v1/wirelessSensorNodes"
  );
  return response.data;
}

export async function deleteWirelessSensorNode(
  id: string
): Promise<WirelessSensorNode | any> {
  const urlRequest = "/meta/write/v1/wirelessSensorNodes/" + id;
  const response = await axiosInstance.delete<WirelessSensorNode | any>(
    urlRequest
  );
  if (response.data) return response.data;
  else return response;
}

export interface wirelessBulkNotCommon {
  id: string;
  sensorId: string;
  deviceModel: number;
  deviceClass: number;
  isActive: boolean;
}

export interface wirelessBulkProps {
  sensorIds: wirelessBulkNotCommon[];
  editWireless: WirelessSensorNodeToEditBulk;
}

export async function editBulkWirelessSensorNode(
  props: wirelessBulkProps
): Promise<WirelessSensorNode | any> {
  let results;
  const propsToEditBulk = props.sensorIds.map((sensor) => {
    return {
      sensorNodeId: sensor.sensorId,
      deviceModel: sensor.deviceModel,
      deviceClass: sensor.deviceClass,
      wirelessGatewayId: props.editWireless.wirelessGatewayId,
      id: sensor.id,
      machineId: props.editWireless.machineId,
      isActive: sensor.isActive,
    };
  });

  results = await Promise.all(
    propsToEditBulk.map(async (config) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await editWirelessSensorNode(config);
    })
  );

  return results;
}

export async function editWirelessSensorNode(
  wireless: WirelessSensorNode
): Promise<WirelessSensorNode | any> {
  const urlRequest = "/meta/write/v1/wirelessSensorNodes/" + wireless.id;
  const response = await axiosInstance.put<WirelessSensorNode | any>(
    urlRequest,
    wireless
  );
  if (response.data) return response.data;
  else return response;
}

export async function addWirelessSensorNode(
  wireless: RequestWirelessSensorNodeAdd
): Promise<any> {
  const urlRequest = "/meta/write/v1/wirelessSensorNodes";

  const response = await axiosInstance.post<WirelessSensorNode | any>(
    urlRequest,
    wireless
  );

  if (response.data) return response.data;
  else return response;
}
