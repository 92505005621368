import { Stack, Text } from "@fluentui/react";
import React, { CSSProperties, useState } from "react";
import { ConfirmDialog } from "../Generic/ConfirmDialog";
import {
  D325TableItem,
  D850EcoTableItem,
  D850TableItem,
  DBasicTableItem,
} from "./tableItemModels";
import { DModels } from "./models";
import { DataloggersAPI } from "./api";
import { notification } from "web-analysis-lib";
import { useAppDispatch } from "../../hooks";
import { listDataloggersAsync } from "./reducer";

/**
 * Gets the datalogger delete dialog message component
 * @param entry the datalogger table entry
 * @returns The datalogger delete dialog message component.
 */
const DeleteMessage = (
  model: DModels,
  entry: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem
) => {
  const style: CSSProperties = {
    marginLeft: "3em",
  };

  let messageData: any = { Model: model, Project: entry.projectName };
  switch (model) {
    case DModels.D325:
      let d325Entry = entry as D325TableItem;
      messageData = {
        MacAddress: d325Entry.macAddress,
        Firmware: d325Entry.firmwareVersion,
        ...messageData,
      };

      break;
    case DModels.D555:
    case DModels.D650:
      let dBasicEntry = entry as DBasicTableItem;
      messageData = {
        MacAddress: dBasicEntry.macAddress,
        Firmware: dBasicEntry.firmwareVersion,
        IpAddress: dBasicEntry.ipAddress,
        SubnetMask: dBasicEntry.subnetMask,
        Gateway: dBasicEntry.gateway,
        ...messageData,
      };

      break;

    case DModels.D850Eco:
      let d850EcoEntry = entry as D850EcoTableItem;
      messageData = {
        BasisCardMacAddress: d850EcoEntry.macAddress,
        ...messageData,
      };

      break;

    case DModels.D850:
      let d850Entry = entry as D850TableItem;
      messageData = {
        BasisCardMacAddress: d850Entry.macAddress,
        OperativeSystem: d850Entry.operativeSystem,
        AdjustmentCardType: d850Entry.adjustmentCardType,
        ...messageData,
      };

      break;
  }

  return (
    <Stack>
      <Text>The following datalogger will be permanently deleted:</Text>
      <br />
      {React.Children.map(Object.keys(messageData), (key) => {
        return <Text style={style}>{`${key}: ${messageData[key]}`}</Text>;
      })}
      <br />
      <Text>Do you want to continue?</Text>
    </Stack>
  );
};

export type DataloggerDeleteDialogProps = {
  model: DModels;
  entry: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem;
  onClose: () => void;
};

/**
 * Gets the datalogger delete dialog component.
 * @param model The datalogger model.
 * @param entry The datalogger table item entry
 * @param onClose Event handler that must be implemented to close this dialog.
 * @returns The datalogger delete dialog component.
 */
const DataloggerDeleteDialog = ({
  model,
  entry,
  onClose,
}: DataloggerDeleteDialogProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = () => {
    setIsLoading(true);
    DataloggersAPI.delete(model, entry.id).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          `Failure: Deleting a ${model} datalogger. Please try again later.`
        );
        return;
      }

      notification.success(`Success: Deleting a ${model} datalogger. `);
      dispatch(listDataloggersAsync(model)());
      onClose?.();
    });
  };

  return (
    <ConfirmDialog
      title={"Delete Datalogger"}
      render={DeleteMessage(model, entry)}
      isLoading={isLoading}
      onConfirm={handleConfirm}
      onCancel={() => {
        onClose?.();
      }}
      onClose={() => {
        onClose?.();
      }}
    />
  );
};

export default DataloggerDeleteDialog;
