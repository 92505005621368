import { useState, useEffect } from "react";
import { BaseDialog, notification } from "web-analysis-lib";
import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
  IComboBoxOption,
} from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Status } from "../../schema/status";
import {
  listAsyncCompanies,
  selectCompaniesStatus,
} from "../Companies/reducer";

import { ComboBoxCustom } from "../Generic/ComboBox";
import { WirelessSensorNodeResponse } from "./models";
import {
  editBulkWirelessSensorNode,
  wirelessBulkNotCommon,
  wirelessBulkProps,
} from "./api";

import { selectMachines } from "../Machines/reducer";
import { selectGatewaysProProjectOptions } from "../Gateways/reducer";

type EditBDialogProps = IDialogProps & {
  sensorNodeIds: wirelessBulkNotCommon[];
  options: IComboBoxOption[];
  data: WirelessSensorNodeResponse | null;
  show: boolean;
  onSuccess: (hasError: boolean) => void;
  onClose: () => void;
};

export const EditBulkDialog = ({
  sensorNodeIds,
  options,
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}: EditBDialogProps) => {
  const machines = useAppSelector(selectMachines);
  const [keyModal1, setKeyModal1] = useState<number>(100);
  const [isLoading, setLoading] = useState(false);
  const statusParent = useAppSelector(selectCompaniesStatus);
  const dispatch = useAppDispatch();
  const [idGatewaySelected, setIdGatewaySelected] = useState<string>(
    data && data.wirelessGatewayId !== "00000000-0000-0000-0000-000000000000"
      ? data.wirelessGatewayId
      : undefined
  );
  const [idMachSelected, setIdMachSelected] = useState<string>(
    data && data.machineId !== "00000000-0000-0000-0000-000000000000"
      ? data.machineId
      : undefined
  );
  const [ProjId, setProjId] = useState<string>(data?.projectId);
  const optionsGateways = useAppSelector(
    selectGatewaysProProjectOptions(ProjId)
  );

  useEffect(() => {
    const mach = machines?.find((mac) => mac.id === idMachSelected);
    setProjId(
      mach?.projectId &&
        mach?.projectId !== "00000000-0000-0000-0000-000000000000"
        ? mach?.projectId
        : undefined
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMachSelected]);

  useEffect(() => {
    optionsGateways?.some((gat) => gat.key === idGatewaySelected) &&
      setIdGatewaySelected(idGatewaySelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsGateways]);

  useEffect(() => {
    setKeyModal1(keyModal1 + 1);
    !ProjId && setIdGatewaySelected(undefined);
    ProjId !== data?.projectId && setIdGatewaySelected(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ProjId]);

  useEffect(() => {
    if (statusParent === Status.void) dispatch(listAsyncCompanies());
  }, [dispatch, statusParent]);

  const handleSubmit = async () => {
    setLoading(true);
    const toSend: wirelessBulkProps = {
      sensorIds: sensorNodeIds,
      editWireless: {
        wirelessGatewayId: idGatewaySelected,
        machineId: idMachSelected,
      },
    };

    await editBulkWirelessSensorNode(toSend).then((response) =>
      onSuccess("status" in response)
    );
    !idGatewaySelected && !idMachSelected
      ? notification.warning("Un-parented sensor nodes")
      : !idMachSelected &&
        notification.warning("Sensor nodes, non machine linked yet.");
    handleClose();
  };

  const handleClose = () => {
    // reset state
    setLoading(false);

    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.normal,
        title: data ? "Edit sensor Node" : "Add sensor Node",
        closeButtonAriaLabel: "Close",
        onDismiss: handleClose,
      }}
    >
      <ComboBoxCustom
        key={20}
        label={"Select machine:"}
        keyMe={20}
        options={options}
        selection={setIdMachSelected}
        errorMessage={""}
        defaultKey={idMachSelected}
      />
      {optionsGateways.length > 0 && (
        <ComboBoxCustom
          key={keyModal1}
          label={"Select Gateway:"}
          keyMe={keyModal1}
          options={optionsGateways}
          selection={setIdGatewaySelected}
          errorMessage={""}
          defaultKey={data ? data.wirelessGatewayId : ""}
        />
      )}

      <DialogFooter>
        <PrimaryButton
          type="submit"
          text="Save Changes"
          disabled={isLoading}
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
          onClick={handleSubmit}
        />
        <DefaultButton
          styles={{
            root: { border: "unset", background: "transparent" },
          }}
          text="Cancel"
          onClick={handleClose}
        />
      </DialogFooter>
    </BaseDialog>
  );
};
