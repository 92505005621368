/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect } from "react";
import {
  BaseDialog,
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "web-analysis-lib";
import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
} from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import { maxLengthType1 } from "../../schema/Constants";
import { Corporation } from "./models";
import { editCorporation, addCorporation } from "./api";
import { areObjectsEqual } from "../../schema/Utils";

const getSchema = (corporations: Corporation[]) =>
  z
    .object({
      id: z.string().optional(),
      name: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Name must contain at most ${maxLengthType1} character(s)`,
        }),
      number: z.string().optional(),
    })
    .superRefine((input, ctx) => {
      let issueData: z.IssueData = {
        code: z.ZodIssueCode.custom,
        path: [],
        message: "",
      };

      // Name
      if (input.name && input.name.length > 0) {
        let existingCorporation = corporations.find(
          (c) => c.name.trim().toLowerCase() === input.name.trim().toLowerCase()
        );
        if (existingCorporation) {
          issueData = {
            ...issueData,
            path: ["name"],
            message: "The corporation name already exists.",
          };

          ctx.addIssue(issueData);
        }
      }

      // Number
      if (input.number && input.number.length > 0) {
        let existingCorporation = corporations.find(
          (c) => c.number === input.number.trim()
        );
        if (existingCorporation) {
          issueData = {
            ...issueData,
            path: ["number"],
            message: "The corporation number already exists.",
          };
          ctx.addIssue(issueData);
        }
      }
    });

const corporationFields: FormItemProps[] = [
  {
    name: "name",
    type: FormItemType.TextField,
    groupProps: { label: "Name *" },
  },
  {
    name: "number",
    type: FormItemType.TextField,
    groupProps: { label: "Number" },
  },
];

type AddOrEditDialogProps = IDialogProps & {
  data: Corporation | null;
  items: Corporation[];
  show: boolean;
  onSuccess: (
    hasError: boolean,
    data: Corporation,
    context: "add" | "edit"
  ) => void;
  onClose: () => void;
};

export const AddOrEditDialog = ({
  data,
  items,
  show,
  onSuccess,
  onClose,
  ...rest
}: AddOrEditDialogProps) => {
  const [isLoading, setLoading] = useState(false);
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(
    data === null || data === undefined
  );
  const filteredItems = useMemo(
    () =>
      // we need to exclude selected data from items when editing
      items.filter(({ name }) =>
        data
          ? name.trim().toLowerCase() !== data.name.trim().toLowerCase()
          : true
      ),
    [items, data]
  );
  const schema = useMemo(() => getSchema(filteredItems), [filteredItems]);
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        id: data.id,
        name: data.name,
        number: data.number,
      },
    }),
  });

  useEffect(() => {
    data ? reset(data) : reset({ id: "", name: "", number: "" });
  }, [data, reset]);

  // Checks whether the entity has changed.
  useEffect(() => {
    if (!control) {
      return;
    }

    let areEqual = areObjectsEqual(control._defaultValues, control._formValues);
    setDataHasChanged(!areEqual);
  }, [watch()]);

  // Handlers.
  const onSubmit = handleSubmit(async (formData) => {
    setLoading(true);

    const mutation = data ? editCorporation : addCorporation;

    await mutation(formData as Corporation).then((response) =>
      onSuccess(
        "status" in response,
        formData as Corporation,
        data ? "edit" : "add"
      )
    );

    handleClose();
  });

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.normal,
        title: data ? "Edit new corporation" : "Add new corporation",
        closeButtonAriaLabel: "Close",
        onDismiss: handleClose,
      }}
    >
      <form onSubmit={onSubmit}>
        {renderFormItems(corporationFields, {
          control,
          errors: errors as { [schemaProp: string]: FieldError },
        })}
        <DialogFooter>
          <PrimaryButton
            type="submit"
            text="Save Changes"
            disabled={isLoading || !isValid || !dataHasChanged}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
          <DefaultButton
            styles={{
              root: { border: "unset", background: "transparent" },
            }}
            text="Cancel"
            onClick={handleClose}
          />
        </DialogFooter>
      </form>
    </BaseDialog>
  );
};
