import { useEffect, CSSProperties } from "react";
import { BaseDialog, notification, DialogSize } from "web-analysis-lib";
import {
  IDialogProps,
  DialogType,
  Text,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import { WithOutPermissionsUserExtendedProperties } from "./models";
import { useAppSelector } from "../../hooks";
import { Status } from "../../schema/status";
import { RolesComponentDetails } from "../Roles/RolesComponentDetails";
import { pageStyle } from "../../schema/Constants";
import { selectUsersRBACError, selectUsersRBACStatus } from "./reducer";
import { detailsUsersRBAC } from "./api";

const titleStylePivot: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "50px",
};

type DetailsDialogProps = IDialogProps & {
  data?: WithOutPermissionsUserExtendedProperties;
  show: boolean;
  onSuccess: (hasError: boolean, data: string) => void;
  onClose: () => void;
};

export const DetailsDialogPending = ({
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}: DetailsDialogProps) => {
  const status = useAppSelector(selectUsersRBACStatus);
  const error = useAppSelector(selectUsersRBACError);

  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
  }, [error, status]);

  const handleClose = () => {
    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.close,
        title: "Details User Account",
        closeButtonAriaLabel: "Close",
        onDismiss: handleClose,
      }}
      styles={{
        main: {
          "@media (min-width: 480px)": {
            height: "65vh",
          },
        },
      }}
      size={DialogSize.L}
    >
      <div style={pageStyle}>
        <Pivot aria-label="Pivots to edit service account">
          <PivotItem
            headerText={"Roles"}
            itemKey={"roles"}
            onRenderItemLink={() => <Text style={titleStylePivot}>Roles</Text>}
          >
            {RolesComponentDetails({
              data: undefined,
              memberId: data?.memberId,
              detailsFunction: detailsUsersRBAC,
              onSuccess: () => {
                handleClose();
                onSuccess(false, "");
              },
            })}
          </PivotItem>
        </Pivot>
      </div>
    </BaseDialog>
  );
};
