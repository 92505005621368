import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Status } from "../../../schema/status";
import { RootState } from "../../../store";
import { fetchData } from "./api";
import { ResponseWirelessGatewayDetailsWithChildren } from "../models";
import { WirelessSensorNode } from "../../SensorNodes/models";

export const listAsyncWirelessDetails = createAsyncThunk(
  "wirelessGatewayDetail",
  async (id: string) => {
    const response = await fetchData(id);
    return response;
  }
);

export interface toSortbyKey {
  sort: boolean;
  key: string;
}

export interface WirelessGatewayDetailsState {
  wirelessGatewayDet: ResponseWirelessGatewayDetailsWithChildren;
  status: Status;
  error: string;
}

const initialState: WirelessGatewayDetailsState = {
  wirelessGatewayDet: {
    sensorNodes: [],
    id: "",
    serialNumber: "",
    projectId: "",
    adminPassword: "",
    connectionString: "",
    ltePlanSize: 0,
    userPassword: "",
    publicKey: "",
    rsshPort: 0,
  },
  status: Status.loading,
  error: "",
};

export const wirelessGatewayDetailsSlice = createSlice({
  name: "wirelessGatewayDetails",
  initialState,
  reducers: {
    sortValues: (
      state: WirelessGatewayDetailsState,
      action: PayloadAction<toSortbyKey>
    ) => {
      const key = action.payload.key as keyof WirelessSensorNode;
      state.wirelessGatewayDet.sensorNodes =
        state.wirelessGatewayDet.sensorNodes
          .slice(0)
          .sort((a: WirelessSensorNode, b: WirelessSensorNode) =>
            (
              action.payload.sort
                ? a[key].toString().toLowerCase() <
                  b[key].toString().toLowerCase()
                : a[key].toString().toLowerCase() >
                  b[key].toString().toLowerCase()
            )
              ? 1
              : -1
          );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncWirelessDetails.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncWirelessDetails.fulfilled, (state, action) => {
        state.status = Status.idle;
        state.error = "";
        state.wirelessGatewayDet = action.payload;
      })
      .addCase(listAsyncWirelessDetails.rejected, (state) => {
        state.status = Status.error;
        state.error = "Error loading wirelessGateways. Please try again later.";
      });
  },
});

export const selectWirelessNodes = (state: RootState) =>
  state.wirelessGatewayDetails.wirelessGatewayDet.sensorNodes;

export const selectWirelessDetails = (state: RootState) =>
  state.wirelessGatewayDetails.wirelessGatewayDet;

export const selectWirelessGatewayDetailsStatus = (state: RootState) =>
  state.wirelessGatewayDetails.status;

export const selectWirelessGatewayDetailsError = (state: RootState) =>
  state.wirelessGatewayDetails.error;

export const { sortValues } = wirelessGatewayDetailsSlice.actions;
export default wirelessGatewayDetailsSlice.reducer;
