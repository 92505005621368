/* eslint-disable react-hooks/exhaustive-deps */
import {
  DefaultButton,
  DialogFooter,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import FormDialog from "../generic/FormDialog";
import { useContext, useEffect, useState } from "react";
import {
  AutomationStatusType,
  ConnectionStatusType,
  ConnectionEntryDetailed,
  MetadataVpnPC,
  MetadataProject,
} from "../../Schema/models";
import { FormItemRow, FormItemSection } from "../generic/FormDialogComponents";
import { DialogSize, notification } from "web-analysis-lib";
import { AxiosContext } from "../../VpnConnectionsManager/VpnConnectionsManager";
import { WithLoadingPanelHOC } from "../generic/HOCs";
import { VpnPCsAPI } from "../../Schema/api";
import React from "react";

type ConnectionDetailsDialogProps = {
  projects: MetadataProject[];
  item: ConnectionEntryDetailed;
  onEdit: (data: ConnectionEntryDetailed) => void;
  onClose: () => void;
};

type ConnectionDetailsDataProps = {
  item: ConnectionEntryDetailed;
  projects: MetadataProject[];
  vpnPCs: MetadataVpnPC[];
};

type VpnConnectionDetailsData = {
  projectName: string;
  companyName: string;
  country: string;
  vpnPcName: string;
  automationStatus: string;
  automationStatusComments: string;
  connectionStatus: string;
  connectionStatusComments: string;
  connectionDetails: string;
};

/**
 * Gets the item details data.
 * @param item The detailed VPN Connection entry to convert.
 * @param projects The projects reference list.
 * @param vpnPCs The VPN PCs reference list.
 * @returns The Connection details data object.
 */
const getDetailsData = ({
  item,
  projects,
  vpnPCs,
}: ConnectionDetailsDataProps): VpnConnectionDetailsData => {
  let result: VpnConnectionDetailsData = {
    projectName: "Project not found.",
    companyName: "",
    country: "Country not found.",
    vpnPcName: "",
    automationStatus: AutomationStatusType[item.automationStatus.status],
    automationStatusComments: item.automationStatus.comment || "",
    connectionStatus: ConnectionStatusType[item.connectionStatus.status],
    connectionStatusComments: item.connectionStatus.comment || "",
    connectionDetails: item.connectionDetails || "",
  };

  // Gets VPN PC information.
  let vpnPC = vpnPCs.find((pc) => pc.id === item.vpnPcId);
  result = { ...result, vpnPcName: vpnPC?.name || "" };

  // Gets project information.
  let project = projects.find((p) => p.id === item.projectId);
  if (!project) {
    return result;
  }

  result = {
    ...result,
    projectName: project.name,
    country: project.country || "",
    companyName: project.company?.name || "",
  };

  return result;
};

/**
 * Gets the connection details dialog.
 * @param companies The companies list.
 * @param projects The projects list.
 * @param item The VPN connection table item
 * @param onEdit The method called when the Edit button is clicked. Use it to display the Edit VPN Connection Dialog.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns
 */
const ConnectionDetailsDialog = ({
  projects,
  item,
  onEdit,
  onClose,
}: ConnectionDetailsDialogProps) => {
  const axiosInstance = useContext(AxiosContext);
  const [data, setData] = useState<VpnConnectionDetailsData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Gets the VPN PCs and builds the detailed item.
  useEffect(() => {
    if (!axiosInstance) {
      onClose?.();
      return;
    }

    setIsLoading(true);
    VpnPCsAPI.list(axiosInstance).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(`Failure getting VPN PCs: ${response.statusText}.`);
        onClose?.();
        return;
      }

      let dialogData = getDetailsData({
        item: item,
        projects: projects,
        vpnPCs: response.data,
      });

      setData(dialogData);
    });
  }, [item, axiosInstance]);

  return WithLoadingPanelHOC(
    !isLoading,
    <FormDialog
      title="VPN Connection Details"
      size={DialogSize.S}
      onClose={onClose}
    >
      <Stack verticalAlign="center">
        <FormItemSection title={"General Details"}>
          <FormItemRow label={"Project:"}>
            <p className="label-value-text">{data?.projectName || ""}</p>
          </FormItemRow>
          <FormItemRow label={"Company:"}>
            <p className="label-value-text">{data?.companyName || ""}</p>
          </FormItemRow>
          <FormItemRow label={"Country:"}>
            <p className="label-value-text">{data?.country || ""}</p>
          </FormItemRow>
          <FormItemRow label={"VPN PC:"}>
            <p
              className="label-value-text"
              style={{ color: data?.vpnPcName ? "rgb(50, 49, 48)" : "red" }}
            >
              {data?.vpnPcName || "Unassigned"}
            </p>
          </FormItemRow>
        </FormItemSection>
        <FormItemSection title={"Automation Status"}>
          <FormItemRow label={"Status:"}>
            <p className="label-value-text">{data?.automationStatus || ""}</p>
          </FormItemRow>
          <FormItemRow label={"Comments:"}>
            <p className="label-value-text">
              {data?.automationStatusComments || ""}
            </p>
          </FormItemRow>
        </FormItemSection>
        <FormItemSection title={"Connection Status"}>
          <FormItemRow label={"Status:"}>
            <p className="label-value-text">{data?.connectionStatus || ""}</p>
          </FormItemRow>
          <FormItemRow label={"Comments:"}>
            <p className="label-value-text">
              {data?.connectionStatusComments || ""}
            </p>
          </FormItemRow>
        </FormItemSection>
        <FormItemSection title={"Connection Details"}>
          <TextField
            multiline
            rows={14}
            resizable={false}
            readOnly
            value={data?.connectionDetails || ""}
          />
        </FormItemSection>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          className="primary-button"
          onClick={onClose}
          text="Done"
        />
        <DefaultButton
          className="secondary-button"
          onClick={() => onEdit(item)}
          text="Edit Details"
        />
      </DialogFooter>
    </FormDialog>
  );
};

export default ConnectionDetailsDialog;
