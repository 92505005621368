import { RootState } from "../../store";

export const selectUserAccountMemberID = (state: RootState) => state?.userAccount?.response?.memberId;

export const selectUserAccountProfilePicture = (state: RootState) => state?.userAccount?.response?.profilePicture;

export const selectUserAccountInitialProfilePicture = (state: RootState) => state?.userAccount?.initialData?.profilePicture;

export const hasProfilePictureChanged = (state: RootState) => state?.userAccount?.response?.profilePicture !== state?.userAccount?.initialData?.profilePicture;

export const selectAllUserAccountProperties = (state: RootState) => {
    const userAccountProperties = state?.userAccount?.response
    const payload = Object.keys(userAccountProperties).reduce((acc, curr)=>{
        if(curr !== 'profilePicture'){
            acc[curr] = userAccountProperties[curr]
        }

        return acc
    }, {})
    
    return payload
};

export const selectUserAccountContactProperties = (state: RootState) => {
    const contactKeys = [
        'email',
        'mobilePhone',
        'countryOrRegion',
        'stateOrProvince',
        'city',
        'streetAddress',
        'zipOrPostalCode',
    ]
    
    const contactProperties = contactKeys.reduce((acc, curr) => {
        acc[curr] = state.userAccount?.response[curr] || ''

        return acc
    }, {})

    return contactProperties
}

type accountInfo = {
    'firstName':string;
    'lastName':string;
    'displayName':string;
    'companyName':string;
    'department':string;
    'jobTitle':string;
}
export const selectUserAccountInformationProperties = (state: RootState): accountInfo => {
    const informationKeys = [
        'firstName',
        'lastName',
        'displayName',
        'companyName',
        'department',
        'jobTitle',
    ]
    
    const infomationProperties = informationKeys.reduce((acc, curr) => {
        acc[curr] = state.userAccount?.response[curr] || ''

        return acc
    }, {} as accountInfo)

    return infomationProperties
}
