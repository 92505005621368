export enum AutomationStatusType {
  Unknown = "Unknown",
  Automated = "Automated",
  SemiAutomated = "Semi-Automated",
  AutomationPossible = "Automation possible",
  AutomationNotPossible = "Automation not possible",
}

export enum ConnectionStatusType {
  Unknown = "Unknown",
  NotConfigured = "Not configured",
  Working = "Working",
  NotWorking = "Not working",
}

export type MetadataCompany = {
  id: string;
  name: string;
};

export type MetadataProject = {
  id: string;
  name: string;
  country?: string;
  company?: MetadataCompany;
};

export type MetadataVpnPC = {
  id?: string;
  name: string;
};

export type AutomationStatus = {
  status: keyof typeof AutomationStatusType;
  comment?: string;
};

export type ConnectionStatus = {
  status: keyof typeof ConnectionStatusType;
  comment?: string;
};

export type ConnectionEntryBasic = {
  id?: string;
  projectId: string;
  automationStatus: AutomationStatus;
  connectionStatus: ConnectionStatus;
  projectName?: string;
};

export type ConnectionEntryDetailed = ConnectionEntryBasic & {
  vpnPcId: string;
  connectionDetails?: string;
  emailDetails?: string;
};

export type ConnectionTableItem = {
  id: string;
  projectName: string;
  companyName: string;
  automationStatus: string;
  connectionStatus: string;
  comments?: string;
};

export type HistoricalChangesItemBasic = {
  id: string;
  vpnConnectionId: string;
  timeStamp: string;
  changedBy?: string;
};

export type HistoricalChangesItemDetailed = HistoricalChangesItemBasic & {
  projectName: string;
  automationStatus: AutomationStatus;
  connectionStatus: ConnectionStatus;
  connectionDetails?: string;
  emailDetails?: string;
};
