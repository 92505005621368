import { AxiosResponse } from "axios";
import { axiosInstance } from "../..";
import {
  AddEditDashboardFormData,
  PowerBIEmbedItem,
  RequestPowerBIAddOrUpdate,
  ResponsePowerBIGet,
} from "./models";

export async function detailsDashboard(
  item: PowerBIEmbedItem
): Promise<ResponsePowerBIGet> {
  const path: string =
    "meta/powerbi/v1/" + item.parentType.toLowerCase() + `/${item.parentId}`;
  const response = await axiosInstance.get<ResponsePowerBIGet>(path);

  return response.data;
}

export async function list(): Promise<PowerBIEmbedItem[]> {
  const response = await axiosInstance.get<PowerBIEmbedItem[]>(
    "/meta/powerbi/v1/"
  );
  return response.data;
}

export async function deleteDashboard(
  id: string
): Promise<PowerBIEmbedItem | any> {
  const urlRequest = "/meta/powerbi/v1/" + id;
  const response = await axiosInstance.delete<PowerBIEmbedItem | any>(
    urlRequest
  );
  if (response.data) return response.data;
  else return response;
}

export async function editDashboard(
  dash: RequestPowerBIAddOrUpdate
): Promise<PowerBIEmbedItem | any> {
  const config = {
    id: dash.id,
    groupId: dash.groupId,
    parentId: dash.parentId,
    parentType: dash.parentType,
  };
  const urlRequest = "/meta/powerbi/v1/";
  const response = await axiosInstance.post<PowerBIEmbedItem | any>(
    urlRequest,
    config
  );
  if (response.data) return response.data;
  else return response;
}

export async function addDashboard(
  dash: RequestPowerBIAddOrUpdate
): Promise<any> {
  const config = {
    id: dash.id,
    groupId: dash.groupId,
    parentId: dash.parentId,
    parentType: dash.parentType,
  };
  const urlRequest = "/meta/powerbi/v1/";

  const response = await axiosInstance.post<PowerBIEmbedItem | any>(
    urlRequest,
    config
  );

  if (response.data) return response.data;
  else return response;
}

export class DashboardMetaDataAPI {
  static createOrUpdateDashboard = async (
    body: AddEditDashboardFormData
  ): Promise<AxiosResponse<AddEditDashboardFormData, any>> => {
    const url = "/meta/powerbi/v1/";
    let response: AxiosResponse<AddEditDashboardFormData, any> = {
      status: 0,
      statusText: "",
      config: null,
      headers: null,
      data: null,
    };

    // Sends the request.
    try {
      response = await axiosInstance.post<AddEditDashboardFormData>(url, body);
    } catch (err) {
      response = {
        ...response,
        statusText: `Exception caught: ${err}`,
      };
    }

    return response;
  };
}
