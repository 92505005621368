import {
  BaseDialog,
  Column,
  FormItemProps,
  FormItemType,
  Table,
  TableProps,
  renderFormItems,
  useZodForm,
  Uploader,
} from "web-analysis-lib";
import { ZodSchema, z } from "zod";
import { useEffect, useMemo, useState } from "react";
import { AcceptedFile } from "web-analysis-lib/dist/ui-kit/Uploader/Uploader";
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  IconButton,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Link,
} from "@fluentui/react";
import type { FieldError } from "react-hook-form";
import { formatFileSize } from "../../schema/Utils";
import { CreateFile } from "../FIles/models";

const dialogStyles = {
  root: { height: "75%" },
};

const defaultFields: FormItemProps[] = [
  {
    name: "description",
    type: FormItemType.TextField,
    groupProps: { label: "Description" },
  },
];

type GetColumnsOpts = {
  onRemove: (id: string) => void;
};

export const getColumns = ({ onRemove }: GetColumnsOpts): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: "Name",
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: ({ name }) => {
      // TODO: should we use Link from react-router-dom?
      return (
        <Link underline href="#" style={{ fontSize: 13 }}>
          {name}
        </Link>
      );
    },
  },
  {
    key: "size",
    fieldName: "size",
    name: "Size",
    minWidth: 100,
    isSortable: true,
    onRender: ({ size, isValid }) => {
      return (
        <span
          style={{ fontSize: 13, color: isValid ? "currentColor" : "#ff6a66" }}
        >
          {isValid ? formatFileSize(size) : "Too large"}
        </span>
      );
    },
  },
  {
    fieldName: "actions",
    key: "actions",
    name: "Actions",
    minWidth: 68,
    onRender: ({ id }) => (
      <IconButton
        iconProps={{ iconName: "Delete" }}
        title="Delete"
        ariaLabel="Delete"
        styles={{
          root: {
            verticalAlign: "middle",
            color: "#0078d4",
            width: "100%",
            height: 16,
            padding: 0,
            margin: "0 auto",
          },
          rootHovered: {
            background: "transparent",
          },
          rootDisabled: {
            background: "transparent",
          },
        }}
        onClick={() => onRemove(id)}
      />
    ),
  },
];

const defaultSchema = z.object({
  description: z
    .string()
    .max(200, { message: "Description must contain at most 200 character(s)" })
    .optional(),
});

type DocumentDialogFieldsSchema = {
  schema: ZodSchema;
  fields: FormItemProps[];
};

type AddDocumentDialogProps = {
  hidden: boolean;
  onClose: () => void;
  onSubmit: (files: CreateFile[]) => void;
  formFields?: DocumentDialogFieldsSchema;
  accept: string;
};

const AddDocumentDialog = ({
  hidden,
  onClose,
  onSubmit,
  formFields = { fields: defaultFields, schema: defaultSchema },
  accept,
}: AddDocumentDialogProps) => {
  const [localFiles, setLocalFiles] = useState<AcceptedFile[]>([]);
  const { fields, schema } = formFields;

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-documents-dialog",
        version: 1,
      },
      items: localFiles,
      perPage: 5,
      hidePerPage: true,
      hasSelection: false,
      columns: getColumns({
        onRemove: (fileId) => {
          setLocalFiles((prev) => [...prev.filter(({ id }) => id !== fileId)]);
        },
      }),
    }),
    [localFiles]
  );

  useEffect(() => {
    if (hidden) {
      reset();
      setLocalFiles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden]);

  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
  });

  const onFormSubmit = handleSubmit(async (data: any) => {
    const files = localFiles.map((file) => ({ ...file, ...data }));
    await onSubmit(files);
    onClose();
  });

  const isDisabled = useMemo(
    () =>
      isSubmitting ||
      !isValid ||
      localFiles.filter(({ isValid }) => isValid).length === 0,
    [isSubmitting, isValid, localFiles]
  );

  const onUpload = (accepted: AcceptedFile[]) => {
    setLocalFiles((prev: AcceptedFile[]) => [...accepted, ...prev]);
  };

  return (
    <BaseDialog
      hidden={hidden}
      styles={dialogStyles}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Add new document",
      }}
    >
      <form onSubmit={onFormSubmit}>
        {renderFormItems(fields, {
          control,
          errors: errors as { [schemaProp: string]: FieldError },
        })}
        <Uploader onChange={onUpload} accept={accept} />
        <Table {...tableProps} />
        <DialogFooter>
          <PrimaryButton
            text="Upload"
            type="submit"
            disabled={isDisabled}
            onRenderIcon={() =>
              isSubmitting ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
          <DefaultButton text="Cancel" onClick={onClose} />
        </DialogFooter>
      </form>
    </BaseDialog>
  );
};

export default AddDocumentDialog;
