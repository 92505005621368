import * as React from "react";
import { Icon } from "@fluentui/react";
import "./ChevronItemWithChildren.css";

interface ChevronItemWithChildrenProps {
  name: string;
  parentIconName: string;
  chevron?: boolean;
  onClick?: () => void;
  pending?: boolean;
}

const ChevronItemWithChildren = ({
  name,
  parentIconName,
  chevron = true,
  onClick,
  children,
  pending = false,
}: React.PropsWithChildren<ChevronItemWithChildrenProps>) => {
  const [expanded, setExpanded] = React.useState(true);

  const handleToggleExpand = () => {
    onClick();
    setExpanded(!expanded);
  };

  return (
    <div key={name + "sub-chevron"}>
      <div
        className={`toggle-header ${expanded ? "expanded" : "collapsed"}`}
        onClick={handleToggleExpand}
      >
        <Icon
          iconName={pending ? parentIconName + "Coral" : parentIconName}
          style={{ marginRight: "8px" }}
        />

        <span>{name}</span>
        {chevron && (
          <Icon
            iconName={expanded ? "ChevronDown" : "ChevronRight"}
            style={{ marginLeft: "auto" }}
          />
        )}
      </div>
      <div className={`toggle-content ${expanded ? "expanded" : "collapsed"}`}>
        {expanded && (
          <>
            <div style={{ marginLeft: 20 }}>{children}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChevronItemWithChildren;
