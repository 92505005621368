/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { UseFormWatch } from "react-hook-form";
import { areObjectsEqual } from "../schema/Utils";

/**
 * Gets the use Zod Watch hook to monitor whether a form data has changed.
 * @param defaultValues The form default values.
 * @param formValues The current form values.
 * @param watch The Zod watch function.
 * @param defaultValue The default hook value.
 * @returns The data has changed boolean, indicating whether the form data has changed.
 */
export const useZodWatch = <T extends object>(
  defaultValues: Partial<T>,
  formValues: Partial<T>,
  watch: UseFormWatch<any>,
  defaultValue?: boolean
) => {
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(
    defaultValue || false
  );

  // Compares and returns the result.
  useEffect(() => {
    if (!defaultValues || !formValues) {
      return;
    }

    let areEqual = areObjectsEqual(defaultValues, formValues);
    setDataHasChanged(!areEqual);
  }, [watch()]);

  return { dataHasChanged };
};
