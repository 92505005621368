import {
  ComboBox,
  IComboBoxOption,
  IComboBoxStyles,
  IComboBox,
} from "@fluentui/react";
import React, { useState } from "react";

const comboBoxStyles: Partial<IComboBoxStyles> = {
  // root: { maxWidth: 400, minWidth: 50 },
};

export interface propsCustomComboBox {
  keyMe: number;
  label: string;
  options: IComboBoxOption[];
  selection: React.Dispatch<React.SetStateAction<string>>;
  errorMessage: string;
  defaultKey: string;
  disabled?: boolean;
}

export const ComboBoxCustom: React.FunctionComponent<propsCustomComboBox> = (
  propsMain: propsCustomComboBox
) => {
  const comboBoxRef = React.useRef<IComboBox>(null);
  const [optionsToShow, setOptionsToShow] = useState<IComboBoxOption[]>(
    propsMain.options
  );

  const [key, setKey] = useState<number>(propsMain.keyMe);
  const handleOnChange = (text: string) => {
    const auxToShow = propsMain.options.filter(
      (i) => i.text?.toLowerCase().indexOf(text.toLowerCase()) > -1
    );
    setOptionsToShow(auxToShow);
    comboBoxRef.current?.focus(true);
  };

  const onChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ): void => {
    if (index !== undefined) {
      const match = propsMain.options.some((item) => {
        if (item.key === optionsToShow[index].key.toString()) {
          return true;
        }
        return false;
      });

      if (match) {
        propsMain.selection(optionsToShow[index].key.toString());
      } else {
        propsMain.selection(undefined);
      }
    } else {
      propsMain.selection(undefined);
      setKey((prev) => prev + 1);
    }
  };

  return (
    <ComboBox
      key={key}
      componentRef={comboBoxRef}
      label={propsMain.label}
      allowFreeform={true}
      autoComplete="off"
      options={optionsToShow}
      styles={comboBoxStyles}
      onInputValueChange={handleOnChange}
      onChange={onChange}
      errorMessage={propsMain.errorMessage}
      defaultSelectedKey={
        key === propsMain.keyMe ? propsMain.defaultKey : undefined
      }
      disabled={propsMain.disabled ? propsMain.disabled : false}
    />
  );
};
