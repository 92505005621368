/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
  notification,
  DialogSize,
} from "web-analysis-lib";
import { IDialogProps, DialogType, Text } from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import { maxLengthType1 } from "../../schema/Constants";
import {
  CreateMachine,
  MachineToList,
  ResponseMachineDetailsWithChilds,
  UpdateMachine,
} from "./models";
import { areObjectsEqual } from "../../schema/Utils";
import FormItemRow from "../Generic/FormItemRow";
import ControlledComboBox from "../Generic/ControlledComboBox";
import { getMachineDetails } from "./MachineDetails/api";
import FormDialog from "../Generic/FormDialog";
import { createMachine, updateMachine } from "./api";
import { useAppDispatch } from "../../hooks";
import { listAsyncMachines } from "./reducer";
import { ResponseProjectDetails } from "../Projects/models";
import { useProjects } from "../../Hooks/useProjects";
import { Status } from "../../schema/status";

type MachineFormData = Partial<UpdateMachine> & {
  gearBoxManufacturer: string;
  gearBoxName: string;
  gearBoxPower: string;
  gearBoxRotationalSpeed: string;
  gearBoxSerialnumber: string;
  gearBoxNotation: string;
};

type BasicFormProps = IDialogProps & {
  onClose: () => void;
};

type AddEditDialogProps = BasicFormProps & {
  title: string;
  data?: MachineFormData;
  schema: any;
  isLoading: boolean;
  onSubmit: (formData: MachineFormData) => void;
};

type AddDialogProps = BasicFormProps & {
  dalogIds: string[];
};

type EditDialogProps = AddDialogProps & {
  tableItem: MachineToList;
};

const numberRegex = /^\d*\.?\d*$/;

const machineFields: FormItemProps[] = [
  {
    name: "dalogId",
    type: FormItemType.TextField,
    groupProps: { label: "Dalog Id *" },
    disabled: false,
  },
  {
    name: "name",
    type: FormItemType.TextField,
    groupProps: { label: "Name" },
    disabled: false,
  },
  {
    name: "customerCode",
    type: FormItemType.TextField,
    groupProps: { label: "Customer Code" },
    disabled: false,
  },
  {
    name: "manufacturer",
    type: FormItemType.TextField,
    groupProps: { label: "Manufacturer" },
    disabled: false,
  },
  {
    name: "process",
    type: FormItemType.TextField,
    groupProps: { label: "Process" },
    disabled: false,
  },
  {
    name: "type",
    type: FormItemType.TextField,
    groupProps: { label: "Type" },
    disabled: false,
  },
  {
    name: "notation",
    type: FormItemType.TextField,
    groupProps: { label: "Notation" },
    disabled: false,
  },
  {
    name: "gearBoxManufacturer",
    type: FormItemType.TextField,
    groupProps: { label: "Gearbox Manufacturer" },
    disabled: false,
  },
  {
    name: "gearBoxName",
    type: FormItemType.TextField,
    groupProps: { label: "Gearbox Name" },
    disabled: false,
  },
  {
    name: "gearBoxPower",
    type: FormItemType.TextField,
    groupProps: { label: "Gearbox Power" },
    disabled: false,
  },
  {
    name: "gearBoxRotationalSpeed",
    type: FormItemType.TextField,
    groupProps: { label: "Gearbox Rotational Speed" },
    disabled: false,
  },
  {
    name: "gearBoxSerialnumber",
    type: FormItemType.TextField,
    groupProps: { label: "Gearbox Serial Number" },
    disabled: false,
  },
  {
    name: "gearBoxNotation",
    type: FormItemType.TextField,
    groupProps: { label: "Gearbox Notation" },
    disabled: false,
  },
];

const getSchema = (dalogIds: string[]) =>
  z
    .object({
      dalogId: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Serial Number must contain at most ${maxLengthType1} character(s)`,
        })
        .regex(/^[0-9]{2}-[0-9]{3}-[0-9]{2}$/)
        .transform((e) => (e === "" ? "" : e)),
      name: z.string().optional(),
      customerCode: z.string().optional(),
      manufacturer: z.string().optional(),
      process: z.string().optional(),
      type: z.string().optional(),
      notation: z.string().optional(),
      gearBoxManufacturer: z.string().optional(),
      gearBoxName: z.string().optional(),
      gearBoxPower: z.string().optional(),
      gearBoxRotationalSpeed: z.string().optional(),
      gearBoxSerialnumber: z.string().optional(),
      gearBoxNotation: z.string().optional(),
    })
    .superRefine((machine, ctx) => {
      // Unique machine number
      if (
        dalogIds.findIndex(
          (dalogId) =>
            dalogId.trim().toLowerCase() ===
            machine.dalogId.trim().toLowerCase()
        ) !== -1
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["dalogId"],
          message: "The dalog Id already exists",
        });
      }

      // Validates gearbox numbers
      const valid = new RegExp(numberRegex);
      let isNumber = valid.test(machine.gearBoxPower);
      if (!isNumber) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["gearBoxPower"],
          message: "Try a number",
        });
      }

      isNumber = valid.test(machine.gearBoxRotationalSpeed);
      if (!isNumber) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["gearBoxRotationalSpeed"],
          message: "Try a number",
        });
      }
    });

const buildFormData = (
  data: ResponseMachineDetailsWithChilds | null
): MachineFormData => {
  let result: MachineFormData = {
    dalogId: data?.dalogId || "",
    projectId: data?.projectId || "",
    name: data?.name || "",
    customerCode: data?.customerCode || "",
    manufacturer: data?.manufacturer || "",
    process: data?.process || "",
    type: data?.type || "",
    notation: data?.notation || "",
    gearBoxManufacturer: data?.gearbox?.manufacturer || "",
    gearBoxName: data?.gearbox?.name || "",
    gearBoxPower: data?.gearbox?.power?.toString() || "",
    gearBoxRotationalSpeed: data?.gearbox?.rotationalSpeed?.toString() || "",
    gearBoxSerialnumber: data?.gearbox?.serialnumber || "",
    gearBoxNotation: data?.gearbox?.notation || "",
  };

  if (data) {
    result = { ...result, id: data.id };
  }

  return result;
};

export const MachineAddDialog = ({
  dalogIds,
  onClose,
  ...rest
}: AddDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const schema = getSchema(dalogIds);
  const dispatch = useAppDispatch();

  const onSubmit = (formData: MachineFormData) => {
    const toSend: CreateMachine = {
      ...formData,
      dalogId: formData.dalogId,
      projectId: formData.projectId,
      gearbox: {
        manufacturer: formData.gearBoxManufacturer,
        name: formData.gearBoxName,
        notation: formData.gearBoxNotation,
        power: Number(formData.gearBoxPower) as number,
        rotationalSpeed: Number(formData.gearBoxRotationalSpeed) as number,
        serialnumber: formData.gearBoxSerialnumber,
      },
    };

    // Sends the request.
    setIsLoading(true);
    createMachine(toSend).then((response) => {
      setIsLoading(false);
      if (response.status !== 201) {
        notification.error("Failure: Adding new machine.");
        return;
      }

      notification.success("Success: Adding new machine.");
      notification.warning(
        "The machine creation process will take around one minute to finish. Please refresh this page after the mentioned time."
      );
      dispatch(listAsyncMachines());
      onClose?.();
    });
  };

  return (
    <AddEditDialog
      {...rest}
      title={"Add New Machine"}
      data={buildFormData(null)}
      schema={schema}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export const MachineEditDialog = ({
  tableItem,
  dalogIds,
  onClose,
  ...rest
}: EditDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<MachineFormData>(null);
  const dispatch = useAppDispatch();
  const schema = getSchema(
    dalogIds.filter((item) => item !== tableItem?.dalogId)
  );
  let root = document.getElementById("root");

  // Fetches the machine entry data.
  useEffect(() => {
    let data: MachineFormData = buildFormData(null);
    if (!tableItem || !tableItem.id) {
      setFormData(data);
      return;
    }

    // Gets the machine details
    root.style.cursor = "wait";
    getMachineDetails(tableItem.id).then((response) => {
      if (!response) {
        notification.error("Failure: Getting machine details.");
        onClose?.();
        return;
      }

      let machineDetails: ResponseMachineDetailsWithChilds = { ...response };
      data = buildFormData(machineDetails);
      setFormData(data);
    });
  }, [tableItem]);

  const onSubmit = (formData: MachineFormData) => {
    const toSend: UpdateMachine = {
      ...formData,
      id: tableItem.id,
      projectId: formData.projectId,
      gearbox: {
        manufacturer: formData.gearBoxManufacturer,
        name: formData.gearBoxName,
        notation: formData.gearBoxNotation,
        power: Number(formData.gearBoxPower) as number,
        rotationalSpeed: Number(formData.gearBoxRotationalSpeed) as number,
        serialnumber: formData.gearBoxSerialnumber,
      },
      dalogId: formData.dalogId,
    };

    // Sends the request.
    setIsLoading(true);
    updateMachine(toSend).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error("Failure: Updating machine.");
        return;
      }

      notification.success("Success: Updating machine.");
      dispatch(listAsyncMachines());
      onClose?.();
    });
  };

  // Changes the cursor pointer
  if (formData) {
    root.style.cursor = "default";
  }

  return (
    formData && (
      <AddEditDialog
        {...rest}
        title={"Edit Machine"}
        data={formData}
        schema={schema}
        isLoading={isLoading}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    )
  );
};

const AddEditDialog = ({
  title,
  data,
  schema,
  isLoading,
  onSubmit,
  onClose,
  ...rest
}: AddEditDialogProps) => {
  const { projects, status } = useProjects();
  const [selectedProject, setSelectedProject] = useState<
    ResponseProjectDetails | undefined
  >(undefined);

  const [isFormChanged, setIsFormChanged] = useState(!data);
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...{ defaultValues: { ...data } },
  });

  // Creates the projects map
  useEffect(() => {
    if (status !== Status.idle || projects.size === 0) {
      return;
    }

    setSelectedProject(projects.get(data.projectId));
  }, [status, projects.size]);

  // Checks whether the data has changed, compared to the initial data.
  useEffect(() => {
    if (!data) {
      return;
    }

    let formDataHasChanged =
      !areObjectsEqual(control._formValues, control._defaultValues) ||
      data.projectId !== selectedProject?.id;
    setIsFormChanged(formDataHasChanged);
  }, [watch()]);

  const onSubmitHandler = (formData: MachineFormData) => {
    let data: MachineFormData = { ...formData, projectId: selectedProject?.id };
    onSubmit?.(data);
  };

  return (
    <FormDialog
      {...rest}
      title={title}
      isLoading={isLoading}
      isValid={isValid && selectedProject && isFormChanged}
      type={DialogType.normal}
      size={DialogSize.M}
      onSubmit={handleSubmit(onSubmitHandler)}
      onClose={onClose}
    >
      <FormItemRow label={"Company"} style={{ marginBottom: "0.75em" }}>
        <Text variant="medium" as={"p"} style={{ fontWeight: 600 }}>
          {selectedProject?.company?.name}
        </Text>
      </FormItemRow>
      <FormItemRow label="Project *">
        <ControlledComboBox
          options={Array.from(projects.values()).map((project) => {
            return {
              key: project.id,
              text: project.name,
            };
          })}
          selectedKey={selectedProject?.id ?? ""}
          disabled={false}
          onKeySelected={(key: string) => setSelectedProject(projects.get(key))}
        />
      </FormItemRow>
      {renderFormItems(machineFields, {
        control,
        errors: errors as { [schemaProp: string]: FieldError },
      })}
    </FormDialog>
  );
};
