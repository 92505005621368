import { CommandBarButton, Stack } from "@fluentui/react";
import { CSSProperties } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const buttonStyle: CSSProperties = {
  textAlign: "start",
  width: "100%",
  padding: "0.5em 0.2em 0.5em 0em",
};

export const UserMenu = ({
  handleDismiss,
}: {
  handleDismiss: (value) => void;
}) => {
  const navigate = useNavigate();
  const { logout } = useAuth0();

  return (
    <>
      <Stack tokens={{ childrenGap: 5 }}>
        <Stack.Item>
          <CommandBarButton
            iconProps={{ iconName: "Settings" }}
            text="Settings"
            onClick={() => {
              navigate("settings");
              handleDismiss(false);
            }}
            style={buttonStyle}
          />
        </Stack.Item>
        <Stack.Item>
          <CommandBarButton
            iconProps={{ iconName: "SignOut" }}
            text="Sign out"
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
            style={buttonStyle}
          />
        </Stack.Item>
      </Stack>
    </>
  );
};
