import { CSSProperties } from "react";

export const boxShadowStyle: CSSProperties = {
  boxShadow:
    "0px 3.200000047683716px 7.199999809265137px 0px rgba(0, 0, 0, 0.13), 0px 0.6000000238418579px 1.7999999523162842px 0px rgba(0, 0, 0, 0.1)",
};

export const paddingStyle: CSSProperties = {
  padding: "0.5em 0em 0em 1em",
};

export const titleStyle: CSSProperties = {
  fontSize: "18px",
  fontWeight: "600",
  color: "rgb(50, 49, 48)",
};

export const subtitleStyle: CSSProperties = {
  fontSize: "16px",
  fontWeight: "600",
  color: "rgb(50, 49, 48)",
};

export const textFieldStyle: CSSProperties = {
  ...boxShadowStyle,
  overflow: "scroll",
  maxHeight: "5em",
};
