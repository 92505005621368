import Tabs from "./Tabs";

const TabContent = ({ tabArray, activeTab, hasExtraClass }) => {
  return (
    <div
      className={`settings-tab-content ${
        hasExtraClass ? "settings-nested-tabs" : ""
      }`}
    >
      {tabArray.map((tab, index) => (
        <div
          key={index}
          className={`settings-tab-pane ${index === activeTab ? "active" : ""}`}
        >
          {index === activeTab ? (
            Array.isArray(tab.content) ? (
              <Tabs
                tabs={tab.content}
                hasExtraClass={false}
                hasHeaderContent={false}
              />
            ) : (
              <div className="settings-tab-pane-content">{tab.content}</div>
            )
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default TabContent;
