import { ComboBox, IComboBox, IComboBoxOption } from "@fluentui/react";
import React from "react";
import { useEffect, useState } from "react";

type ControlledComboBoxProps = {
  options: IComboBoxOption[];
  initialKey: string;
  disabled: boolean;
  onKeySelected: (key: string) => void;
};

/**
 * Gets a controlled combo box component.
 * @param options The combo box available options.
 * @param initialKey The initial key.
 * @param disabled Value indicating whether the combo box must be disabled.
 * @param onKeySelected Function called when a new key is selected.
 * @returns The controlled combo box component
 */
const ControlledComboBox = ({
  options,
  initialKey,
  disabled,
  onKeySelected,
}: ControlledComboBoxProps) => {
  const [selectedKey, setSelectedKey] = useState<string>(initialKey);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // Sets the selected key.
  useEffect(() => {
    setSelectedKey(initialKey);
  }, [initialKey]);

  // Handlers
  const onChange = (
    _event: React.FormEvent<IComboBox>,
    option: IComboBoxOption | undefined
  ) => {
    if (option) {
      setSelectedKey(option.key.toString());
      setErrorMessage("");
      onKeySelected?.(option.key.toString());
      return;
    }

    setErrorMessage(!selectedKey ? "Select a valid option." : "");
  };

  return (
    <ComboBox
      options={options}
      allowFreeform
      autoComplete="on"
      disabled={disabled}
      dropdownMaxWidth={200}
      placeholder="Select an option..."
      useComboBoxAsMenuWidth
      selectedKey={selectedKey}
      errorMessage={errorMessage}
      onChange={onChange}
    />
  );
};

export default ControlledComboBox;
