import { MachineToList } from "../Machines/models";
import { DModels } from "./models";
import {
  D325TableItem,
  D850EcoTableItem,
  D850TableItem,
  DBaseItem,
  DBasicTableItem,
} from "./tableItemModels";

export class Utils {
  static regexMacAddress = new RegExp(
    "[A-F0-9]{2}-[A-F0-9]{2}-[A-F0-9]{2}-[A-F0-9]{2}-[A-F0-9]{2}-[A-F0-9]{2}"
  );

  static regexIpAddress = new RegExp(
    "\\b(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\b"
  );

  /**
   * Gets the datalogger model key as string.
   * @param model The datalogger model.
   * @returns The DModels enum key as string. Otherwise, an empty string.
   */
  static getDModelsKey = (model: DModels): string => {
    let index: number = Object.values(DModels).indexOf(model);
    if (index < 0) {
      return "";
    }

    return Object.keys(DModels)[index];
  };

  /**
   * Gets the datalogger machines from the machines list.
   * @param datalogger The datalogger base object
   * @param machinesRef The reference machines list, to get the datalogger machines information.
   * @returns The datalogger machines list.
   */
  static getDataloggerMachines = (
    datalogger: DBaseItem,
    machinesRef: MachineToList[]
  ): string[] => {
    let result: string[] = [];
    datalogger?.machineIds?.forEach((id: string) => {
      var machine = machinesRef.find((m) => m.id === id);
      if (machine) {
        result.push(machine.name);
      }
    });

    return result;
  };

  /**
   * Formats an IP address
   * @param ipAddress The IP address
   * @returns The Ip address formatted to world wide default.
   */
  static formatIpAddress = (ipAddress: string) => {
    let ipSegments: string[] = ipAddress.trim().split(".");
    let result: string = "";
    for (let i = 0; i < ipSegments.length; i++) {
      result +=
        i === ipSegments.length - 1
          ? `${parseInt(ipSegments[i])}`
          : `${parseInt(ipSegments[i])}.`;
    }

    return result;
  };

  /**
   * Gets the dataloggers filtered table items
   * @param model The datalogger model
   * @param items The original items list
   * @param searchString The search string
   * @returns The items list, but filtered by the search string.
   */
  static getFilteredItems = <
    T = D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem
  >(
    model: DModels,
    items: T[],
    searchString: string
  ): T[] => {
    let result: T[] = [...items];
    if (!searchString || searchString === "") {
      return result;
    }

    switch (model) {
      case DModels.D325:
        result = (result as D325TableItem[]).filter(
          (r) =>
            r.projectName.toLowerCase().includes(searchString.toLowerCase()) ||
            r.macAddress?.toLowerCase().includes(searchString.toLowerCase()) ||
            r.firmwareVersion
              ?.toLowerCase()
              .includes(searchString.toLowerCase())
        ) as T[];
        break;

      case DModels.D555:
      case DModels.D650:
        result = (result as DBasicTableItem[]).filter(
          (r) =>
            r.projectName.toLowerCase().includes(searchString.toLowerCase()) ||
            r.macAddress?.toLowerCase().includes(searchString.toLowerCase()) ||
            r.firmwareVersion.toLowerCase().includes(searchString.toLowerCase())
        ) as T[];
        break;

      case DModels.D850Eco:
        result = (result as D850EcoTableItem[]).filter(
          (r) =>
            r.projectName.toLowerCase().includes(searchString.toLowerCase()) ||
            r.macAddress?.toLowerCase().includes(searchString.toLowerCase())
        ) as T[];
        break;

      case DModels.D850:
        result = (result as D850TableItem[]).filter(
          (r) =>
            r.projectName.toLowerCase().includes(searchString.toLowerCase()) ||
            r.macAddress?.toLowerCase().includes(searchString.toLowerCase())
        ) as T[];
        break;
    }

    return result;
  };

  static sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  
}
