/* eslint-disable react-hooks/exhaustive-deps */
import { DialogType, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { BaseDialog, DialogSize, notification } from "web-analysis-lib";
import { Text } from "@fluentui/react/lib/Text";
import {
  ImageType,
  UploadImageAdditionalFormData,
  UploadImageFormData,
} from "../models";
import { useEffect, useState } from "react";
import { ImagesAPI } from "../api";

type ImagesUploadProgressDialogProps = {
  imageType: ImageType;
  files: File[];
  onClose: (dataChanged: boolean) => void;
  additionalData?: UploadImageAdditionalFormData;
};

/**
 * Gets the Images upload progress dialog component.
 * @param imageType the Image(s) type.
 * @param files The files list.
 * @param additionalData Any additional data required in the HTTP request body.
 * @param onClose Method called when this dialog is being closed.
 * @returns The Images upload progress dialog component.
 */
const ImagesUploadProgressDialog = ({
  imageType,
  files,
  additionalData,
  onClose,
}: ImagesUploadProgressDialogProps) => {
  const [imagesProcessed, setImagesProcessed] = useState<number>(0);
  const [dataUploaded, setDataUploaded] = useState<boolean>(false);

  // Uploads all images (one by one).
  useEffect(() => {
    if (files?.length === 0 ?? true) {
      onClose?.(false);
      return;
    }

    if (imagesProcessed === files.length) {
      onClose?.(dataUploaded);
      return;
    }

    let body: UploadImageFormData = {
      file: files[imagesProcessed],
      ...additionalData,
    };

    ImagesAPI.uploadImage(imageType, body).then((response) => {
      if (response.status !== 202) {
        notification.error(
          `Failure uploading file '${body.file.name}': ${response.statusText}.`
        );
      } else {
        notification.success(`Success uploading file '${body.file.name}'`);
        !dataUploaded && setDataUploaded(true);
      }

      setImagesProcessed(imagesProcessed + 1);
    });
  }, [imagesProcessed]);

  return (
    <BaseDialog
      hidden={false}
      dialogContentProps={{
        title: "",
        type: DialogType.normal,
        onDismiss: () => onClose(true),
      }}
      size={DialogSize.AUTO}
    >
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        tokens={{ childrenGap: 20, padding: 10 }}
        style={{ minHeight: "8em", minWidth: "8em" }}
      >
        <Stack.Item>
          <Spinner size={SpinnerSize.large} />
        </Stack.Item>
        <Text variant="medium" style={{ fontWeight: "600" }}>{`Uploading ${
          imagesProcessed + 1
        } of ${files?.length ?? 0} images...`}</Text>
      </Stack>
    </BaseDialog>
  );
};

export default ImagesUploadProgressDialog;
