import { useState, useEffect, useMemo } from "react";
import {
  BaseDialog,
  FormItemProps,
  FormItemType,
  renderFormItems,
  useZodForm,
} from "web-analysis-lib";
import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
  IComboBoxOption,
} from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Status } from "../../schema/status";
import {
  listAsyncCompanies,
  selectCompaniesStatus,
} from "../Companies/reducer";

import { ComboBoxCustom } from "../Generic/ComboBox";

import {
  editBulkGateways,
  gatewaysBulkNotCommon,
  gatewaysBulkProps,
} from "./api";
import { ItemsSelected } from "./GatewaysList";
import { z } from "zod";
import { maxLengthType1 } from "../../schema/Constants";
import type { FieldError } from "react-hook-form";

type EditBDialogProps = IDialogProps & {
  gatewaysNotCommon: gatewaysBulkNotCommon[];
  options: IComboBoxOption[];
  data: ItemsSelected; // | null;
  show: boolean;
  onSuccess: (hasError: boolean) => void;
  onClose: () => void;
};

const getSchema = () =>
  z
    .object({
      ltePlanSize: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Name must contain at most ${maxLengthType1} character(s)`,
        }),
      firmware: z.string().optional(),
    })
    .refine(
      (input) => {
        if (!input.ltePlanSize) {
          return true;
        }
        var regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);
        return valid.test(input.ltePlanSize);
      },
      {
        path: ["ltePlanSize"],
        message: "Try a number between (1.0:1000.0)",
      }
    );
const gatewayFields: FormItemProps[] = [
  {
    name: "ltePlanSize",
    type: FormItemType.TextField,
    groupProps: { label: "Lte Plan Size *" },
  },
  {
    name: "firmware",
    type: FormItemType.TextField,
    groupProps: { label: "Firmware" },
  },
];

export const EditBulkDialog = ({
  gatewaysNotCommon,
  options,
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}: EditBDialogProps) => {
  const schema = useMemo(
    () => getSchema(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [isLoading, setLoading] = useState(false);
  const statusParent = useAppSelector(selectCompaniesStatus);
  const dispatch = useAppDispatch();
  const [idProjSelected, setIdProjSelected] = useState<string>(
    data && data.projectId !== "00000000-0000-0000-0000-000000000000"
      ? data.projectId
      : undefined
  );

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        ltePlanSize: data.ltePlanSize.toString(),
        firmware: data.firmware,
      },
    }),
  });

  useEffect(() => {
    if (statusParent === Status.void) dispatch(listAsyncCompanies());
  }, [dispatch, statusParent]);

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);
    const toSend: gatewaysBulkProps = {
      notCommon: gatewaysNotCommon,
      editGateWay: {
        projectId: idProjSelected,
        ltePlanSize: Number(formData.ltePlanSize),
        firmware: formData.firmware,
      },
    };
    await editBulkGateways(toSend).then((response) =>
      onSuccess("status" in response)
    );

    handleClose();
  });

  const handleClose = () => {
    // reset state
    setLoading(false);

    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Edit Bulk Gateways",
        closeButtonAriaLabel: "Close",
        onDismiss: handleClose,
      }}
    >
      <ComboBoxCustom
        key={20}
        label={"Select project:"}
        keyMe={20}
        options={options}
        selection={setIdProjSelected}
        errorMessage={""}
        defaultKey={idProjSelected}
      />

      {renderFormItems(gatewayFields, {
        control,
        errors: errors as { [schemaProp: string]: FieldError },
      })}

      <DialogFooter>
        <PrimaryButton
          type="submit"
          text="Save Changes"
          disabled={isLoading || !isValid}
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
          onClick={onSubmit}
        />
        <DefaultButton
          styles={{
            root: { border: "unset", background: "transparent" },
          }}
          text="Cancel"
          onClick={handleClose}
        />
      </DialogFooter>
    </BaseDialog>
  );
};
